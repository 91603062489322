import React, { useRef, useEffect } from 'react';

interface TitleInputProps {
  title: string;
  onTitleChange: (newTitle: string) => void;
  isEditing: boolean;
}

const TitleInput: React.FC<TitleInputProps> = ({ title, onTitleChange, isEditing }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onTitleChange(event.target.value);
  };

  useEffect(() => {
    if (isEditing) {
      adjustTextareaHeight();
    }
  }, [title, isEditing]);

  return isEditing ? (
    <textarea
      ref={textareaRef}
      value={title}
      onChange={handleTitleChange}
      className="text-xl bg-mcdm-primary-100 dark:bg-mcdm-third-400 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100 pt-0 pl-5 pr-5 w-full border-none focus:outline-none resize-none overflow-hidden"
      style={{
        minHeight: '3rem',
      }}
      rows={1}
    />
  ) : (
    <h1 className="text-xl pt-0 pl-5 pr-5 whitespace-pre-wrap text-mcdm-third-500 dark:text-mcdm-primary-100 break-words font-platypi">
      {title}
    </h1>
  );
};

export default TitleInput;