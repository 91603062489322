import { useState, useEffect, useCallback } from 'react';
import { ISuggestion, IDossier } from '../widgets/types';
import { useWebSocket } from '../../../context/WebSocketContext';

export const useSuggestions = (
  token: string, 
  dossierId: string, 
  setDossierContent: React.Dispatch<React.SetStateAction<IDossier | null>>, 
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const { socket } = useWebSocket();

  const fetchSuggestions = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/suggestions/dossier/${dossierId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Failed to fetch suggestions');
      const data: ISuggestion[] = await response.json();

      // Filtrer les suggestions ayant le statut "pending"
      const pendingSuggestions = data.filter(suggestion => suggestion.status === 'pending');
      setSuggestions(pendingSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  }, [token, dossierId]);

  useEffect(() => {
    fetchSuggestions();
  
    if (socket) {
      socket.on('newSuggestion', (newSuggestion: ISuggestion) => {
        if (newSuggestion.dossierId === dossierId && newSuggestion.status === 'pending') {
          setSuggestions(prevSuggestions => {
            const updatedSuggestions = [...prevSuggestions, newSuggestion];
            console.log('Nouvelles suggestions:', updatedSuggestions);
            return updatedSuggestions;
          });
        }
      });
    }
  
    return () => {
      if (socket) {
        socket.off('newSuggestion');
      }
    };
  }, [fetchSuggestions, socket, dossierId]);

  const handleSuggestionAction = async (suggestion: ISuggestion, action: 'accept' | 'reject') => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/suggestions/${suggestion._id}/status`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: action === 'accept' ? 'approved' : 'denied' }),
      });    
  
      if (!response.ok) throw new Error(`Failed to ${action} suggestion`);
  
      if (action === 'accept') {
        setDossierContent((prevDossier) => {
          if (!prevDossier) return null;
          return { ...prevDossier };
        });
        setHasUnsavedChanges(true);
      }
  
      // Mettre à jour la liste des suggestions localement
      setSuggestions(prevSuggestions => 
        prevSuggestions.filter(s => s._id !== suggestion._id)
      );
    } catch (error) {
      console.error(`Error ${action}ing suggestion:`, error);
    }
  };  

  const handleAcceptSuggestion = (suggestion: ISuggestion) => handleSuggestionAction(suggestion, 'accept');
  const handleRejectSuggestion = (suggestion: ISuggestion) => handleSuggestionAction(suggestion, 'reject');

  return {
    suggestions,
    fetchSuggestions,
    handleAcceptSuggestion,
    handleRejectSuggestion,
    setSuggestions,
  };
};