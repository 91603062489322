import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';

interface IUser {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePicture: string | null;
}

interface AuthorAvatarProps {
    user: IUser;
    className?: string;
    size?: 'sm' | 'md' | 'lg';
}

// Stocker les timestamps des dernières mises à jour par ID d'utilisateur
const lastRefreshTimestamps: Record<string, number> = {};
// Stocker les URLs actualisées des photos de profil par ID d'utilisateur
const refreshedProfilePictures: Record<string, { url: string, timestamp: number }> = {};

const AuthorAvatar: React.FC<AuthorAvatarProps> = ({
    user,
    className = "",
    size = "sm"
}) => {
    const [imageUrl, setImageUrl] = useState<string | null>(user.profilePicture);
    const [error, setError] = useState<boolean>(false);
    const [retryCount, setRetryCount] = useState<number>(0);
    const { token } = useAuth();

    const tooltipContent = `${user.firstName} ${user.lastName}`;

    const sizes = {
        sm: 'w-6 h-6',
        md: 'w-8 h-8',
        lg: 'w-10 h-10'
    };

    const getInitial = (firstName: string) => {
        return firstName.charAt(0).toUpperCase();
    };

    // Vérifier si l'URL est encore valide
    const isProfileUrlValid = (userId: string) => {
        if (!refreshedProfilePictures[userId]) return false;

        const now = Date.now();
        // Considérer l'URL comme invalide 2 minutes avant son expiration réelle (15min - 2min = 13min)
        return now - refreshedProfilePictures[userId].timestamp < 13 * 60 * 1000;
    };

    // Rafraîchir l'URL de la photo de profil d'un auteur
    const refreshAuthorProfilePicture = async (userId: string) => {
        if (!token) return null;

        // Éviter les requêtes trop fréquentes pour le même utilisateur
        const now = Date.now();
        if (lastRefreshTimestamps[userId] && now - lastRefreshTimestamps[userId] < 5000) {
            return refreshedProfilePictures[userId]?.url || null;
        }

        // Utiliser l'URL en cache si elle est encore valide
        if (isProfileUrlValid(userId)) {
            return refreshedProfilePictures[userId].url;
        }

        try {
            lastRefreshTimestamps[userId] = now;

            const response = await fetch(
                `${process.env.REACT_APP_URL_API_MACADAMIA}/users/${userId}/profile-picture`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();

            if (data.profilePictureUrl) {
                // Mettre en cache l'URL rafraîchie
                refreshedProfilePictures[userId] = {
                    url: data.profilePictureUrl,
                    timestamp: now
                };
                return data.profilePictureUrl;
            }

            return null;
        } catch (error) {
            console.error("Erreur lors du rafraîchissement de la photo de profil de l'auteur:", error);
            return null;
        }
    };

    // Gestion des erreurs de chargement d'image
    const handleImageError = async () => {
        if (retryCount >= 3) {
            setError(true);
            return;
        }

        setError(true);
        setRetryCount(prev => prev + 1);

        try {
            const newUrl = await refreshAuthorProfilePicture(user._id);
            if (newUrl) {
                setImageUrl(newUrl);
                setError(false);
            }
        } catch (err) {
            console.error("Échec du rafraîchissement après erreur:", err);
        }
    };

    // Effet pour initialiser/rafraîchir l'URL
    useEffect(() => {
        const initImage = async () => {
            // Si nous avons déjà une URL en cache valide pour cet utilisateur, l'utiliser
            if (isProfileUrlValid(user._id)) {
                setImageUrl(refreshedProfilePictures[user._id].url);
                return;
            }

            // Si l'utilisateur a une photo de profil mais pas d'URL rafraîchie, essayer de la rafraîchir
            if (user.profilePicture && !isProfileUrlValid(user._id)) {
                try {
                    const newUrl = await refreshAuthorProfilePicture(user._id);
                    if (newUrl) {
                        setImageUrl(newUrl);
                    }
                } catch (err) {
                    console.error("Échec de l'initialisation de l'image:", err);
                }
            }
        };

        initImage();

        // Effet pour gérer le retour de visibilité
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && !isProfileUrlValid(user._id)) {
                initImage();
            }
        };

        window.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [user._id, user.profilePicture]);

    // Rendu du tooltip
    const Tooltip: React.FC<{
        content: string;
        children: React.ReactNode;
    }> = ({ content, children }) => {
        return (
            <div className="relative group">
                {children}
                <div className="absolute invisible opacity-0 group-hover:visible group-hover:opacity-100 
                      bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1
                      bg-mcdm-third-500 text-white text-xs rounded whitespace-nowrap
                      transition-all duration-200 z-10">
                    {content}
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 
                       border-4 border-transparent border-t-mcdm-third-500" />
                </div>
            </div>
        );
    };

    if (imageUrl && !error) {
        return (
            <Tooltip content={tooltipContent}>
                <img
                    src={imageUrl}
                    alt={tooltipContent}
                    className={`${sizes[size]} rounded-full object-cover object-center ${className}`}
                    onError={handleImageError}
                />
            </Tooltip>
        );
    }

    return (
        <Tooltip content={tooltipContent}>
            <div
                className={`${sizes[size]} rounded-full bg-mcdm-third-200 flex items-center justify-center
                  text-xs text-white font-medium ${className}`}
            >
                {getInitial(user.firstName)}
            </div>
        </Tooltip>
    );
};

export default AuthorAvatar;