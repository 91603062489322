import React from 'react';
import JurisprudenceSearch from './JurisprudenceSearch';
import LegifranceSearch from './LegifranceSearch'; // Import du nouveau composant
import LinkManagement from './LinkManagement';
import Bold from './../../../icons/Bold';
import Italic from './../../../icons/Italic';
import Underline from './../../../icons/Underline';

interface ToolbarProps {
  onFormat: (command: string, value?: string) => void;
  onAddEntity: (type: string, data: any) => void;
  setForceToolbarVisible: (isVisible: boolean) => void;
  mode: 'editor' | 'reader';
}

const Toolbar: React.FC<ToolbarProps> = ({ onFormat, onAddEntity, mode, setForceToolbarVisible }) => {
  if (mode !== 'editor') return null;

  const handleJurisprudenceSelect = (reference: string) => {
    onAddEntity('jurisprudenceReference', { reference });
  };

  const handleLegifranceSelect = (reference: string) => {
    onAddEntity('legifranceReference', { reference });
  };

  const handleAddLink = (url: string) => {
    const selection = window.getSelection();
    const text = selection ? selection.toString() : '';

    onAddEntity('link', { url, text: text || url });
  };

  return (
    <div className="bg-white border border-gray-300 rounded-lg shadow-lg shadow-inner pt-1 pl-1 pr-1 pb-1 flex space-x-0 relative">
      <button onClick={() => onFormat('bold')} className="p-1 hover:bg-mcdm-primary-200 rounded text-mcdm-third-500">
        <Bold width="23px" height="23px" stroke="currentColor" />
      </button>
      <button onClick={() => onFormat('italic')} className="p-1 hover:bg-mcdm-primary-200 rounded text-mcdm-third-500">
        <Italic width="23px" height="23px" stroke="currentColor" />
      </button>
      <button onClick={() => onFormat('underline')} className="p-1 hover:bg-mcdm-primary-200 rounded text-mcdm-third-500">
        <Underline width="23px" height="23px" stroke="currentColor" />
      </button>

      <LinkManagement onAddLink={handleAddLink} setForceToolbarVisible={setForceToolbarVisible} />
      <JurisprudenceSearch onSelect={handleJurisprudenceSelect} />
      <LegifranceSearch onSelect={handleLegifranceSelect} />
    </div>
  );
};

export default Toolbar;
