import React, { useState, useEffect } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { IImageBlock } from './types';

interface ImageWidgetProps {
  block: IImageBlock;
  onChange: (newBlock: IImageBlock) => void;
  currentState: string;
}

const ImageWidget: React.FC<ImageWidgetProps> = ({ block, onChange, currentState }) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [isFetchingUrl, setIsFetchingUrl] = useState<boolean>(false);
  const [urlError, setUrlError] = useState<string | null>(null);

  // Fonction pour récupérer l'URL pré-signée à partir de l'imageId
  const fetchImageUrl = async (imageId: string) => {
    try {
      setIsFetchingUrl(true);
      setUrlError(null);

      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/images/${imageId}/url`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la récupération de l\'URL de l\'image');
      }

      const data = await response.json();
      setImageSrc(data.imageUrl);
      setIsFetchingUrl(false);
    } catch (error: any) {
      console.error('Erreur lors de la récupération de l\'URL de l\'image:', error);
      setUrlError(error.message || 'Erreur lors de la récupération de l\'URL de l\'image');
      setIsFetchingUrl(false);
    }
  };

  // useEffect pour récupérer l'URL pré-signée lorsque l'imageId change
  useEffect(() => {
    if (block.imageId) {
      fetchImageUrl(block.imageId);
    } else {
      setImageSrc('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.imageId]);

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('image', file);

    // Obtenir les dimensions de l'image sélectionnée
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    };

    try {
      setUploadStatus('uploading');
      setErrorMessage(null);

      // Envoyer l'image au backend
      const uploadResponse = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/upload/image`, {
        method: 'POST',
        body: formData,
      });

      if (!uploadResponse.ok) {
        const errorData = await uploadResponse.json();
        throw new Error(errorData.message || 'Erreur lors de l\'upload de l\'image');
      }

      const uploadData = await uploadResponse.json();
      const imageId = uploadData.imageId;

      // Mettre à jour le bloc avec l'ID de l'image (imageSrc sera mis à jour via useEffect)
      onChange({ ...block, imageId: imageId, src: '' });

      setUploadStatus('success');
    } catch (error: any) {
      console.error(error);
      setUploadStatus('error');
      setErrorMessage(error.message || 'Erreur lors de l\'upload de l\'image');
    }
  };

  const accept: Accept = {
    'image/png': [],
    'image/jpeg': [],
    'image/jpg': [],
    'image/gif': [],
    'image/webp': [],
    'image/heic': [],
    'image/heif': [],
    'image/avif': [],
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  // Calculer le ratio d'aspect de l'image pour définir la hauteur du rectangle
  const aspectRatio = imageDimensions ? imageDimensions.height / imageDimensions.width : 0.75; // 0.75 par défaut

  return currentState === 'editor' ? (
    <div {...getRootProps({ className: 'dropzone' })} style={styles.dropZone}>
      <input {...getInputProps()} />
      {uploadStatus === 'uploading' ? (
        <div
          className="uploading-placeholder"
          style={{ ...styles.uploadingPlaceholder, paddingTop: `${aspectRatio * 100}%` }}
        ></div>
      ) : imageSrc ? (
        isFetchingUrl ? (
          <div
            className="loading-placeholder"
            style={{ ...styles.uploadingPlaceholder, paddingTop: `${aspectRatio * 100}%` }}
          >
            <p>Chargement de l'image...</p>
          </div>
        ) : urlError ? (
          <div>
            <p style={{ color: 'red' }}>{urlError}</p>
            <button onClick={() => fetchImageUrl(block.imageId!)}>Réessayer</button>
          </div>
        ) : (
          <img src={imageSrc} alt="Uploaded" style={styles.imagePreview} />
        )
      ) : (
        <p>Déposez votre image, ou sélectionnez-en une depuis votre appareil en cliquant ici.</p>
      )}
      {uploadStatus === 'error' && errorMessage && (
        <p style={{ color: 'red' }}>{errorMessage}</p>
      )}
    </div>
  ) : (
    <>
      {imageSrc ? (
        isFetchingUrl ? (
          <div
            className="loading-placeholder"
            style={{ ...styles.uploadingPlaceholder, paddingTop: `${aspectRatio * 100}%` }}
          >
            <p>Chargement de l'image...</p>
          </div>
        ) : urlError ? (
          <div>
            <p style={{ color: 'red' }}>{urlError}</p>
            <button onClick={() => fetchImageUrl(block.imageId!)}>Réessayer</button>
          </div>
        ) : (
          <img src={imageSrc} alt={block.description || 'Image'} style={styles.image} />
        )
      ) : (
        <p>Aucune image sélectionnée.</p>
      )}
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  dropZone: {
    border: '2px dashed #cccccc',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  imagePreview: {
    width: '100%',
    objectFit: 'cover',
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },
  uploadingPlaceholder: {
    width: '100%',
    backgroundColor: '#ccc',
    animation: 'placeholderAnimation 1.5s infinite',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default ImageWidget;
