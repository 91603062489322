import React from 'react';
import { useRessourcesRecommendations } from '../../../hooks/useRessourcesRecommendations';

const RessourcesSidebar = () => {
  const { recommendations, loading, error } = useRessourcesRecommendations(5);

  if (loading) {
    return (
      <div className="w-1/5">
        <div className="bg-mcdm-primary-200 dark:bg-mcdm-third-400 border border-white dark:border-mcdm-third-400 rounded-lg p-4">
          <h2 className="text-mcdm-third-500 dark:text-mcdm-primary-100 font-platypi text-lg mb-4">
            Ressources recommandées
          </h2>
          <div className="animate-pulse space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-24 bg-gray-200 dark:bg-mcdm-third-300 rounded" />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-1/5">
        <div className="bg-mcdm-primary-200 dark:bg-mcdm-third-400 border border-white dark:border-mcdm-third-400 rounded-lg p-4">
          <h2 className="text-mcdm-third-500 dark:text-mcdm-primary-100 font-platypi text-lg mb-4">
            Ressources recommandées
          </h2>
          <p className="text-red-500 dark:text-red-400 text-sm">
            Une erreur est survenue lors du chargement des recommandations
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-1/5">
      <div className="bg-mcdm-primary-200  dark:bg-mcdm-third-400 border border-white dark:border-mcdm-third-400 rounded-lg p-4">
        <h2 className="text-mcdm-third-500 dark:text-mcdm-primary-100 font-platypi text-lg mb-4">
          Ressources recommandées
        </h2>
        <div className="space-y-4">
          {recommendations.map((resource) => (
            <div
              key={resource._id}
              className="p-3 bg-mcdm-primary-100 dark:bg-mcdm-third-300 rounded-lg hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-200 transition-colors"
            >
              <a
                href={resource.source.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <h3 className="text-sm font-medium text-mcdm-third-500 dark:text-mcdm-primary-100 mb-2 line-clamp-2">
                  {resource.titre}
                </h3>
                <div className="flex items-center text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 space-x-4">
                  <span>
                    {resource.halMetadata?.publication?.type || 'Publication'}
                  </span>
                  {resource.halMetadata?.publication?.date && (
                    <span>
                      {new Date(resource.halMetadata.publication.date).getFullYear()}
                    </span>
                  )}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RessourcesSidebar;