import React, { CSSProperties } from 'react';

interface Bars3BottomLeftProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Bars3BottomLeft: React.FC<Bars3BottomLeftProps> = ({ fill = 'currentColor', secondaryfill, width = '100%', height = '100%', style, className }) => {
  const finalFill = fill || 'currentColor';
  const finalSecondaryFill = secondaryfill || finalFill;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g fill={finalFill}>
        <path
          d="M263 351.92h534"
          fill="none"
          stroke={finalFill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <path
          d="M263 530.24h534"
          fill="none"
          stroke={finalFill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <path
          d="M263 708.08h197"
          fill="none"
          stroke={finalFill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
      </g>
    </svg>
  );
};

export default Bars3BottomLeft;
