import React, { useState } from 'react';
import { useCurrentChapter } from '../contexts/CurrentChapterContext';
import { useMacadamia } from '../contexts/MacadamiaContext';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { ObjectId } from 'bson';
import AutoResizeTextarea from './AutoResizeTextarea';
import Trash from '../icons/trash';
import Plus from '../icons/Plus';

interface IChapter {
  type: string;
  title: string;
  sections: any[];
  _id: string;
}

const MacadamiaChapters: React.FC<{
  content: IChapter[],
  className?: string,
  itemClassName?: string,
  activeItemClassName?: string,
  onAddChapter: (newChapter: IChapter) => void,
  onEditChapter: (index: number, newTitle: string) => void,
  onDeleteChapter: (index: number) => void,
  onReorderChapters: (updatedChapters: IChapter[]) => void
}> = ({
  content,
  className = "bg-mcdm-primary-100 dark:bg-mcdm-third-400",
  itemClassName = "p-4 my-2 rounded-lg bg-white dark:bg-mcdm-third-300 text-mcdm-third-500 dark:text-mcdm-primary-100 hover:shadow-md transition-shadow duration-200",
  activeItemClassName = "border-l-4 border-mcdm-secondary-400 bg-mcdm-green-300 text-mcdm-primary-100 dark:text-mcdm-primary-100",
  onAddChapter,
  onEditChapter,
  onDeleteChapter,
  onReorderChapters
}) => {
    const { currentChapterIndex, setCurrentChapterIndex } = useCurrentChapter();
    const { currentState } = useMacadamia();
    const [confirmDeleteIndex, setConfirmDeleteIndex] = useState<number | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [confirmPosition, setConfirmPosition] = useState({ top: 0, left: 0 });

    const handleTitleChange = (index: number, newTitle: string) => {
      onEditChapter(index, newTitle);
    };

    const handleDeleteClick = (e: React.MouseEvent, index: number) => {
      e.stopPropagation();

      // Calculer la position optimale pour la boîte de dialogue
      const buttonRect = e.currentTarget.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      // Positionner à gauche ou à droite selon l'espace disponible
      const left = buttonRect.left < viewportWidth / 2
        ? buttonRect.right + 5 // Positionner à droite du bouton
        : buttonRect.left - 155; // Positionner à gauche du bouton (150px largeur + 5px marge)

      setConfirmPosition({
        top: buttonRect.top,
        left: left
      });
      setConfirmDeleteIndex(index);
    };

    const handleConfirmDelete = (index: number) => {
      onDeleteChapter(index);
      setConfirmDeleteIndex(null);
    };

    const handleCancelDelete = () => {
      setConfirmDeleteIndex(null);
    };

    const addNewChapter = () => {
      // Génération d'un ID garantie unique en ajoutant un timestamp
      const timestamp = new Date().getTime();
      const newChapter = {
        _id: new ObjectId().toHexString() + '-' + timestamp,
        type: 'chapter',
        title: 'Nouveau chapitre',
        sections: []
      };

      onAddChapter(newChapter);
    };

    const handleChapterDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) {
        return;
      }

      const updatedChapters = Array.from(content);
      const [movedChapter] = updatedChapters.splice(sourceIndex, 1);
      updatedChapters.splice(destinationIndex, 0, movedChapter);

      onReorderChapters(updatedChapters);
    };

    // Fonction pour déterminer les classes CSS de l'élément du chapitre
    const getItemClasses = (index: number) => {
      const isActive = currentChapterIndex === index;
      const isHovered = hoveredIndex === index;

      let classes = `${itemClassName} min-h-[50px] flex items-center relative`;

      if (isActive) {
        classes = `${classes} ${activeItemClassName}`;
      } else {
        // Si ce n'est pas le chapitre actif, on ajoute les classes pour le survol
        classes = `${classes} hover:bg-mcdm-primary-200 hover:text-mcdm-primary-100 dark:hover:bg-mcdm-third-200 dark:hover:text-mcdm-primary-100`;
      }

      return classes;
    };

    // Fonction pour déterminer les classes CSS du textarea ou du texte du chapitre
    const getTextClasses = (index: number) => {
      const isActive = currentChapterIndex === index;
      const isHovered = hoveredIndex === index;

      if (isActive) {
        return 'text-mcdm-primary-100 dark:text-mcdm-primary-100';
      } else if (isHovered) {
        return 'text-mcdm-primary-100 dark:text-mcdm-primary-100';
      } else {
        return 'text-mcdm-third-500 dark:text-mcdm-primary-100';
      }
    };

    // Fonction pour déterminer la couleur de l'icône de suppression
    const getTrashIconColor = (index: number) => {
      const isActive = currentChapterIndex === index;
      const isHovered = hoveredIndex === index;

      if (isActive || isHovered) {
        return "text-mcdm-primary-100 dark:text-mcdm-primary-100";
      } else {
        return "text-mcdm-third-400 dark:text-mcdm-primary-200";
      }
    };

    // Fonction pour garantir l'unicité des clés même en cas d'IDs dupliqués
    const getUniqueKey = (chapter: IChapter, index: number) => {
      return `${chapter._id}-${index}`;
    };

    return currentState === 'editor' ? (
      <DragDropContext onDragEnd={handleChapterDragEnd}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={`${className} p-4 rounded-lg`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {content.map((chapter, index) => (
                <Draggable
                  key={getUniqueKey(chapter, index)}
                  draggableId={getUniqueKey(chapter, index)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={getItemClasses(index)}
                      onClick={() => setCurrentChapterIndex(index)}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <AutoResizeTextarea
                        value={chapter.title}
                        onChange={(newTitle) => handleTitleChange(index, newTitle)}
                        className={`chapter-input border-none bg-transparent flex-grow ${getTextClasses(index)}`}
                      />
                      <div className="flex items-center pr-2">
                        {content.length > 1 && (
                          <button
                            className={`p-2 hover:text-mcdm-secondary-400 ${getTrashIconColor(index)}`}
                            onClick={(e) => handleDeleteClick(e, index)}
                          >
                            <Trash width="19px" height="19px" />
                          </button>
                        )}
                        {confirmDeleteIndex === index && (
                          <div
                            className="fixed bg-white dark:bg-mcdm-third-400 border rounded shadow-md z-50 p-4"
                            style={{
                              width: '150px',
                              top: `${confirmPosition.top}px`,
                              left: `${confirmPosition.left}px`
                            }}
                          >
                            <p className="mb-2 text-mcdm-third-500 dark:text-mcdm-primary-100">Confirmer ?</p>
                            <div className="flex justify-between">
                              <button
                                className="text-mcdm-secondary-400 hover:text-mcdm-secondary-500 px-2 py-1"
                                onClick={() => handleConfirmDelete(index)}
                              >
                                Oui
                              </button>
                              <button
                                className="text-red-500 hover:text-red-600 px-2 py-1"
                                onClick={handleCancelDelete}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div
                className="flex justify-center items-center p-4 cursor-pointer text-mcdm-secondary-400 hover:text-mcdm-secondary-500 dark:text-mcdm-secondary-300 dark:hover:text-mcdm-secondary-400"
                onClick={addNewChapter}
              >
                <Plus width="15px" height="15px" className="mr-3" />
                <span>Ajouter un chapitre</span>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <div className={`${className} p-4 rounded-lg`}>
        {content.map((chapter, index) => {
          const isActive = currentChapterIndex === index;
          return (
            <div
              key={getUniqueKey(chapter, index)}
              className={`${itemClassName} min-h-[50px] flex items-center relative ${isActive ? activeItemClassName : ''} 
                hover:bg-mcdm-primary-200 hover:text-mcdm-primary-100 dark:hover:bg-mcdm-third-200 dark:hover:text-mcdm-primary-100`}
              onClick={() => setCurrentChapterIndex(index)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <span className={getTextClasses(index)}>
                {chapter.title}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

export default MacadamiaChapters;