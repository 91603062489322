import React, { CSSProperties } from 'react';

interface WindowProps {
    stroke?: string;
    fill?: string;
    secondaryfill?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Window: React.FC<WindowProps> = ({
    stroke = 'currentColor',
    fill = 'currentColor',
    secondaryfill,
    width = '100%',
    height = '100%',
    style
}) => {
    const actualSecondaryFill = secondaryfill || fill;

    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <g fill={fill}>
                <defs />
                <rect
                    className="cls-1"
                    height="531.1"
                    width="690"
                    fill="none"
                    rx="43.16"
                    ry="43.16"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                    x="185"
                    y="264.45"
                />
                <path
                    className="cls-1"
                    d="M228.16 264.45h603.68c23.82 0 43.16 19.34 43.16 43.16V424.2H185V307.61c0-23.82 19.34-43.16 43.16-43.16z"
                    fill="none"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
                <circle
                    className="cls-2"
                    cx="264.45"
                    cy="343.9"
                    fill={fill}
                    r="26.77"
                    strokeWidth="0px"
                />
                <circle
                    className="cls-2"
                    cx="334.11"
                    cy="344.75"
                    fill={fill}
                    r="26.77"
                    strokeWidth="0px"
                />
                <circle
                    className="cls-2"
                    cx="403.77"
                    cy="344.75"
                    fill={fill}
                    r="26.77"
                    strokeWidth="0px"
                />
            </g>
        </svg>
    );
};

export default Window;