import React, { useEffect, useState } from 'react';

const Status: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<any>(null);

  useEffect(() => {
    const fetchOAuthToken = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/legifrance/get-oauth-token`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setToken(data.token);
      } catch (error) {
        console.error('Erreur lors de la récupération du token OAuth:', error);
        setError('Erreur lors de la récupération du token OAuth');
      }
    };

    fetchOAuthToken();
  }, []);

  const handleSearch = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/legifrance/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({}) // Si besoin de params plus tard
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Erreur lors de la recherche Légifrance:', error);
      setError('Erreur lors de la recherche Légifrance');
    }
  };

  return (
    <div>
      <h1>Status Page</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {token ? (
        <div>
          <p>OAuth Token:</p>
          <pre>{token}</pre>
          <button onClick={handleSearch}>Effectuer une recherche Légifrance</button>
          {results && (
            <div>
              <h2>Résultats:</h2>
              <pre>{JSON.stringify(results, null, 2)}</pre>
            </div>
          )}
        </div>
      ) : (
        <p>Chargement du token...</p>
      )}
    </div>
  );
};

export default Status;
