import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useViewport } from './hooks/useViewport';
import { useMobileSidebar } from '../contexts/MobileSidebarContext';
import { Link } from 'react-router-dom';
import ChevronLeft from '../icons/ChevronLeft';
import TitleInput from './TitleInput';
import AuthorsBox from './AuthorsBox';
import MacadamiaChapters from './MacadamiaChapters';
import UserProfile from './UserProfile';
import { IDossier, IChapter } from './widgets/types';
import './styles/DossierSidebar.css';

interface DossierSidebarProps {
  title: string;
  onTitleChange: (title: string) => void;
  isEditing: boolean;
  userRole: string | null;
  dossierContent: IDossier;
  onOpenSettingsModal: () => void;
  onAddChapter: () => void;
  onEditChapter: (index: number, title: string) => void;
  onDeleteChapter: (index: number) => void;
  onReorderChapters: (chapters: IChapter[]) => void;
}

const DossierSidebar: React.FC<DossierSidebarProps> = ({
  title,
  onTitleChange,
  isEditing,
  userRole,
  dossierContent,
  onOpenSettingsModal,
  onAddChapter,
  onEditChapter,
  onDeleteChapter,
  onReorderChapters,
}) => {
  const { isMobile } = useViewport();
  const { isSidebarOpen, closeSidebar } = useMobileSidebar();

  const sidebarContent = (
    <div className="flex flex-col h-full">
      {/* Top section with fixed elements */}
      <div className="flex-shrink-0">
        <Link
          to="/dossiers"
          className="flex items-center pl-5 pr-5 pt-5 pb-2 text-mcdm-secondary-400 hover:text-mcdm-secondary-500 text-sm"
        >
          <ChevronLeft width="16px" height="16px" className="mr-2" />
          Mes dossiers
        </Link>

        <TitleInput
          title={title}
          onTitleChange={onTitleChange}
          isEditing={isEditing}
        />

        <AuthorsBox
          userRole={userRole}
          dossierId={dossierContent._id}
          onOpenSettingsModal={onOpenSettingsModal}
        />
        
      </div>

      {/* Middle section with scrollable chapters */}
      <div className="flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto custom-scrollbar">
          <MacadamiaChapters
            content={dossierContent.content}
            className="p-4 text-sm"
            itemClassName="cursor-pointer p-2 hover:bg-mcdm-third-200"
            activeItemClassName="bg-mcdm-third-100"
            onAddChapter={onAddChapter}
            onEditChapter={onEditChapter}
            onDeleteChapter={onDeleteChapter}
            onReorderChapters={onReorderChapters}
          />
        </div>
      </div>

      {/* Bottom section with user profile */}
      <div className="flex-shrink-0 mt-auto">
        <UserProfile />
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <AnimatePresence>
        {isSidebarOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black z-20"
              onClick={closeSidebar}
            />
            <motion.aside
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ type: "spring", bounce: 0, duration: 0.3 }}
              className="w-64 bg-mcdm-primary-100 dark:bg-mcdm-third-400 fixed inset-y-0 left-0 z-50 h-screen flex flex-col"
              onClick={(e) => e.stopPropagation()}
            >
              {sidebarContent}
            </motion.aside>
          </>
        )}
      </AnimatePresence>
    );
  }

  return (
    <aside className="w-64 bg-mcdm-primary-100 dark:bg-mcdm-third-400 fixed inset-y-0 left-0 z-10 h-screen flex flex-col">
      {sidebarContent}
    </aside>
  );
};

export default DossierSidebar;