import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import PromoCodeModal from './../../widgets/PromoCodeModal';

interface IPlan {
    _id: string;
    name: string;
    type: 'Gratuit' | 'Étudiant' | 'Rédacteur' | 'Académique';
    description: string;
    price: {
        monthly: number;
        annual: number;
    };
    features: string[];
    maxUsers?: number;
    maxTeams?: number;
    isTeamPlan: boolean;
    status: 'active' | 'inactive';
}

interface IPromoPrice {
    original: number;
    final: number;
    saving: number;
}

interface IPromoPrices {
    [key: string]: IPromoPrice;
}

export default function PlanSelectionPage() {
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAnnual, setIsAnnual] = useState(true);
    const [promoCode, setPromoCode] = useState('');
    const [promoPrices, setPromoPrices] = useState<IPromoPrices>({});
    const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
    const navigate = useNavigate();
    const { user, token, setUser, logout } = useAuth();

    useEffect(() => {
        fetchPlans();
    }, []);

    const handlePromoSubmit = async (code: string): Promise<boolean> => {
        try {
            // Utiliser le premier plan pour vérifier la validité du code
            const planToTest = plans[0];
            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/promotions/validate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    code,
                    planId: planToTest._id,
                    frequency: isAnnual ? 'annual' : 'monthly'
                })
            });

            if (response.ok) {
                setPromoCode(code);
                setIsPromoModalOpen(false);
                return true;
            }

            return false;
        } catch (err) {
            console.error('Erreur lors de la validation du code promo:', err);
            return false;
        }
    };

    const handleRemovePromoCode = () => {
        setPromoCode('');
        setPromoPrices({});
    };

    useEffect(() => {
        if (plans.length > 0 && promoCode) {
            validatePromoForAllPlans();
        }
    }, [plans, isAnnual, promoCode]);

    const validatePromoForAllPlans = async () => {
        const newPromoPrices: IPromoPrices = {};

        for (const plan of plans) {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/promotions/validate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        code: promoCode,
                        planId: plan._id,
                        frequency: isAnnual ? 'annual' : 'monthly'
                    })
                });

                if (response.ok) {
                    const data = await response.json();
                    newPromoPrices[plan._id] = data.pricing;
                }
            } catch (err) {
                console.error('Erreur lors de la validation du code promo:', err);
            }
        }

        setPromoPrices(newPromoPrices);
    };

    const fetchPlans = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/plans`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setPlans(data.filter((plan: IPlan) => plan.status === 'active'));
        } catch (err) {
            setError('Erreur lors du chargement des plans');
        } finally {
            setLoading(false);
        }
    };

    const renderPrice = (plan: IPlan) => {
        const basePrice = isAnnual ? plan.price.annual : plan.price.monthly;
        const promoPrice = promoPrices[plan._id];

        if (promoPrice && promoPrice.final !== basePrice) {
            return (
                <div className="text-2xl font-bold text-mcdm-marron-500 flex items-center justify-start gap-2 mb-2 transition-all duration-300">
                    <span className="text-sm text-mcdm-marron-300 line-through">
                        {basePrice}€
                    </span>
                    <span>
                        {promoPrice.final}€
                        <span className="text-sm">/{isAnnual ? 'an' : 'mois'}</span>
                    </span>
                </div>
            );
        }

        return (
            <div className="text-2xl font-bold text-mcdm-marron-500 mb-2">
                {basePrice}€<span className="text-sm">/{isAnnual ? 'an' : 'mois'}</span>
            </div>
        );
    };

    const handlePlanSelection = async (planId: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/users/select-plan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ planId })
            });

            if (response.ok) {
                if (user) {
                    setUser({
                        id: user.id,
                        profilePicture: user.profilePicture,
                        name: user.name,
                        email: user.email,
                        verifiedUser: user.verifiedUser,
                        plan: planId
                    });
                }
                navigate('/mes-dossiers');
            } else {
                setError('Erreur lors de la sélection du plan');
            }
        } catch (err) {
            setError('Erreur lors de la sélection du plan');
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-mcdm-primary-100 dark:bg-mcdm-third-500 flex items-center justify-center">
                <div className="spinner dark:border-mcdm-primary-100"></div>
            </div>
        );
    }

    return (
        <div className="relative min-h-screen bg-mcdm-primary-100 dark:bg-mcdm-third-500 pt-24 pb-12 px-4">
            <img
                src="/logos/logo-macadamia-v2.png"
                alt="Logo Macadamia"
                className="absolute top-4 left-4 h-8 dark:invert"
            />
            <button
                onClick={logout}
                className="absolute top-4 right-4 px-4 py-2 text-mcdm-third-500 dark:text-mcdm-primary-200 hover:text-mcdm-third-600 dark:hover:text-mcdm-primary-100 transition-colors"
            >
                Déconnexion
            </button>

            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-bold font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100 mb-4">
                        Choisissez votre plan
                    </h2>
                    <p className="text-mcdm-third-400 dark:text-mcdm-primary-200 mb-4">
                        Sélectionnez le plan qui correspond le mieux à vos besoins
                    </p>

                    <div className="inline-flex items-center bg-mcdm-third-500 dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-full p-1 mb-8">
                        <button
                            className={`px-6 py-2 rounded-full text-sm font-medium transition-colors
                                ${isAnnual ? 'bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400' : 'text-gray-300 hover:text-white dark:text-gray-400 dark:hover:text-white'}`}
                            onClick={() => setIsAnnual(true)}
                        >
                            Annuel
                            {isAnnual && <span className="ml-2 text-xs">(meilleur prix)</span>}
                        </button>
                        <button
                            className={`px-6 py-2 rounded-full text-sm font-medium transition-colors
                                ${!isAnnual ? 'bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400' : 'text-gray-300 hover:text-white dark:text-gray-400 dark:hover:text-white'}`}
                            onClick={() => setIsAnnual(false)}
                        >
                            Mensuel
                        </button>
                    </div>
                </div>

                {error && (
                    <div className="text-red-500 dark:text-red-400 text-center mb-4">{error}</div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {plans.map((plan) => (
                        <div
                            key={plan._id}
                            className={`bg-white dark:bg-mcdm-third-400 rounded-lg shadow-sm shadow-inner p-6 border-2 transition-all
                                ${selectedPlan === plan._id ? 'border-mcdm-secondary-400' : 'border-transparent'}
                                hover:border-mcdm-secondary-400`}
                        >
                            <h3 className="text-lg font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100 mb-2">
                                {plan.name}
                            </h3>
                            <p className="text-mcdm-third-400 dark:text-mcdm-primary-200 mb-4">{plan.description}</p>

                            <div className="text-2xl font-bold text-mcdm-third-500 dark:text-mcdm-primary-100 mb-2">
                                {renderPrice(plan)}
                            </div>

                            <ul className="mb-6 text-mcdm-third-500 dark:text-mcdm-primary-200">
                                {plan.features.map((feature: string, index: number) => (
                                    <li key={index} className="flex items-center mb-2">
                                        <svg
                                            className="w-5 h-5 text-mcdm-secondary-400 dark:text-mcdm-secondary-300 mr-2"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <button
                                onClick={() => handlePlanSelection(plan._id)}
                                className="w-full bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 shadow-inner text-white dark:text-mcdm-third-400 py-2 px-4 rounded
                                    hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500 transition-colors"
                            >
                                Sélectionner
                            </button>
                        </div>
                    ))}
                </div>

                <div className="text-center mt-8">
                    <button
                        onClick={() => setIsPromoModalOpen(true)}
                        className="text-mcdm-third-500 dark:text-mcdm-primary-200 hover:text-mcdm-third-600 dark:hover:text-mcdm-primary-100 text-sm underline"
                    >
                        Vous avez un code promotionnel ?
                    </button>
                </div>

                {promoCode && (
                    <div className="mt-4 flex items-center justify-center">
                        <div className="flex items-center bg-white dark:bg-mcdm-third-400 shadow-sm rounded-full px-4 py-2 border border-mcdm-third-200 dark:border-mcdm-third-300">
                            <span className="text-sm text-mcdm-third-500 dark:text-mcdm-primary-200 font-medium mr-2">
                                {promoCode}
                            </span>
                            <button
                                onClick={handleRemovePromoCode}
                                className="text-mcdm-third-400 dark:text-mcdm-primary-300 hover:text-mcdm-third-600 dark:hover:text-mcdm-primary-100 transition-colors"
                                aria-label="Supprimer le code promo"
                            >
                                <svg
                                    className="w-4 h-4"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M18 6L6 18" />
                                    <path d="M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                )}

                <PromoCodeModal
                    isOpen={isPromoModalOpen}
                    onClose={() => setIsPromoModalOpen(false)}
                    onSubmit={handlePromoSubmit}
                    currentPromoCode={promoCode}
                />
            </div>
        </div>
    );
}