import React, { useState, useRef, useEffect } from 'react';
import Link from '../../../icons/Link';

interface LinkManagementProps {
  onAddLink: (url: string) => void;
  setForceToolbarVisible: (isVisible: boolean) => void;
}

const LinkManagement: React.FC<LinkManagementProps> = ({ onAddLink, setForceToolbarVisible }) => {
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const linkFormRef = useRef<HTMLDivElement>(null);
  const savedSelection = useRef<Range | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (linkFormRef.current && !linkFormRef.current.contains(event.target as Node)) {
        setIsAddingLink(false);
        setForceToolbarVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateUrl = (url: string) => {
    const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:\d+)?(\/.*)?$/;
    return pattern.test(url);
  };

  const handleAddLink = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
    setIsAddingLink(true);
    setForceToolbarVisible(true);
  };

  const handleLinkSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isValidUrl && savedSelection.current) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(savedSelection.current);
      }

      const selectedText = selection?.toString();
      if (selectedText) {
        const anchor = document.createElement('a');
        anchor.href = linkUrl;
        anchor.className = 'highlighted-link';
        anchor.target = '_blank';
        anchor.rel = 'noopener noreferrer';
        anchor.textContent = selectedText;

        savedSelection.current?.deleteContents();
        savedSelection.current?.insertNode(anchor);
      }

      setIsAddingLink(false);
      setLinkUrl('');
      setForceToolbarVisible(false);
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setLinkUrl(url);
    setIsValidUrl(validateUrl(url));
  };

  return (
    <div className="relative">
      <button onClick={handleAddLink} className="p-1 hover:bg-mcdm-primary-200 rounded" aria-label="Ajouter un lien">
        <Link
          width="23px"
          height="23px"
          className="text-mcdm-third-500"
        />
      </button>

      {isAddingLink && (
        <div ref={linkFormRef} className="absolute z-20 bg-white border border-gray-300 rounded shadow-md mt-2 p-2" style={{ top: '100%', left: 0 }}>
          <form onSubmit={handleLinkSubmit} className="flex flex-col space-y-2">
            <input
              type="text"
              value={linkUrl}
              onChange={handleUrlChange}
              placeholder="Entrez l'URL du lien"
              className="border rounded p-1 text-mcdm-third-500"
            />
            <button
              type="submit"
              className={`px-2 py-1 text-white rounded ${isValidUrl ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-400 cursor-not-allowed'}`}
              disabled={!isValidUrl}
            >
              Ajouter le lien
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default LinkManagement;
