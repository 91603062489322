import React, { useEffect, useState } from 'react';

interface LastSavedDateProps {
  lastSaved: Date;
}

const getTimeElapsed = (lastSaved: Date) => {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - lastSaved.getTime()) / 1000);

  if (seconds < 10) return "à l'instant";
  if (seconds < 20) return "il y a 10s";
  if (seconds < 30) return "il y a 20s";
  if (seconds < 40) return "il y a 30s";
  if (seconds < 60) return "il y a moins d'une minute";
  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
  if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `il y a ${hours} heure${hours > 1 ? 's' : ''}`;
  }
  if (seconds < 2592000) { // 30 jours
    const days = Math.floor(seconds / 86400);
    return `il y a ${days} jour${days > 1 ? 's' : ''}`;
  }
  if (seconds < 31536000) { // 365 jours
    const months = Math.floor(seconds / 2592000);
    return `il y a ${months} mois`;
  }
  const years = Math.floor(seconds / 31536000);
  return `il y a ${years} année${years > 1 ? 's' : ''}`;
};

const LastSavedDate: React.FC<LastSavedDateProps> = ({ lastSaved }) => {
  const [timeElapsed, setTimeElapsed] = useState(getTimeElapsed(lastSaved));

  useEffect(() => {
    const updateTimeElapsed = () => {
      setTimeElapsed(getTimeElapsed(lastSaved));
    };

    updateTimeElapsed();

    const interval = setInterval(updateTimeElapsed, 1000);

    return () => clearInterval(interval);
  }, [lastSaved]);

  return <span className='text-sm text-mcdm-third-400 dark:text-mcdm-primary-100 mr-3'>Sauvegardé {timeElapsed}</span>;
};

export default LastSavedDate;