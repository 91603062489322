import React, { useRef, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import Sun from '../../macadamia-core/icons/Sun';
import Moon from '../../macadamia-core/icons/Moon';
import Window from '../../macadamia-core/icons/Window';

// Définition du type pour les noms de thèmes
type ThemeKey = 'jcs' | 'eve-anne' | 'germain' | 'chaplin' | 'green';

// Map des noms de thèmes affichés avec type explicite
const themeDisplayNames: Record<ThemeKey, string> = {
    "jcs": "JCS",
    "eve-anne": "Ève-Anne",
    "germain": "Germain",
    "chaplin": "Chaplin",
    "green": "Green"
};

const getThemeImage = (theme: ThemeKey): string => {
    const images: Record<ThemeKey, string> = {
        'jcs': '/themes/theme-jcs.png',
        'eve-anne': '/themes/theme-eve-anne.png',
        'germain': '/themes/theme-germain.png',
        'chaplin': '/themes/theme-chaplin.png',
        'green': '/themes/theme-green.png'
    };
    return images[theme];
};

const ThemeSelector = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { currentTheme, isDarkMode, isSystemTheme, themes, changeTheme, toggleThemeMode } = useTheme();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleThemeChange = (theme: ThemeKey) => {
        changeTheme(theme);
        setIsOpen(false);
    };

    const handleModeChange = (mode: 'light' | 'dark' | 'system') => {
        toggleThemeMode(mode);
        setIsOpen(false);
    };

    const iconProps = {
        width: "20px",
        height: "20px"
    };

    const icons = {
        light: () => <Sun {...iconProps} />,
        dark: () => <Moon {...iconProps} />,
        system: () => <Window {...iconProps} />
    };

    const getIcon = () => {
        if (isSystemTheme) {
            return icons.system();
        }
        return isDarkMode ? icons.dark() : icons.light();
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 
             text-mcdm-third-500 dark:text-mcdm-secondary-400
             transition-colors duration-200"
                aria-label="Theme selector"
            >
                {getIcon()}
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute right-0 mt-2 w-64 bg-white dark:bg-mcdm-third-400 shadow-sm dark:shadow-sm shadow-inner dark:shadow-inner
                       rounded-lg shadow-lg py-2 z-20"
                    >
                        <div className="px-4 py-2 text-sm font-medium text-mcdm-third-500 
                          dark:text-mcdm-primary-200">
                            Mode
                        </div>
                        <div className="px-2">
                            {[
                                { mode: 'light', label: 'Clair', Icon: Sun },
                                { mode: 'dark', label: 'Sombre', Icon: Moon },
                                { mode: 'system', label: 'Système', Icon: Window }
                            ].map(({ mode, label, Icon }) => (
                                <button
                                    key={mode}
                                    onClick={() => handleModeChange(mode as 'light' | 'dark' | 'system')}
                                    className={`w-full flex items-center px-3 py-2 text-sm rounded-md
                             ${(isSystemTheme && mode === 'system') ||
                                            (!isSystemTheme && ((isDarkMode && mode === 'dark') ||
                                                (!isDarkMode && mode === 'light')))
                                            ? 'bg-mcdm-primary-200 dark:bg-mcdm-third-300 text-mcdm-third-500 dark:text-mcdm-primary-200'
                                            : 'text-mcdm-third-500 dark:text-mcdm-primary-200 hover:bg-gray-100 dark:hover:bg-mcdm-third-300'
                                        }`}
                                >
                                    <div className="w-4 h-4 mr-3 flex items-center justify-center">
                                        <Icon
                                            width="16px"
                                            height="16px"
                                        />
                                    </div>
                                    {label}
                                </button>
                            ))}
                        </div>

                        <div className="border-t border-gray-200 dark:border-gray-600 mt-2 pt-2">
                            <div className="px-4 py-2 text-sm font-medium text-mcdm-third-500 
                            dark:text-mcdm-primary-200">
                                Thème
                            </div>
                            <div className="px-2">
                                {(Object.keys(themes) as ThemeKey[]).map((theme) => (
                                    <button
                                        key={theme}
                                        onClick={() => handleThemeChange(theme)}
                                        className={`w-full text-left px-3 py-2 text-sm rounded-md flex items-center
                               ${currentTheme === theme
                                                ? 'bg-mcdm-primary-200 dark:bg-mcdm-third-300 text-mcdm-third-500 dark:text-mcdm-primary-200'
                                                : 'text-mcdm-third-500 dark:text-mcdm-primary-200 hover:bg-gray-100 dark:hover:bg-mcdm-third-300'
                                            }`}
                                    >
                                        <div className={`w-6 h-6 mr-3 rounded-full p-[2px]
                                   ${currentTheme === theme
                                                ? 'border-2 border-mcdm-primary-500 dark:border-mcdm-third-200'
                                                : 'border-2 border-transparent'
                                            }`}>
                                            <img
                                                src={getThemeImage(theme)}
                                                alt={`Theme ${themeDisplayNames[theme]}`}
                                                className="w-full h-full rounded-full object-cover"
                                            />
                                        </div>
                                        {themeDisplayNames[theme]}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ThemeSelector;