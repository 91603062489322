import React, { CSSProperties } from 'react';

interface ItalicProps {
    stroke?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Italic: React.FC<ItalicProps> = ({ stroke = '#54408e', width = '100%', height = '100%', style, className }) => {
    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <g fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px">
                <line x1="471.8" y1="735.46" x2="588.2" y2="324.54" />
            </g>
        </svg>
    );
};

export default Italic;
