import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../../services/authService';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');
    const [passwordStrength, setPasswordStrength] = useState<'poor' | 'average' | 'strong'>('poor');

    const checkPasswordStrength = (password: string) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const isLongEnough = password.length >= 8;

        let score = 0;
        if (hasUpperCase) score++;
        if (hasLowerCase) score++;
        if (hasNumbers) score++;
        if (hasSpecialChar) score++;
        if (isLongEnough) score++;

        if (score <= 2) return 'poor';
        if (score <= 3) return 'average';
        return 'strong';
    };

    const getStrengthColor = () => {
        switch (passwordStrength) {
            case 'poor':
                return 'bg-mcdm-primary-100 dark:bg-mcdm-primary-300';
            case 'average':
                return 'bg-mcdm-primary-300 dark:bg-mcdm-primary-400';
            case 'strong':
                return 'bg-mcdm-secondary-300 dark:bg-mcdm-secondary-400';
            default:
                return 'bg-gray-200 dark:bg-gray-600';
        }
    };

    const getStrengthMessage = () => {
        switch (passwordStrength) {
            case 'poor':
                return 'Votre mot de passe est facilement devinable !';
            case 'average':
                return 'Votre mot de passe est moyen, faites mieux !';
            case 'strong':
                return 'Votre mot de passe est robuste !';
            default:
                return '';
        }
    };

    const getStrengthEmoji = () => {
        switch (passwordStrength) {
            case 'poor':
                return '❄️';
            case 'average':
                return '🤏';
            case 'strong':
                return '🔥';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (password) {
            setPasswordStrength(checkPasswordStrength(password));
        }
    }, [password]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (password.length < 8) {
            setError('Le mot de passe doit contenir au moins 8 caractères');
            return;
        }

        if (passwordStrength === 'poor') {
            setError('Veuillez choisir un mot de passe plus fort');
            return;
        }

        if (!token) {
            setError('Token invalide ou expiré');
            return;
        }

        setIsLoading(true);

        try {
            await resetPassword(token, password);
            navigate('/login', {
                state: {
                    message: 'Mot de passe a été réinitialisé avec succès.'
                }
            });
        } catch (err) {
            setError('Une erreur est survenue lors de la réinitialisation du mot de passe');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500">
            <form onSubmit={handleSubmit} className="bg-white dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-lg px-8 pt-6 pb-8 mb-4 max-w-md w-full">
                <h2 className="text-xl mb-6 text-center font-400 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                    Nouveau mot de passe
                </h2>

                <div className="mb-6">
                    <label className="text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm mb-1" htmlFor="password">
                        Nouveau mot de passe
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline mb-2"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        minLength={8}
                    />
                    {password && (
                        <div className="mt-2">
                            <div className="flex justify-between items-center mb-1">
                                <span className="text-sm text-mcdm-third-500 dark:text-mcdm-primary-200">Force du mot de passe :</span>
                                <div className="flex items-center">
                                    <span className={`text-sm ${passwordStrength === 'poor' ? 'text-red-500 dark:text-red-400' :
                                            passwordStrength === 'average' ? 'text-yellow-500 dark:text-yellow-400' :
                                                'text-green-500 dark:text-green-400'
                                        } mr-2`}>
                                        {passwordStrength === 'poor' ? 'Faible' :
                                            passwordStrength === 'average' ? 'Moyen' :
                                                'Fort'}
                                    </span>
                                    <span className="text-xl">{getStrengthEmoji()}</span>
                                </div>
                            </div>
                            <div className="h-2 rounded overflow-hidden bg-gray-200 dark:bg-gray-600">
                                <div
                                    className={`h-full ${getStrengthColor()} transition-all duration-300`}
                                    style={{
                                        width: passwordStrength === 'poor' ? '30%' :
                                            passwordStrength === 'average' ? '60%' : '100%'
                                    }}
                                />
                            </div>
                            <p className="text-sm text-mcdm-third-400 dark:text-mcdm-primary-200 mt-1">
                                {getStrengthMessage()}
                            </p>
                        </div>
                    )}
                </div>

                {error && <p className="text-red-500 text-xs italic mb-4 text-center">{error}</p>}

                <button
                    className={`w-full py-2 px-4 rounded shadow-inner focus:outline-none focus:shadow-outline ${isLoading || passwordStrength !== 'strong'
                            ? 'bg-gray-400 dark:bg-gray-600 text-white dark:text-mcdm-primary-200 cursor-not-allowed opacity-50'
                            : 'bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400 hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500'
                        }`}
                    type="submit"
                    disabled={isLoading || passwordStrength !== 'strong'}
                >
                    {isLoading ? (
                        <>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Réinitialisation...
                        </>
                    ) : passwordStrength !== 'strong' ? 'Mot de passe trop faible' : 'Réinitialiser le mot de passe'
                    }
                </button>
            </form>
        </div>
    );
};

export default ResetPassword;