import React, { CSSProperties } from 'react';

interface ShareProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Share: React.FC<ShareProps> = ({
  fill = 'currentColor',
  secondaryfill,
  width = '100%',
  height = '100%',
  style,
  className,
}) => {
  const fillValue = fill || 'currentColor';
  const secondaryFillValue = secondaryfill || fillValue;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g fill={fillValue}>
        <circle
          className="cls-1"
          cx="717.5"
          cy="342.5"
          fill="none"
          r="125"
          stroke={fillValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <circle
          className="cls-1"
          cx="717.5"
          cy="717.5"
          fill="none"
          r="125"
          stroke={fillValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <circle
          className="cls-1"
          cx="342.5"
          cy="529.5"
          fill="none"
          r="125"
          stroke={fillValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <path
          className="cls-1"
          d="M458.81 471.5l142.38-71"
          fill="none"
          stroke={fillValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <path
          className="cls-1"
          d="M457.67 587.24l144.66 72.52"
          fill="none"
          stroke={fillValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
      </g>
    </svg>
  );
};

export default Share;
