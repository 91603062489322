import React from 'react';
import { IBlock, ITitleBlock, ICompositeTextWidget, IImageBlock, IGifBlock, ISuggestion, IListBlock, IVideoBlock } from './types';
import TitleWidget from './TitleWidget';
import CompositeTextWidget from './composite-text-widget/CompositeTextWidget';
import ListWidget from './ListWidget';
import ImageWidget from './ImageWidget';
import GifWidget from './GifWidget';
import VideoWidget from './video/VideoWidget';
import SuggestionItems from './SuggestionItems';
import { useMacadamia } from '../../contexts/MacadamiaContext';
import Trash from '../../icons/trash';
import Clone from '../../icons/square-2-stack';
import Arrows from '../../icons/Bars3';

interface WidgetRendererProps {
  block: IBlock | ICompositeTextWidget | IImageBlock | IVideoBlock;
  onChange: (newBlock: IBlock | ICompositeTextWidget | IImageBlock | IVideoBlock) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  suggestions: ISuggestion[];
  onAcceptSuggestion: (suggestion: ISuggestion) => void;
  onRejectSuggestion: (suggestion: ISuggestion) => void;
  dossierId: string;
  onSuggestionCreated: (newSuggestion: ISuggestion) => void;
  userRole: string | null;
}

const WidgetRenderer: React.FC<WidgetRendererProps> = ({
  block,
  onChange,
  onDuplicate,
  onDelete,
  suggestions,
  onAcceptSuggestion,
  onRejectSuggestion,
  dossierId,
  onSuggestionCreated,
  userRole
}) => {
  const { currentState } = useMacadamia();

  const renderBlockContent = () => {
    switch (block.type) {
      case 'image':
        return (
          <ImageWidget
            block={block as IImageBlock}
            onChange={onChange}
            currentState={currentState}
          />
        );
      case 'video':
        return (
          <VideoWidget
            block={block as IVideoBlock}
            onChange={onChange}
            dossierId={dossierId}
          />
        );
      case 'title':
        const titleBlock = block as ITitleBlock;
        return (
          <TitleWidget
            title={titleBlock.content}
            onTitleChange={(newTitle) => onChange({ ...titleBlock, content: newTitle } as ITitleBlock)}
          />
        );
      case 'compositeText':
        const compositeTextBlock = block as ICompositeTextWidget;
        return (
          <CompositeTextWidget
            widget={compositeTextBlock}
            onWidgetChange={onChange}
            mode={currentState}
            dossierId={dossierId}
            onSuggestionCreated={onSuggestionCreated}
            mongoId={block._id}
            userRole={userRole}
          />
        );
      case 'list':
        const listBlock = block as IListBlock;
        return (
          <ListWidget
            block={listBlock}
            onChange={(newBlock) => onChange(newBlock)}
          />
        );
      case 'gif':
        const gifBlock = block as IGifBlock;
        return <GifWidget block={gifBlock} onChange={onChange} />;
      default:
        return <span>Unknown block type: {block.type}</span>;
    }
  };

  const canViewSuggestions = userRole === 'author' || userRole === 'admin';

  return (
    <div className="block block-hover" style={{ pointerEvents: 'auto' }}>
      {currentState === 'editor' && (
        <div className="block-controls">
          <div className="icon-container">
            <div className="icon move-icon">
              <Arrows />
            </div>
            <div onClick={onDuplicate} className="icon">
              <Clone />
            </div>
            <div onClick={onDelete} className="icon">
              <Trash />
            </div>
          </div>
        </div>
      )}
      {renderBlockContent()}
      {canViewSuggestions && suggestions.length > 0 && (
        <div className="suggestions-container mt-2">
          <SuggestionItems
            suggestions={suggestions}
            onAccept={onAcceptSuggestion}
            onReject={onRejectSuggestion}
          />
        </div>
      )}
    </div>
  );
};

export default WidgetRenderer;
