import React, { useState, useEffect } from 'react';
import { useAuth } from './../../../../context/AuthContext';

interface FollowButtonProps {
    reference: string;
}

const FollowButton: React.FC<FollowButtonProps> = ({ reference }) => {
    const { token } = useAuth();
    const [isFavorite, setIsFavorite] = useState<boolean | null>(null);
    const [remainingFavorites, setRemainingFavorites] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudence-suivi`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const favorites = await response.json();
                    setRemainingFavorites(100 - favorites.length);
                    setIsFavorite(favorites.some((fav: any) => fav.jurisprudenceId === reference));
                }
            } catch (error) {
                console.error('Error fetching favorites:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchFavorites();
    }, [token, reference]);

    const handleToggleFavorite = async () => {
        if (isLoading || isFavorite === null || remainingFavorites === null) return;

        setIsLoading(true);
        try {
            if (isFavorite) {
                const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudence-suivi/${reference}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    setIsFavorite(false);
                    setRemainingFavorites(prev => prev !== null ? prev + 1 : null);
                } else {
                    throw new Error('Erreur lors de la suppression du suivi');
                }
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudence-suivi`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        jurisprudenceId: reference,
                        nombreRapprochements: 0,
                    }),
                });
                if (response.ok) {
                    setIsFavorite(true);
                    setRemainingFavorites(prev => prev !== null ? prev - 1 : null);
                }
            }
        } catch (error) {
            console.error('Error toggling favorite:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading || isFavorite === null || remainingFavorites === null) {
        return (
            <div className="flex justify-between items-center mb-4">
                <div className="h-10 bg-gray-200 rounded w-24 animate-pulse"></div>
                <div className="h-6 bg-gray-200 rounded w-32 animate-pulse"></div>
            </div>
        );
    }

    return (
        <div className="flex justify-between items-center mb-4">
            <button
                onClick={handleToggleFavorite}
                disabled={!isFavorite && remainingFavorites === 0}
                className={`px-4 py-2 rounded ${isFavorite
                        ? 'bg-red-500 text-white hover:bg-red-600'
                        : remainingFavorites > 0
                            ? 'bg-mcdm-secondary-400 text-white hover:bg-mcdm-secondary-500'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
            >
                {isFavorite ? 'Ne plus suivre' : 'Suivre'}
            </button>
            <p className="text-sm text-mcdm-third-400 dark:text-mcdm-primary-100">{remainingFavorites} ajouts restants</p>
        </div>
    );
};

export default FollowButton;