import React from 'react';

interface ServerProps {
    fill?: string;
    secondaryfill?: string;
    width?: string | number;
    height?: string | number;
    className?: string;
}

const Server: React.FC<ServerProps> = (props) => {
    const fill = props.fill || 'currentColor';
    const secondaryfill = props.secondaryfill || fill;
    const width = props.width || '100%';
    const height = props.height || '100%';
    const className = props.className || '';

    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g fill={fill}>
                <defs />
                <rect
                    className="cls-1"
                    height="219"
                    width="683"
                    fill="none"
                    rx="109.5"
                    ry="109.5"
                    stroke={fill}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                    x="188.5"
                    y="557.13"
                />
                <circle className="cls-2" cx="752.5" cy="666.67" fill={fill} r="38.04" strokeWidth="0px" />
                <circle className="cls-2" cx="639.58" cy="666.83" fill={fill} r="38.04" strokeWidth="0px" />
                <path
                    className="cls-1"
                    d="M188.5 666.62l52.81-320.85c5.88-35.7 36.74-61.9 72.92-61.9h436.14c36.58 0 67.65 26.75 73.08 62.92l48.05 319.83"
                    fill="none"
                    stroke={fill}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
            </g>
        </svg>
    );
};

export default Server;
