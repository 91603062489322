import React, { useState, useRef, useEffect } from 'react';
import { IDossier } from './widgets/types';
import XCircle from '../icons/XCircle';
import AuthorsSettings from './settings/AuthorsSettings';
import VisibilitySettings from './settings/VisibilitySettings';
import DeleteSettings from './settings/DeleteSettings';

interface DossierSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  dossier: IDossier;
  onSave: (updatedDossier: IDossier) => void;
  initialTab?: string;
  userRole: string | null;
}

const DossierSettingsModal: React.FC<DossierSettingsModalProps> = ({
  isOpen,
  onClose,
  dossier,
  onSave,
  initialTab,
  userRole
}) => {
  const [activeTab, setActiveTab] = useState(initialTab || 'Général');
  const [hoverCloseIcon, setHoverCloseIcon] = useState(false);
  const [visibility, setVisibility] = useState(dossier.settings.visibility);
  const [description, setDescription] = useState(dossier.description);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveTab(initialTab || 'Général');
  }, [initialTab]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibility(event.target.value);
  };

  const handleSave = () => {
    const updatedDossier = {
      ...dossier,
      settings: {
        ...dossier.settings,
        visibility,
      },
      description,
    };
    onSave(updatedDossier);
    onClose();
  };

  const handleAddAuthor = (email: string) => {
    // Logique pour ajouter un auteur
  };

  const handleRemoveAuthor = (authorId: string) => {
    // Logique pour retirer un auteur
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-75 z-50 flex justify-center items-center">
      <div ref={modalRef} className="bg-white dark:bg-mcdm-third-400 p-4 rounded-lg shadow-lg dark:shadow-inner w-full max-w-2xl flex flex-col">
        <div className="flex justify-between items-center mb-4 border-b border-gray-200 dark:border-mcdm-primary-100 pb-3">
          <div className="w-1/2">
            <h2 className="text-lg font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">Paramètres</h2>
          </div>
          <div className="w-1/2 text-right">
            <button
              onMouseEnter={() => setHoverCloseIcon(true)}
              onMouseLeave={() => setHoverCloseIcon(false)}
              onClick={onClose}
              className="transition-colors duration-300"
            >
              <XCircle fill={hoverCloseIcon ? '#000000' : '#c7c7c7'} width="28" height="28" />
            </button>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col w-1/4">
            <button
              className={`px-0 py-2 text-left ${activeTab === 'Général' ? 'text-mcdm-secondary-400 dark:text-mcdm-secondary-400 font-semibold' : 'text-gray-800 dark:text-mcdm-primary-100'}`}
              onClick={() => setActiveTab('Général')}
            >
              Général
            </button>
            <button
              className={`px-0 py-2 text-left ${activeTab === 'Visibilité' ? 'text-mcdm-secondary-400 dark:text-mcdm-secondary-400 font-semibold' : 'text-gray-800 dark:text-mcdm-primary-100'}`}
              onClick={() => setActiveTab('Visibilité')}
            >
              Visibilité
            </button>
            <button
              className={`px-0 py-2 text-left ${activeTab === 'Auteurs' ? 'text-mcdm-secondary-400 dark:text-mcdm-secondary-400 font-semibold' : 'text-gray-800 dark:text-mcdm-primary-100'}`}
              onClick={() => setActiveTab('Auteurs')}
            >
              Auteurs
            </button>
            <button
              className={`px-0 py-2 text-left ${activeTab === 'Suppression' ? 'text-mcdm-secondary-400 dark:text-mcdm-secondary-400 font-semibold' : 'text-gray-800 dark:text-mcdm-primary-100'}`}
              onClick={() => setActiveTab('Suppression')}
            >
              Suppression
            </button>
          </div>
          <div className="w-3/4 p-2 min-h-[280px]">
            {activeTab === 'Général' && (
              <div>
                <label className="block text-lg font-semibold mb-2 text-mcdm-third-500 dark:text-mcdm-primary-100" htmlFor="description">
                  Description
                </label>
                <textarea
                  id="description"
                  placeholder="Ajoutez une description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-mcdm-primary-100 dark:bg-mcdm-third-500 leading-tight focus:outline-none focus:shadow-outline"
                  rows={10}
                />
              </div>
            )}
            {activeTab === 'Visibilité' && (
              <VisibilitySettings
                visibility={visibility}
                onVisibilityChange={handleVisibilityChange}
              />
            )}
            {activeTab === 'Auteurs' && (
              <AuthorsSettings
                dossier={dossier}
                onAddAuthor={handleAddAuthor}
                onRemoveAuthor={handleRemoveAuthor}
              />
            )}
            {activeTab === 'Suppression' && (
              <DeleteSettings
                dossier={dossier}
                onClose={onClose}
                userRole={userRole}
              />
            )}
          </div>
        </div>
        {activeTab !== 'Suppression' && (
          <div className="flex justify-end mt-4">
            <button onClick={onClose} className="mr-4 px-4 py-2 bg-gray-200 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 rounded">
              Annuler
            </button>
            <button onClick={handleSave} className="px-4 py-2 bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500 text-white rounded">
              Sauvegarder
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DossierSettingsModal;