import React, { useState, useEffect } from 'react';
import { generateJurisprudenceTitle } from '../../../macadamia-core/components/hooks/useTitleJurisprudence';
import JurisprudenceSimilairesTab from './JurisprudenceSimilairesTab';

interface Jurisprudence {
    jurisdiction: string;
    location?: string;
    number: string;
    decision_date: string;
    summary?: string;
    themes?: string[];
    text?: string;
    nombreRapprochements?: number;
}

interface JurisprudenceItemProps {
    jurisprudenceId: string;
    onUnfollow?: (jurisprudenceId: string) => void;
}

const TabButton: React.FC<{
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-4 py-2 text-sm font-medium transition-colors duration-200 border-b-2 ${active
            ? 'border-mcdm-secondary-500 text-mcdm-secondary-500 dark:border-mcdm-secondary-400 dark:text-mcdm-secondary-400'
            : 'border-transparent text-mcdm-third-400 hover:text-mcdm-third-500 dark:text-mcdm-primary-200 dark:hover:text-mcdm-primary-100'
            }`}
    >
        {children}
    </button>
);

const JurisprudenceItem: React.FC<JurisprudenceItemProps> = ({ jurisprudenceId, onUnfollow }) => {
    const [jurisprudence, setJurisprudence] = useState<Jurisprudence | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isUnfollowing, setIsUnfollowing] = useState(false);
    const [activeTab, setActiveTab] = useState('themes');

    useEffect(() => {
        const fetchJurisprudence = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudences/decision?id=${jurisprudenceId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Erreur lors de la récupération de la jurisprudence');
                }

                const data = await response.json();
                const mappedData: Jurisprudence = {
                    ...data,
                    decision_date: data.decision_date || '',
                    themes: data.themes || [],
                    text: data.text || '',
                    nombreRapprochements: data.nombreRapprochements || 0,
                };

                setJurisprudence(mappedData);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message || 'Impossible de récupérer la jurisprudence. Veuillez réessayer.');
                } else {
                    setError('Impossible de récupérer la jurisprudence. Veuillez réessayer.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchJurisprudence();
    }, [jurisprudenceId]);

    const handleUnfollow = async () => {
        try {
            setIsUnfollowing(true);
            const response = await fetch(
                `${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudence-suivi/${jurisprudenceId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors du retrait du suivi');
            }

            if (onUnfollow) {
                onUnfollow(jurisprudenceId);
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message || 'Impossible de retirer le suivi. Veuillez réessayer.');
            }
        } finally {
            setIsUnfollowing(false);
        }
    };

    if (error) {
        return (
            <div className="bg-mcdm-secondary-500 text-mcdm-primary-100 p-2 rounded">
                {error}
            </div>
        );
    }

    if (loading) {
        return (
            <div className="bg-mcdm-primary-100 dark:bg-mcdm-third-300 p-4 rounded-lg animate-pulse">
                <div className="h-4 bg-primary-300 dark:bg-mcdm-third-500 rounded w-3/4 mb-2"></div>
                <div className="h-3 bg-primary-300 dark:bg-mcdm-third-500 rounded w-full"></div>
            </div>
        );
    }

    if (!jurisprudence) {
        return null;
    }

    const title = generateJurisprudenceTitle(
        jurisprudence.jurisdiction,
        jurisprudence.location || '',
        jurisprudence.number,
        jurisprudence.decision_date
    );

    const renderTabContent = () => {
        switch (activeTab) {
            case 'themes':
                return (
                    <div className="p-4">
                        {jurisprudence.themes && jurisprudence.themes.length > 0 ? (
                            <div className="flex flex-wrap gap-2">
                                {jurisprudence.themes.map((theme, index) => (
                                    <span
                                        key={index}
                                        className="px-3 py-1 text-sm font-medium rounded-full shadow-inner
                                                 bg-mcdm-primary-100 text-mcdm-third-500
                                                 dark:bg-mcdm-primary-100 dark:text-mcdm-third-500
                                                 border border-mcdm-third-300 dark:border-mcdm-primary-500"
                                    >
                                        {theme}
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <p className="text-mcdm-third-400 dark:text-mcdm-primary-200">Aucun thème disponible</p>
                        )}
                    </div>
                );
            case 'contenu':
                return (
                    <div className="p-4">
                        <p className="text-sm text-mcdm-third-400 dark:text-mcdm-primary-100 whitespace-pre-wrap">
                            {jurisprudence.text || 'Aucun contenu disponible'}
                        </p>
                    </div>
                );
            case 'similaires':
                return (
                    <JurisprudenceSimilairesTab 
                        jurisprudenceId={jurisprudenceId}
                        themes={jurisprudence.themes}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="bg-mcdm-primary-100 dark:bg-mcdm-third-300 rounded-lg hover:shadow-md dark:hover:shadow-md transition-all duration-200">
            <div className="p-4">
                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:justify-between sm:items-start">
                    <button
                        onClick={handleUnfollow}
                        disabled={isUnfollowing}
                        className="sm:order-2 sm:ml-4 w-full sm:w-auto px-3 py-1 text-sm font-medium 
                                 text-mcdm-secondary-500 dark:text-mcdm-secondary-400 
                                 dark:hover:text-mcdm-primary-100 bg-transparent hover:bg-mcdm-secondary-200 
                                 dark:hover:bg-mcdm-secondary-300 border border-mcdm-secondary-500 
                                 dark:border-mcdm-secondary-400 rounded-md transition-colors duration-200 
                                 disabled:opacity-50"
                    >
                        {isUnfollowing ? 'Suppression...' : 'Ne plus suivre'}
                    </button>
                    <div className="flex-1 sm:order-1">
                        <p className="text-lg font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                            {title}
                        </p>
                        <p className="text-sm text-mcdm-third-400 dark:text-mcdm-primary-100 mt-2">
                            {jurisprudence.summary || 'Aucun résumé disponible.'}
                        </p>
                    </div>
                </div>
            </div>

            <div className="border-t border-mcdm-third-200 dark:border-mcdm-third-600">
                <div className="flex border-b border-mcdm-third-200 dark:border-mcdm-third-600">
                    <TabButton active={activeTab === 'themes'} onClick={() => setActiveTab('themes')}>
                        Thèmes
                    </TabButton>
                    <TabButton active={activeTab === 'contenu'} onClick={() => setActiveTab('contenu')}>
                        Contenu
                    </TabButton>
                    <TabButton active={activeTab === 'similaires'} onClick={() => setActiveTab('similaires')}>
                        Cas similaires
                    </TabButton>
                </div>
                {renderTabContent()}
            </div>
        </div>
    );
};

export default JurisprudenceItem;