import React, { useState, useEffect, useRef } from 'react';
import ExclamationCircle from '../../../icons/ExclamationCircle';

interface ReaderToolbarProps {
  onSuggestion: (suggestion: string) => void;
  onVisibilityChange: (isVisible: boolean) => void;
}

const ReaderToolbar: React.FC<ReaderToolbarProps> = ({ onSuggestion, onVisibilityChange }) => {
  const [isSuggesting, setIsSuggesting] = useState(false);
  const [suggestionText, setSuggestionText] = useState('');
  const toolbarRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSuggestionClick = () => {
    setIsSuggesting(true);
    onVisibilityChange(true);
  };

  const handleSendSuggestion = () => {
    if (suggestionText.trim().length > 0) {
      onSuggestion(suggestionText);
      setIsSuggesting(false);
      setSuggestionText('');
      onVisibilityChange(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toolbarRef.current &&
      !toolbarRef.current.contains(event.target as Node) &&
      textareaRef.current &&
      !textareaRef.current.contains(event.target as Node)
    ) {
      setIsSuggesting(false);
      onVisibilityChange(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={toolbarRef}
      className="bg-white shadow-lg rounded-lg p-1 flex space-x-2 readertoolbar"
      style={{
        transform: isSuggesting ? 'translateY(-70px)' : 'translateY(0)',
        zIndex: 2 
      }}
    >
      {isSuggesting ? (
        <div className="flex flex-col space-y-2">
          <textarea
            ref={textareaRef}
            className="border rounded p-1"
            placeholder="Quelle serait votre suggestion ?"
            value={suggestionText}
            onChange={(e) => setSuggestionText(e.target.value)}
          />
          <button
            onClick={handleSendSuggestion}
            className={`px-2 py-1 rounded ${suggestionText.trim().length === 0
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-green-500 text-white cursor-pointer'
              }`}
            disabled={suggestionText.trim().length === 0}
          >
            Envoyer
          </button>
        </div>
      ) : (
        <button
          onClick={handleSuggestionClick}
          className="px-2 py-1 rounded flex items-center justify-center space-x-1"
          aria-label="Faire une suggestion"
        >
          <ExclamationCircle width="24" height="24" fill="black" />
          <span className="text-mcdm-third-500 dark:text-mcdm-third-500">Suggestion</span>
        </button>
      )}
    </div>
  );
};

export default ReaderToolbar;
