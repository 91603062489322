import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../../../services/authService';
import { useAuth } from '../../../context/AuthContext';

const Register = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [civility, setCivility] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');
        setIsSubmitting(true);
        try {
            const response = await register({ email, password, civility, firstName, lastName });
            setIsSubmitting(false);
            if (response.token) {
                auth.login(response.token, response.user);
                navigate('/mes-dossiers');
            } else {
                setError('Inscription réussie mais connexion automatique impossible');
            }
        } catch (error: any) {
            console.error('Erreur lors de la création du compte', error);
            setError(error.message || 'Erreur lors de la création du compte. Veuillez réessayer.');
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500">
            <form onSubmit={handleSubmit} className="bg-white dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-lg px-8 pt-6 pb-8 mb-4">
                <h2 className="text-xl mb-6 text-center font-400 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                    Inscription
                </h2>

                <div className="mb-4">
                    <label className="block text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm mb-2" htmlFor="civility">
                        Civilité
                    </label>
                    <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
                        id="civility"
                        value={civility}
                        onChange={(e) => setCivility(e.target.value)}
                        required
                    >
                        <option value="" disabled>Choisir une option</option>
                        <option value="Madame">Madame</option>
                        <option value="Monsieur">Monsieur</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm mb-2" htmlFor="firstName">
                        Prénom
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
                        id="firstName"
                        type="text"
                        placeholder="Prénom"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm mb-2" htmlFor="lastName">
                        Nom
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastName"
                        type="text"
                        placeholder="Nom"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm mb-2" htmlFor="password">
                        Mot de passe
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        placeholder="******************"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>

                {error && <p className="text-red-500 text-xs italic mb-4 text-center">{error}</p>}

                <div className="flex flex-col items-center">
                    <button
                        className={`w-full bg-mcdm-secondary-400 hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500 dark:bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400 py-2 px-4 rounded shadow-inner focus:outline-none focus:shadow-outline mb-4 ${
                            isSubmitting 
                                ? 'opacity-50 cursor-not-allowed' 
                                : 'hover:bg-mcdm-secondary-400 dark:hover:bg-mcdm-secondary-500'
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Inscription...
                            </>
                        ) : (
                            'S\'inscrire'
                        )}
                    </button>

                    <Link 
                        to="/login" 
                        className="text-sm text-mcdm-secondary-300 dark:text-mcdm-secondary-100 hover:text-mcdm-secondary-400 dark:hover:text-mcdm-secondary-200"
                    >
                        Déjà un compte ? Se connecter
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default Register;