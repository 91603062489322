import React, { CSSProperties } from 'react';

interface SunProps {
    stroke?: string;
    fill?: string;
    secondaryfill?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Sun: React.FC<SunProps> = ({
    fill = 'currentColor',
    secondaryfill,
    width = '100%',
    height = '100%',
    style
}) => {
    const actualSecondaryFill = secondaryfill || fill;

    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <g fill={fill}>
                <defs />
                <circle
                    className="cls-1"
                    cx="530"
                    cy="530"
                    fill="none"
                    r="163.52"
                    stroke={fill}
                    strokeMiterlimit="10"
                    strokeWidth="50px"
                />
                <path
                    className="cls-2"
                    d="M530 147c-12.07 0-21.86 9.79-21.86 21.86v65.58c0 12.07 9.79 21.86 21.86 21.86s21.86-9.79 21.86-21.86v-65.58c0-12.07-9.79-21.86-21.86-21.86z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M530 803.7c-12.07 0-21.86 9.79-21.86 21.86v65.58c0 12.07 9.79 21.86 21.86 21.86s21.86-9.79 21.86-21.86v-65.58c0-12.07-9.79-21.86-21.86-21.86z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M290.09 259.18c-8.54-8.54-22.38-8.54-30.92 0-8.54 8.54-8.54 22.38 0 30.92l46.37 46.37c8.54 8.54 22.38 8.54 30.92 0 8.54-8.54 8.54-22.38 0-30.92l-46.37-46.37z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M754.45 723.53c-8.54-8.54-22.38-8.54-30.92 0-8.54 8.54-8.54 22.38 0 30.92l46.37 46.37c8.54 8.54 22.38 8.54 30.92 0 8.54-8.54 8.54-22.38 0-30.92l-46.37-46.37z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M256.3 530c0-12.07-9.79-21.86-21.86-21.86h-65.58c-12.07 0-21.86 9.79-21.86 21.86s9.79 21.86 21.86 21.86h65.58c12.07 0 21.86-9.79 21.86-21.86z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M891.14 508.14h-65.58c-12.07 0-21.86 9.79-21.86 21.86s9.79 21.86 21.86 21.86h65.58c12.07 0 21.86-9.79 21.86-21.86s-9.79-21.86-21.86-21.86z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M723.53 336.47c8.54 8.54 22.38 8.54 30.92 0l46.37-46.37c8.54-8.54 8.54-22.38 0-30.92-8.54-8.54-22.38-8.54-30.92 0l-46.37 46.37c-8.54 8.54-8.54 22.38 0 30.92z"
                    fill={fill}
                    strokeWidth="0px"
                />
                <path
                    className="cls-2"
                    d="M305.55 723.53l-46.37 46.37c-8.54 8.54-8.54 22.38 0 30.92 8.54 8.54 22.38 8.54 30.92 0l46.37-46.37c8.54-8.54 8.54-22.38 0-30.92-8.54-8.54-22.38-8.54-30.92 0z"
                    fill={fill}
                    strokeWidth="0px"
                />
            </g>
        </svg>
    );
};

export default Sun;