import React from 'react';

interface LinkPopoverProps {
  url: string;
  onRemove: () => void;
  position: { top: number; left: number };
}

const LinkPopover: React.FC<LinkPopoverProps> = ({ url, onRemove, position }) => {
  return (
    <div 
      className="absolute z-50 bg-mcdm-primary-100 dark:bg-mcdm-primary-100 border border-gray-300 rounded shadow-md p-2"
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <p className="mb-2 text-sm text-mcdm-third-500">
        <strong>Lien :</strong> <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
      </p>
      <button 
        onClick={onRemove}
        className="bg-red-500 hover:bg-red-600 text-white text-sm py-1 px-2 rounded"
      >
        Supprimer le lien
      </button>
    </div>
  );
};

export default LinkPopover;