import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback, useMemo } from 'react';
import { useEditor } from './EditorContext';

interface MacadamiaContextType {
  currentState: 'editor' | 'reader';
  switchState: (newState: 'editor' | 'reader') => void;
}

interface MacadamiaProviderProps {
  children: ReactNode;
}

export const MacadamiaContext = createContext<MacadamiaContextType | undefined>(undefined);

export const MacadamiaProvider: React.FC<MacadamiaProviderProps> = ({ children }) => {
  const [currentState, setCurrentState] = useState<'editor' | 'reader'>('reader');
  
  // Accédez au contexte d'édition uniquement pour la fonction saveContent
  const { saveContent } = useEditor();

  // Optimisation: useCallback pour éviter de recréer cette fonction à chaque rendu
  const switchState = useCallback((newState: 'editor' | 'reader') => {
    setCurrentState(prevState => {
      // N'effectuez le changement que si l'état est différent pour éviter les re-rendus inutiles
      if (prevState !== newState) {
        return newState;
      }
      return prevState;
    });
  }, []);

  // Utilisez useEffect pour déclencher une sauvegarde uniquement quand on passe de editor à reader
  useEffect(() => {
    // Sauvegarde uniquement lors du passage de l'éditeur au lecteur
    if (currentState === 'reader') {
      saveContent();
    }
  }, [currentState, saveContent]);

  // Memoïsation de la valeur du contexte pour éviter des re-rendus inutiles
  const contextValue = useMemo(() => ({
    currentState,
    switchState
  }), [currentState, switchState]);

  return (
    <MacadamiaContext.Provider value={contextValue}>
      {children}
    </MacadamiaContext.Provider>
  );
};

export const useMacadamia = (): MacadamiaContextType => {
  const context = useContext(MacadamiaContext);
  if (context === undefined) {
    throw new Error('useMacadamia must be used within a MacadamiaProvider');
  }
  return context;
};