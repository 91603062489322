import { useState, useEffect } from 'react';

// Définition des breakpoints
export const BREAKPOINTS = {
  mobile: 768,
  tablet: 1024,
  desktop: 1280
} as const;

export type Viewport = 'mobile' | 'tablet' | 'desktop';

export function useViewport() {
  const [viewport, setViewport] = useState<Viewport>('desktop');

  useEffect(() => {
    // Fonction pour déterminer le viewport actuel
    const determineViewport = () => {
      const width = window.innerWidth;
      
      if (width < BREAKPOINTS.mobile) {
        setViewport('mobile');
      } else if (width < BREAKPOINTS.tablet) {
        setViewport('tablet');
      } else {
        setViewport('desktop');
      }
    };

    // Déterminer le viewport initial
    determineViewport();

    // Créer un debounced handler pour éviter trop d'appels
    let timeoutId: NodeJS.Timeout;
    const debouncedHandler = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(determineViewport, 250);
    };

    // Ajouter l'écouteur d'événement
    window.addEventListener('resize', debouncedHandler);

    // Nettoyer
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', debouncedHandler);
    };
  }, []);

  return {
    viewport,
    isMobile: viewport === 'mobile',
    isTablet: viewport === 'tablet', 
    isDesktop: viewport === 'desktop'
  };
}