import React, { useState } from 'react';

interface CreateFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFolderCreated: (folder: any) => void;
}

const CreateFolderModal: React.FC<CreateFolderModalProps> = ({ isOpen, onClose, onFolderCreated }) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOutsideClick = (e: React.MouseEvent) => {
    if ((e.target as Element).classList.contains('modal-overlay')) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    setError(null);
    setIsLoading(true);

    if (!title || !description) {
      setError('Le titre et la description sont obligatoires.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          title,
          description
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la création du dossier');
      }

      const newDossier = await response.json();
      onFolderCreated(newDossier);
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message || 'Impossible de créer le dossier. Veuillez réessayer.');
      } else {
        setError('Impossible de créer le dossier. Veuillez réessayer.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center modal-overlay z-50"
      onClick={handleOutsideClick}
    >
      <div
        className="bg-white dark:bg-mcdm-third-400 p-6 rounded-lg shadow-lg max-w-md w-full mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100 mb-4">
          Créer un nouveau dossier
        </h2>

        {error && (
          <p className="text-red-500 dark:text-red-400 mb-4 text-sm">
            {error}
          </p>
        )}

        <div className="mb-4">
          <label
            className="block text-mcdm-third-500 dark:text-mcdm-primary-200 mb-2"
            htmlFor="folder-title"
          >
            Titre du dossier
          </label>
          <input
            id="folder-title"
            type="text"
            className="w-full p-2 border border-mcdm-third-200 dark:border-mcdm-third-300 
                     rounded focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 
                     dark:focus:ring-mcdm-secondary-400 bg-white dark:bg-mcdm-primary-100 
                     text-mcdm-third-500 dark:text-mcdm-third-100"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-mcdm-third-500 dark:text-mcdm-primary-200 mb-2"
            htmlFor="folder-description"
          >
            Description du dossier
          </label>
          <textarea
            id="folder-description"
            className="w-full p-2 border border-mcdm-third-200 dark:border-mcdm-third-300 
                     rounded focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 
                     dark:focus:ring-mcdm-secondary-400 bg-white dark:bg-mcdm-primary-100 
                     text-mcdm-third-500 dark:text-mcdm-third-100"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={4}
            disabled={isLoading}
          />
        </div>

        <div className="flex gap-4 justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-mcdm-third-500 dark:text-mcdm-primary-200 
                     border border-mcdm-third-200 dark:border-mcdm-third-300 
                     rounded hover:bg-mcdm-third-50 dark:hover:bg-mcdm-third-300 
                     transition-colors disabled:opacity-50"
            disabled={isLoading}
          >
            Annuler
          </button>

          <button
            className={`bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400 
                     shadow-inner px-4 py-2 rounded hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500 
                     transition-colors disabled:opacity-50 ${isLoading ? 'cursor-not-allowed' : ''
              }`}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10"
                    stroke="currentColor" strokeWidth="4"
                  />
                  <path className="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Création...
              </>
            ) : 'Créer dossier'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;