import React, { useState, useEffect } from 'react';
import { IDossier, IAuthor, IInvitation } from './../widgets/types';
import { useAuth } from '../../../context/AuthContext';
import Trash from '../../icons/trash';

interface AuthorsSettingsProps {
  dossier: IDossier;
  onAddAuthor: (email: string) => void;
  onRemoveAuthor: (authorId: string) => void;
}

const AuthorsSettings: React.FC<AuthorsSettingsProps> = ({ dossier, onAddAuthor, onRemoveAuthor }) => {
  const [email, setEmail] = useState('');
  const [coAuthors, setCoAuthors] = useState<IAuthor[]>(dossier.coAuthors || []);
  const [invitations, setInvitations] = useState<IInvitation[]>([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();

  useEffect(() => {
    const fetchCoAuthors = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${dossier._id}/authors`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Co-authors data:', data);
          setCoAuthors(data.coAuthors);
        } else {
          console.error('Erreur lors de la récupération des co-auteurs');
        }
      } catch (error) {
        console.error('Erreur réseau:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoAuthors();
  }, [dossier._id, token]);

  useEffect(() => {
    const fetchInvitations = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/invitations/dossier/${dossier._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const invitationsData = await response.json();
          setInvitations(invitationsData);
        } else {
          console.error('Erreur lors de la récupération des invitations.');
        }
      } catch (error) {
        console.error('Erreur réseau:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvitations();
  }, [dossier._id, token]);

  const handleAddAuthor = async () => {
    if (email) {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/invitations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            dossierId: dossier._id,
            email,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Invitation créée:', data);
          onAddAuthor(email);
          setEmail('');
          // Refresh invitations after adding a new one
          const updatedInvitations = [...invitations, data.invitation];
          setInvitations(updatedInvitations);
        } else {
          const errorData = await response.json();
          console.error('Erreur lors de la création de l\'invitation:', errorData);
        }
      } catch (error) {
        console.error('Erreur réseau:', error);
      }
    }
  };

  const handleRemoveAuthor = async (authorId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${dossier._id}/authors/${authorId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        // Suppression côté client après succès du serveur
        console.log('Co-auteur supprimé avec succès du serveur.');
        setCoAuthors(coAuthors.filter((author) => author._id !== authorId));
      } else {
        const errorData = await response.json();
        console.error('Erreur lors de la suppression du co-auteur:', errorData);
      }
    } catch (error) {
      console.error('Erreur réseau lors de la suppression du co-auteur:', error);
    }
  };  

  const handleRemoveInvitation = async (invitationId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/invitations/${invitationId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Update the state after successful deletion
        const updatedInvitations = invitations.filter(inv => inv._id !== invitationId);
        setInvitations(updatedInvitations);
      } else {
        console.error('Erreur lors de la suppression de l\'invitation.');
      }
    } catch (error) {
      console.error('Erreur réseau:', error);
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold">Gestion des auteurs</h3>
      <div className="mt-4">
        <input
          type="email"
          placeholder="Email de l'auteur"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <button
          onClick={handleAddAuthor}
          className="mt-2 px-4 py-2 bg-mcdm-vert-400 text-white rounded"
        >
          Ajouter
        </button>
      </div>
      <ul className="mt-4">
        {loading ? (
          dossier.coAuthors.map((_, index) => (
            <li key={index} className="mb-4 bg-gray-300 h-6 w-full rounded animate-pulse"></li>
          ))
        ) : (
          coAuthors.map((author, index) => (
            <li key={author._id || index} className="flex justify-between items-center mb-4">
              {author && (
                <>
                  <span>{author.firstName || 'Prénom inconnu'} {author.lastName || 'Nom inconnu'} ({author.email || 'Email inconnu'})</span>
                  <button
                    onClick={() => handleRemoveAuthor(author._id)}
                    className="text-red-500"
                  >
                    <Trash fill="red" />
                  </button>

                </>
              )}
            </li>
          ))
        )}
      </ul>
      <div className="mt-4 border-t border-gray-300 pt-4">
        <h4 className="text-lg font-semibold">Invitations envoyées</h4>
        <ul className="mt-4">
          {loading ? (
            [...Array(3)].map((_, index) => (
              <li key={index} className="mb-4 bg-gray-300 h-6 w-full rounded animate-pulse"></li>
            ))
          ) : (
            invitations.filter(invitation => invitation.status !== 'accepted').length > 0 ? (
              invitations
                .filter(invitation => invitation.status !== 'accepted')
                .map((invitation) => (
                  <li key={invitation._id} className="flex justify-between items-center mb-4">
                    <span>{invitation.email} - {invitation.status === 'pending' ? 'En attente' : invitation.status}</span>
                    <button
                      onClick={() => handleRemoveInvitation(invitation._id)}
                      className="text-red-500"
                    >
                      <Trash fill="red" />
                    </button>
                  </li>
                ))
            ) : (
              <p>Aucune invitation envoyée.</p>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default AuthorsSettings;
