import React, { useState, useEffect } from 'react';
import { IListBlock } from './types';
import { useMacadamia } from '../../contexts/MacadamiaContext';

interface ListWidgetProps {
  block: IListBlock;
  onChange: (newBlock: IListBlock) => void;
}

const ListWidget: React.FC<ListWidgetProps> = ({ block, onChange }) => {
  const { currentState } = useMacadamia();
  const [items, setItems] = useState<string[]>(block.items || ['']);

  useEffect(() => {
    onChange({ ...block, items });
  }, [items]);

  const handleItemChange = (index: number, value: string) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([...items, '']);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, idx) => idx !== index);
    setItems(newItems);
  };

  return (
    <div className="list-widget pl-4">
      {currentState === 'editor' ? (
        <div className="flex flex-col">
          {items.map((item, index) => (
            <div key={index} className="list-item flex items-center mb-2">
              <input
                type="text"
                value={item}
                onChange={(e) => handleItemChange(index, e.target.value)}
                className="flex-grow mr-2"
              />
              <button
                onClick={() => handleRemoveItem(index)}
                className="text-red-500"
                aria-label={`Supprimer l'élément ${index + 1}`}
              >
                Supprimer
              </button>
            </div>
          ))}
          <button
            onClick={handleAddItem}
            className="text-blue-500 mt-2 self-start"
          >
            Ajouter un élément
          </button>
        </div>
      ) : (
        <ul className="list-disc pl-5">
          {items.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListWidget;
