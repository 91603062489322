import React from 'react';

interface LinkProps {
  width?: string;
  height?: string;
  className?: string;
}

const Link: React.FC<LinkProps> = ({ width = '100%', height = '100%', className = '' }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px">
        <path
          d="m440.51,395.85s.36-.36,89.31-89.31c88.96-88.96,178.63,0,178.63,0,0,0,89.67,88.96,0,178.63l-89.67,89.67s1.43-1.43-43.94,43.94c-45.37,45.37-89.31,0-89.31,0,0,0-44.66-44.66,0-89.31"
        />
        <path
          d="m619.49,664.15s-.36.36-89.31,89.31c-88.96,88.96-178.63,0-178.63,0,0,0-89.67-88.96,0-178.63l89.67-89.67s-1.43,1.43,43.94-43.94c45.37-45.37,89.31,0,89.31,0,0,0,44.66,44.66,0,89.31"
        />
      </g>
    </svg>
  );
};

export default Link;