import React, { CSSProperties } from 'react';

interface PlusProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Plus: React.FC<PlusProps> = ({
  fill = 'currentColor',
  secondaryfill,
  width = '19px',
  height = '19px',
  style,
  className,
}) => {
  const fillValue = fill || 'currentColor';
  const secondaryFillValue = secondaryfill || fillValue;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g fill={fillValue}>
        <path
          d="M530 155v750m375-375H155"
          fill="none"
          stroke={fillValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
      </g>
    </svg>
  );
};


export default Plus;
