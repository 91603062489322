import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { IDossier } from '../components/widgets/types';

export interface JuridicalReference {
    id: string;
    type: 'jurisprudence' | 'legifrance';
    text: string;
    blockId: string;
    details: {
        [key: string]: any;
    };
}

interface JuridicalReferencesContextType {
    references: JuridicalReference[];
    totalReferences: number;
    updateReferences: (dossierContent: IDossier | null) => void;
    addReference: (reference: JuridicalReference) => void;
    removeReference: (referenceId: string) => void;
}

const JuridicalReferencesContext = createContext<JuridicalReferencesContextType>({
    references: [],
    totalReferences: 0,
    updateReferences: () => { },
    addReference: () => { },
    removeReference: () => { },
});

export const useJuridicalReferences = () => useContext(JuridicalReferencesContext);

interface JuridicalReferencesProviderProps {
    children: ReactNode;
    initialDossier: IDossier | null;
}

export const JuridicalReferencesProvider: React.FC<JuridicalReferencesProviderProps> = ({
    children,
    initialDossier
}) => {
    const [references, setReferences] = useState<JuridicalReference[]>([]);
    const [lastProcessedDossierId, setLastProcessedDossierId] = useState<string | null>(null);

    // Fonction pour extraire les références juridiques du HTML
    const extractReferencesFromHTML = (html: string, blockId: string): JuridicalReference[] => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const extractedReferences: JuridicalReference[] = [];

        // Extraire les références de jurisprudence
        const jurisprudenceElements = doc.querySelectorAll('.jurisprudence-highlight');
        jurisprudenceElements.forEach((element) => {
            const referenceId = element.getAttribute('data-reference-id');
            if (referenceId) {
                extractedReferences.push({
                    id: referenceId,
                    type: 'jurisprudence',
                    text: element.textContent || '',
                    blockId,
                    details: {
                        referenceId
                    }
                });
            }
        });

        // Extraire les références Legifrance
        const legifranceElements = doc.querySelectorAll('.legifrance-highlight');
        legifranceElements.forEach((element) => {
            const codeId = element.getAttribute('data-legifrance-code');
            const sectionId = element.getAttribute('data-legifrance-section');
            const articleId = element.getAttribute('data-legifrance-article');

            if (codeId) {
                const refId = `${codeId}-${sectionId || ''}-${articleId || ''}`;
                extractedReferences.push({
                    id: refId,
                    type: 'legifrance',
                    text: element.textContent || '',
                    blockId,
                    details: {
                        codeId,
                        sectionId: sectionId || undefined,
                        articleId: articleId || undefined
                    }
                });
            }
        });

        return extractedReferences;
    };

    // Fonction pour mettre à jour les références à partir du contenu du dossier
    // Mémoriser cette fonction avec useCallback pour éviter les recréations inutiles
    const updateReferences = useCallback((dossier: IDossier | null) => {
        if (!dossier) {
            setReferences([]);
            return;
        }

        // Vérifier si c'est le même dossier que celui déjà traité
        // Si oui, on évite une mise à jour inutile
        if (lastProcessedDossierId === dossier._id) {
            return;
        }

        const allReferences: JuridicalReference[] = [];

        // Parcourir tous les chapitres, sections et blocs
        dossier.content.forEach(chapter => {
            chapter.sections.forEach(section => {
                section.blocks.forEach(block => {
                    // Vérifier si c'est un bloc de texte composite
                    if (block.type === 'compositeText') {
                        const compositeTextBlock = block as any; // Utiliser any pour contourner les erreurs de typage

                        // Parcourir tous les sous-blocs de texte
                        if (compositeTextBlock.content && compositeTextBlock.content.blocks) {
                            compositeTextBlock.content.blocks.forEach((textBlock: any) => {
                                // Extraire les références du HTML brut
                                if (textBlock.rawText) {
                                    const blockReferences = extractReferencesFromHTML(textBlock.rawText, block._id);
                                    allReferences.push(...blockReferences);
                                }
                            });
                        }
                    }
                });
            });
        });

        // Mettre à jour l'état avec toutes les références trouvées
        setReferences(allReferences);
        // Mettre à jour l'ID du dernier dossier traité
        setLastProcessedDossierId(dossier._id);
    }, [lastProcessedDossierId]); // Dépendance seulement à lastProcessedDossierId

    // Initialiser les références au chargement du dossier
    useEffect(() => {
        if (initialDossier && initialDossier._id !== lastProcessedDossierId) {
            updateReferences(initialDossier);
        }
    // Dépendance explicite à initialDossier._id plutôt qu'à l'objet complet
    }, [initialDossier?._id, lastProcessedDossierId, updateReferences]);

    // Fonction pour ajouter une référence
    const addReference = useCallback((reference: JuridicalReference) => {
        setReferences(prev => {
            // Vérifier si la référence existe déjà
            const existingIndex = prev.findIndex(ref => ref.id === reference.id);
            if (existingIndex >= 0) {
                // Mettre à jour la référence existante si elle est différente
                if (JSON.stringify(prev[existingIndex]) !== JSON.stringify(reference)) {
                    const updated = [...prev];
                    updated[existingIndex] = reference;
                    return updated;
                }
                return prev; // Pas de mise à jour si identique
            } else {
                // Ajouter la nouvelle référence
                return [...prev, reference];
            }
        });
    }, []);

    // Fonction pour supprimer une référence
    const removeReference = useCallback((referenceId: string) => {
        setReferences(prev => prev.filter(ref => ref.id !== referenceId));
    }, []);

    return (
        <JuridicalReferencesContext.Provider
            value={{
                references,
                totalReferences: references.length,
                updateReferences,
                addReference,
                removeReference
            }}
        >
            {children}
        </JuridicalReferencesContext.Provider>
    );
};