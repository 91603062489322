import React, { CSSProperties } from 'react';

interface MoonProps {
    stroke?: string;
    fill?: string;
    secondaryfill?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Moon: React.FC<MoonProps> = ({
    stroke = 'currentColor',
    fill = 'currentColor',
    secondaryfill,
    width = '100%',
    height = '100%',
    style
}) => {
    const actualSecondaryFill = secondaryfill || fill;

    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <g fill={fill}>
                <defs />
                <path
                    className="cls-1"
                    d="M650.17 732.58c-128.91-36.01-222.94-147.92-236.6-281.06-9.76-95.11 19.78-181.68 73.55-246.85.09-.11-.01-.27-.15-.24-126.55 31.9-226.03 137.3-246.07 271.49-29.45 197.27 122.54 376.54 321.97 379.72 103.28 1.65 197.49-44.81 259.62-119.73.09-.11-.01-.27-.15-.24-53.86 13.42-112.6 13.54-172.18-3.1z"
                    fill="none"
                    stroke={stroke}
                    strokeMiterlimit="10"
                    strokeWidth="50px"
                />
            </g>
        </svg>
    );
};

export default Moon;