import React, { useRef, useEffect, useState } from 'react';
import { ITextBlock, ISuggestion } from '../types';
import Toolbar from './Toolbar';
import ReaderToolbar from './ReaderToolbar';
import JurisprudenceModal from './JurisprudenceModal';
import LinkPopover from './LinkPopover';
import { useAuth } from '../../../../context/AuthContext';

interface SubBlockEditorProps {
  textBlock: ITextBlock;
  onTextBlockChange: (newTextBlock: ITextBlock) => void;
  mode: 'editor' | 'reader';
  dossierId: string;
  onSuggestionCreated: (newSuggestion: ISuggestion) => void;
  compositeBlockId: string;
  userRole: string | null;
}

interface decisionLink {
  title: string;
  number: string;
  description: string;
  theme: string[];
  url: string;
}

interface JurisprudenceData {
  reference: string;
  jurisdiction: string;
  chamber: string;
  number: string;
  ecli: string;
  formation: string;
  publication: string[];
  decisionDate: string;
  solution: string;
  summary: string;
  themes: string[];
  text: string;
  visa?: { title: string }[];
  contested?: {
    date: string;
    title: string;
    jurisdiction: string;
    chamber: string;
    solution: string;
    number: string;
  };
  rapprochements?: decisionLink[];
}

interface LinkPopoverState {
  isVisible: boolean;
  url: string;
  position: { top: number; left: number };
}

const SubBlockEditor: React.FC<SubBlockEditorProps> = ({
  textBlock,
  onTextBlockChange,
  mode,
  dossierId,
  onSuggestionCreated,
  compositeBlockId,
  userRole
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [readerToolbarVisible, setReaderToolbarVisible] = useState(false);
  const [readerToolbarPosition, setReaderToolbarPosition] = useState({ top: 0, left: 0 });
  const [forceToolbarVisible, setForceToolbarVisible] = useState(false);
  const [forceReaderToolbarVisible, setForceReaderToolbarVisible] = useState(false);
  const API_KEY = process.env.REACT_APP_CLE_API_JUDILIBRE;
  const { token } = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [jurisprudenceData, setJurisprudenceData] = useState<JurisprudenceData | null>(null);
  const [isLoadingJurisprudence, setIsLoadingJurisprudence] = useState(false);
  const [linkPopover, setLinkPopover] = useState<LinkPopoverState>({
    isVisible: false,
    url: '',
    position: { top: 0, left: 0 },
  });

  useEffect(() => {
    if (editorRef.current && editorRef.current.innerHTML !== textBlock.rawText) {
      editorRef.current.innerHTML = textBlock.rawText;
    }
    checkPlaceholderVisibility();
  }, [textBlock.rawText]);

  const handleContentChange = () => {
    if (mode !== 'editor') return;

    if (editorRef.current) {
      const newContent = editorRef.current.innerHTML;
      onTextBlockChange({ ...textBlock, rawText: newContent });
      checkPlaceholderVisibility();
    }
  };

  const handleFormat = (command: string, value?: string) => {
    if (mode !== 'editor') return;

    document.execCommand(command, false, value);
    handleContentChange();
  };

  const handleAddEntity = (type: string, data: any) => {
    if (mode !== 'editor') return;

    if (type === 'jurisprudenceReference') {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const span = document.createElement('span');
        span.className = 'jurisprudence-highlight';
        span.dataset.referenceId = data.reference;
        span.textContent = selection.toString();
        range.deleteContents();
        range.insertNode(span);
        handleContentChange();
      }
    }

    if (type === 'link') {
      const selection = window.getSelection();

      if (selection && !selection.isCollapsed && editorRef.current?.contains(selection.anchorNode)) {
        console.log('Selected text:', selection.toString());
        console.log('URL to be added:', data.url);

        document.execCommand('createLink', false, data.url);

        console.log('Updated content after adding link:', editorRef.current?.innerHTML);

        handleContentChange();
      } else {
        console.error('No valid text selected for creating a link');
      }
    }

    if (type === 'legifranceReference') {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const span = document.createElement('span');
        span.className = 'legifrance-highlight';
        span.id = data.reference;
        span.textContent = selection.toString();
        range.deleteContents();
        range.insertNode(span);
        handleContentChange();
      }
    }

  };

  const handleSelectionChange = () => {
    if (mode !== 'reader' || forceReaderToolbarVisible) return;

    const selection = window.getSelection();
    if (selection && !selection.isCollapsed && editorRef.current) {
      const range = selection.getRangeAt(0);

      if (!editorRef.current.contains(range.commonAncestorContainer)) {
        return;
      }

      const rect = range.getBoundingClientRect();
      const editorRect = editorRef.current.getBoundingClientRect();
      const position = {
        top: rect.top - editorRect.top - 40,
        left: rect.left - editorRect.left,
      };
      setReaderToolbarVisible(true);
      setReaderToolbarPosition(position);
      console.log(`Selected text in block with ID: ${compositeBlockId}`);
    } else if (!forceReaderToolbarVisible) {
      setReaderToolbarVisible(false);
    }
  };

  const handleReaderToolbarVisibilityChange = (isVisible: boolean) => {
    setForceReaderToolbarVisible(isVisible);
    if (isVisible) {
      setReaderToolbarVisible(true);
    }
  };

  const checkPlaceholderVisibility = () => {
    if (editorRef.current) {
      setShowPlaceholder(editorRef.current.innerHTML.trim() === '');
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text/plain');
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return;

    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(text));
    handleContentChange();
  };

  const handleSuggestion = async (suggestionText: string) => {
    console.log('Creating suggestion for:');
    console.log(`Dossier ID: ${dossierId}`);
    console.log(`Composite Block ID: ${compositeBlockId}`);
    console.log(`Suggestion: ${suggestionText}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/suggestions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          dossierId: dossierId,
          blocId: compositeBlockId,
          content: suggestionText,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Erreur response:', errorData);
        throw new Error(`Erreur lors de la création de la suggestion: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      onSuggestionCreated(data);
    } catch (error) {
      console.error('Erreur:', error);
    }

    setReaderToolbarVisible(false);
  };

  const handleJurisprudenceClick = async (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.classList.contains('jurisprudence-highlight')) {
      const referenceId = target.dataset.referenceId || '';
      setIsLoadingJurisprudence(true);
      setShowModal(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudences/decision?id=${referenceId}&resolve_references=true`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données de la décision');
        }

        const decisionData = await response.json();

        setJurisprudenceData({
          reference: referenceId,
          jurisdiction: decisionData.jurisdiction || '',
          chamber: decisionData.chamber || '',
          number: decisionData.number || '',
          ecli: decisionData.ecli || '',
          formation: decisionData.formation || '',
          publication: decisionData.publication || [],
          decisionDate: decisionData.decision_date || '',
          solution: decisionData.solution || '',
          summary: decisionData.summary || '',
          themes: decisionData.themes || [],
          text: decisionData.text || '',
          visa: decisionData.visa || [],
          rapprochements: decisionData.rapprochements || [],
          contested: decisionData.contested ? {
            date: decisionData.contested.date,
            title: decisionData.contested.title,
            jurisdiction: decisionData.contested.jurisdiction,
            chamber: decisionData.contested.chamber,
            solution: decisionData.contested.solution,
            number: decisionData.contested.number
          } : undefined,
        });

      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setIsLoadingJurisprudence(false);
      }

      event.stopPropagation();
    }
  };

  const handleLegifranceClick = async (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.classList.contains('legifrance-highlight')) {
      const referenceId = target.id;
      event.stopPropagation();
    }
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!editorRef.current?.contains(event.target as Node) &&
      !document.querySelector('.jurisprudence-modal')?.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  const handleLinkClick = (event: MouseEvent) => {
    if (mode !== 'editor') return;

    const target = event.target as HTMLElement;
    if (target.tagName === 'A') {
      event.preventDefault();
      const rect = target.getBoundingClientRect();
      const editorRect = editorRef.current?.getBoundingClientRect();

      if (editorRect) {
        setLinkPopover({
          isVisible: true,
          url: target.getAttribute('href') || '',
          position: {
            top: rect.bottom - editorRect.top,
            left: rect.left - editorRect.left,
          },
        });
      }
    } else {
      setLinkPopover(prev => ({ ...prev, isVisible: false }));
    }
  };

  const handleRemoveLink = () => {
    if (editorRef.current) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer;
        let linkElement = startContainer.parentElement;

        // Recherche de l'élément <a> parent le plus proche
        while (linkElement && linkElement.tagName !== 'A') {
          linkElement = linkElement.parentElement;
        }

        if (linkElement && linkElement.tagName === 'A') {
          const textNode = document.createTextNode(linkElement.textContent || '');
          linkElement.parentNode?.replaceChild(textNode, linkElement);
          setLinkPopover(prev => ({ ...prev, isVisible: false }));
          handleContentChange();
        }
      }
    }
  };

  useEffect(() => {
    if (mode === 'reader' && editorRef.current) {
      editorRef.current.addEventListener('click', handleJurisprudenceClick);
      editorRef.current.addEventListener('click', handleLegifranceClick);
      document.addEventListener('click', handleDocumentClick);
      return () => {
        editorRef.current?.removeEventListener('click', handleJurisprudenceClick);
        editorRef.current?.removeEventListener('click', handleLegifranceClick);
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [mode]);

  useEffect(() => {
    if (mode === 'reader') {
      document.addEventListener('selectionchange', handleSelectionChange);
      return () => {
        document.removeEventListener('selectionchange', handleSelectionChange);
      };
    }
  }, [mode]);

  useEffect(() => {
    if (mode === 'editor') {
      const handleEditorSelectionChange = () => {
        const selection = window.getSelection();
        if (selection && !selection.isCollapsed && editorRef.current && !forceToolbarVisible) {
          const range = selection.getRangeAt(0);
          if (!editorRef.current.contains(range.commonAncestorContainer)) {
            return;
          }

          const rect = range.getBoundingClientRect();
          const editorRect = editorRef.current.getBoundingClientRect();
          const position = {
            top: rect.top - editorRect.top - 50,
            left: rect.left - editorRect.left,
          };
          setToolbarVisible(true);
          setToolbarPosition(position);
        } else if (!forceToolbarVisible) {
          setToolbarVisible(false);
        }
      };

      document.addEventListener('selectionchange', handleEditorSelectionChange);
      return () => {
        document.removeEventListener('selectionchange', handleEditorSelectionChange);
      };
    }
  }, [mode, forceToolbarVisible]);

  useEffect(() => {
    if (mode === 'editor' && editorRef.current) {
      editorRef.current.addEventListener('click', handleLinkClick);
      return () => {
        editorRef.current?.removeEventListener('click', handleLinkClick);
      };
    }
  }, [mode]);

  return (
    <div className="sub-block-editor relative">
      {mode === 'editor' && toolbarVisible && (
        <div
          className="absolute z-9 bg-white shadow-lg rounded-lg"
          style={{
            top: `${toolbarPosition.top}px`,
            left: `${toolbarPosition.left}px`,
          }}
        >
          <Toolbar
            onFormat={handleFormat}
            onAddEntity={handleAddEntity}
            mode={mode}
            setForceToolbarVisible={setForceToolbarVisible}
          />
        </div>
      )}

      {mode === 'reader' && (readerToolbarVisible || forceReaderToolbarVisible) && (
        <div
          className="absolute z-9"
          style={{
            top: `${readerToolbarPosition.top}px`,
            left: `${readerToolbarPosition.left}px`,
          }}
        >
          <ReaderToolbar
            onSuggestion={handleSuggestion}
            onVisibilityChange={handleReaderToolbarVisibilityChange}
          />
        </div>
      )}
      {showPlaceholder && mode === 'editor' && (
        <div
          className="absolute inset-0 flex items-center pl-0 pointer-events-none text-gray-400"
          style={{
            userSelect: 'none',
          }}
        >
          Veuillez renseigner votre texte...
        </div>
      )}
      {mode === 'reader' && showModal && (
        <JurisprudenceModal
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          isLoading={isLoadingJurisprudence}
          reference={jurisprudenceData?.reference || ''}
          jurisdiction={jurisprudenceData?.jurisdiction || ''}
          chamber={jurisprudenceData?.chamber || ''}
          number={jurisprudenceData?.number || ''}
          ecli={jurisprudenceData?.ecli || ''}
          formation={jurisprudenceData?.formation || ''}
          publication={jurisprudenceData?.publication || []}
          decisionDate={jurisprudenceData?.decisionDate || ''}
          solution={jurisprudenceData?.solution || ''}
          summary={jurisprudenceData?.summary || ''}
          themes={jurisprudenceData?.themes || []}
          text={jurisprudenceData?.text || ''}
          visa={jurisprudenceData?.visa || []}
          rapprochements={jurisprudenceData?.rapprochements || []}
          userRole={userRole}
        />
      )}

      {mode === 'editor' && linkPopover.isVisible && (
        <LinkPopover
          url={linkPopover.url}
          onRemove={handleRemoveLink}
          position={linkPopover.position}
        />
      )}

      <div
        ref={editorRef}
        contentEditable={mode === 'editor'}
        onInput={handleContentChange}
        onBlur={handleContentChange}
        onPaste={handlePaste}
        className={`pt-2 border border-transparent rounded relative text-justify ${mode === 'editor' ? 'editor-mode' : ''}`}
        style={{
          width: '100%',
          outline: 'none',
          minHeight: '40px',
          whiteSpace: 'pre-wrap',
          borderWidth: '1px',
          borderColor: 'rgba(0, 0, 0, 0)',
        }}
        data-block-id={compositeBlockId}
      />
    </div>
  );
};

export default SubBlockEditor;