import React, { useState, useEffect } from 'react';
import { ISuggestion } from './types';
import ChevronLeft from '../../icons/ChevronLeft';
import ChevronRight from '../../icons/ChevronRight';
import XCircle from '../../icons/XCircle';
import CheckCircle from '../../icons/CheckCircle';

interface SuggestionItemsProps {
  suggestions: ISuggestion[];
  onAccept: (suggestion: ISuggestion) => void;
  onReject: (suggestion: ISuggestion) => void;
}

const SuggestionItems: React.FC<SuggestionItemsProps> = ({ suggestions, onAccept, onReject }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex >= suggestions.length) {
      setCurrentIndex(Math.max(0, suggestions.length - 1));
    }
  }, [suggestions, currentIndex]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0));
  };

  if (suggestions.length === 0 || !suggestions[currentIndex]) {
    return null;
  }

  const currentSuggestion = suggestions[currentIndex];

  return (
    <div className="suggestion-items-container">
      {suggestions.length > 1 && (
        <div className="slider-controls">
          <button
            onClick={handlePrevious}
            className="absolute left-1 top-1/2 -translate-y-1/2 hover:bg-gray-200 focus:outline-none transition-colors duration-200"
            aria-label="Previous suggestion"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      )}
      <div className="suggestion-item p-4 bg-white rounded-lg shadow-sm px-8 py-4 border border-gray">
        <h4 className="text-s font-semibold mb-3">Suggestion {suggestions.length > 1 ? `${currentIndex + 1}/${suggestions.length}` : ''}:</h4>
        <p className="text-gray-700 text-sm mt-2">{currentSuggestion.content}</p>
        <div className="flex justify-center space-x-4 mt-4">
          <button
            className="p-1 bg-white border border-transparent rounded-full hover:shadow-lg hover:border-gray-400 transition duration-200 flex items-center justify-center"
            onClick={() => onAccept(currentSuggestion)}
          >
            <CheckCircle className="w-8 h-8 text-black" />
          </button>
          <button
            className="p-1 bg-white border border-transparent rounded-full hover:shadow-lg hover:border-gray-400 transition duration-200 flex items-center justify-center"
            onClick={() => onReject(currentSuggestion)}
          >
            <XCircle className="w-8 h-8 text-black" />
          </button>
        </div>
      </div>
      {suggestions.length > 1 && (
        <div className="slider-controls">
          <button
            onClick={handleNext}
            className="absolute right-1 top-1/2 -translate-y-1/2 hover:bg-gray-200 focus:outline-none transition-colors duration-200"
            aria-label="Next suggestion"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SuggestionItems;
