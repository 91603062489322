import React from 'react';
import { useViewport } from './hooks/useViewport';
import { useMobileSidebar } from '../contexts/MobileSidebarContext';
import ThemeSelector from '../../components/widgets/ThemeSelector';
import MacadamiaSwitcher from './MacadamiaSwitcher';
import AddFavorite from './AddFavorite';
import LastSavedDate from './LastSavedDate';
import FloppyDisk from '../../macadamia-icons/floppy-disk';
import Cog6Tooth from '../../macadamia-icons/cog-6-tooth';
import ShareButton from './ShareButton';
import Bars3 from '../icons/Bars3';

interface TopBarDossierProps {
  toggleSettingsModal: (tab?: string) => void;
  isSettingsModalOpen: boolean;
  title: string;
  onTitleChange: (newTitle: string) => void;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
  handleSave: () => void;
  lastSaved: Date;
  dossierId: string;
  userRole: string | null;
}

const TopBarDossier: React.FC<TopBarDossierProps> = ({
  toggleSettingsModal,
  title,
  hasUnsavedChanges,
  isSaving,
  handleSave,
  lastSaved,
  dossierId,
  userRole,
}) => {
  const { isMobile } = useViewport();
  const { toggleSidebar } = useMobileSidebar();
  const canEdit = userRole === 'author' || userRole === 'co-author' || userRole === 'admin';

  if (isMobile) {
    return (
      <div className="fixed top-0 h-12 bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-md z-9 w-full flex items-center px-4">
        <div className="flex items-center w-1/2">
          <button
            onClick={toggleSidebar}
            className="mr-3 text-mcdm-third-400 dark:text-mcdm-secondary-400 flex-shrink-0"
          >
            <Bars3
              width="24px"
              height="24px"
            />
          </button>
          <div className="overflow-x-auto whitespace-nowrap scrollbar-hide">
            <span className="text-sm font-normal text-mcdm-third-500 dark:text-mcdm-primary-100">
              {title}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-end w-1/2 space-x-2">
          <ThemeSelector />
          <div className="transform -translate-y-px">
            <AddFavorite dossierId={dossierId} />
          </div>
          {canEdit && (
            <div
              className="p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer text-mcdm-third-500 dark:text-mcdm-secondary-400 dark:hover:text-mcdm-primary-100"
              onClick={() => toggleSettingsModal('Général')}
            >
              <Cog6Tooth width="24px" height="24px" />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="stickyheadbar top-0 flex-shrink-0 h-16 bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-md dark:shadow-inner z-30 w-full flex justify-between items-center px-4">
      <div className="flex justify-start" style={{ width: '40%' }}>
        <span className="text-sm font-normal text-mcdm-third-500 dark:text-mcdm-primary-100 p-2 w-full">{title}</span>
      </div>

      <div className="flex justify-end items-center" style={{ width: '60%' }}>
        <LastSavedDate lastSaved={lastSaved} />
        <div className='mr-1'>
          <ThemeSelector />
        </div>
        <div className="mr-2 mt-1" style={{ transform: 'translateY(-1px)' }}>
          <AddFavorite dossierId={dossierId} />
        </div>
        {canEdit && (
          <>
            <div className="p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 mr-2 cursor-pointer hover:text-mcdm-third-500 dark:text-mcdm-secondary-400 dark:hover:text-mcdm-primary-100" onClick={() => toggleSettingsModal('Général')}>
              <Cog6Tooth width="24px" height="24px" />
            </div>
            <div className={`mr-2 cursor-pointer p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 ${hasUnsavedChanges ? 'text-mcdm-secondary-400 dark:text-mcdm-secondary-400' : 'text-mcdm-third-500 dark:text-mcdm-primary-100'} ${isSaving ? 'animate-spin' : ''}`}
              onClick={handleSave}
            >
              <FloppyDisk width="24px" height="24px" />
            </div>
          </>
        )}
        {canEdit && <ShareButton className="ml-2" onShareClick={() => toggleSettingsModal('Visibilité')} />}
        {canEdit && <MacadamiaSwitcher className="ml-2" />}
      </div>
    </div>
  );
};

export default TopBarDossier;