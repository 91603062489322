import React, { useState, useCallback } from 'react';
import { useAuth } from './../../../../context/AuthContext';
import LinkIcon from '../../../icons/Link';
import XCircle from '../../../icons/XCircle';
import FollowButton from './FollowButton';
import JurisprudenceSimilairesTab from './../../../../components/pages/jurisprudences/JurisprudenceSimilairesTab';

interface decisionLink {
  title: string;
  number: string;
  description: string;
  theme: string[];
  url: string;
}

interface JurisprudenceModalProps {
  isVisible: boolean;
  onClose: () => void;
  isLoading: boolean;
  reference: string;
  jurisdiction: string;
  chamber: string;
  number: string;
  ecli: string;
  formation: string;
  publication: string[];
  decisionDate: string;
  solution: string;
  summary: string;
  themes: string[];
  text: string;
  visa?: { title: string }[];
  contested?: {
    date: string;
    title: string;
    jurisdiction: string;
    chamber: string;
    solution: string;
    number: string;
  };
  rapprochements?: decisionLink[];
  userRole: string | null;
}

const generateJurisprudenceTitle = (jurisdiction: string, location: string, number: string, decisionDate: string) => {
  if (jurisdiction === "Cour d'appel") {
    const locationPart = location ? `de ${location.replace(/^Cour d'appel de /, '')}` : '';
    return `${decisionDate || 'Date inconnue'} - Cour d'appel ${locationPart} ${number ? `- RG n° ${number}` : ''}`.trim();
  }
  return `${decisionDate || 'Date inconnue'} - ${jurisdiction || 'Juridiction inconnue'} ${number ? `n° ${number}` : ''}`.trim();
};

const extractLinkFromTitle = (title: string): string | null => {
  const linkRegex = /href="([^"]*)"/;
  const match = linkRegex.exec(title);
  return match ? match[1] : null;
};

// Composant TabButton inspiré de JurisprudenceItem
const TabButton: React.FC<{
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}> = ({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium transition-colors duration-200 border-b-2 ${active
      ? 'border-mcdm-secondary-500 text-mcdm-secondary-500 dark:border-mcdm-secondary-400 dark:text-mcdm-secondary-400'
      : 'border-transparent text-mcdm-third-400 hover:text-mcdm-third-500 dark:text-mcdm-primary-200 dark:hover:text-mcdm-primary-100'
      }`}
  >
    {children}
  </button>
);

const JurisprudenceModal: React.FC<JurisprudenceModalProps> = ({
  isVisible,
  onClose,
  isLoading,
  reference,
  jurisdiction,
  chamber,
  number,
  ecli,
  formation,
  publication = [],
  decisionDate,
  solution,
  summary,
  themes = [],
  text,
  visa = [],
  contested,
  rapprochements = [],
  userRole
}) => {
  const { token } = useAuth();
  const [aiSummary, setAiSummary] = useState<string>('');
  const [isGeneratingSummary, setIsGeneratingSummary] = useState<boolean>(false);
  const [showSummaryBlock, setShowSummaryBlock] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('texte');

  const title = generateJurisprudenceTitle(jurisdiction, '', number, decisionDate);

  const handleGenerateSummary = useCallback(async () => {
    if (!text || isGeneratingSummary) return;

    setIsGeneratingSummary(true);
    setShowSummaryBlock(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/ai/generate-summary`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();
      setAiSummary(data.summary);
    } catch (error) {
      console.error('Erreur lors de la génération du résumé:', error);
      setAiSummary('Erreur lors de la génération du résumé. Veuillez réessayer.');
    } finally {
      setIsGeneratingSummary(false);
    }
  }, [text, token, isGeneratingSummary]);

  // Fonction pour afficher le contenu en fonction de l'onglet actif
  const renderTabContent = () => {
    switch (activeTab) {
      case 'texte':
        return (
          <div className="mt-4">
            <div className="flex justify-between items-center mb-2">
              <p><strong>Texte intégral:</strong></p>
              <button
                onClick={handleGenerateSummary}
                disabled={isGeneratingSummary || !text}
                className={`px-3 py-1 rounded ${isGeneratingSummary || !text
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-mcdm-secondary-400 text-white hover:bg-mcdm-secondary-500'
                  }`}
              >
                {isGeneratingSummary ? 'Génération...' : 'Résumé IA'}
              </button>
            </div>
            {showSummaryBlock && (
              <div className="mt-4 p-3 bg-mcdm-secondary-200 rounded">
                <h4 className="font-bold mb-2 text-mcdm-third-400">Résumé IA :</h4>
                {isGeneratingSummary ? (
                  <div className="flex flex-col items-center">
                    <img
                      src="/gifs/Wizard.gif"
                      alt="Chargement"
                      style={{ height: '80px', width: '80px' }}
                    />
                    <p className="text-mcdm-third-500 mt-2">Patientez...</p>
                  </div>
                ) : (
                  <p className='text-mcdm-third-500'>{aiSummary}</p>
                )}
              </div>
            )}
            <div className="text-sm mt-4 whitespace-pre-wrap break-words">
              {text ? text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              )) : 'Aucun texte disponible.'}
            </div>
          </div>
        );
      case 'rapprochements':
        return (
          <div className="-mx-6">
            <JurisprudenceSimilairesTab
              jurisprudenceId={reference}
              themes={themes}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-20 z-30 transition-opacity duration-300"
      onClick={onClose}
    >
      <div
        className="fixed inset-y-0 right-0 w-[320px] bg-mcdm-primary-100 dark:bg-mcdm-third-500 shadow-lg z-40 transform transition-transform duration-300 overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-6 text-sm">
          {isLoading ? (
            <div className="animate-pulse space-y-4">
              <div className="h-10 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-full mb-4"></div>
              <h2 className="text-xl font-bold mb-2">Détails de la jurisprudence</h2>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-2/3"></div>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-1/2"></div>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-3/4"></div>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-2/3"></div>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-1/2"></div>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-3/4"></div>
              <div className="h-4 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-2/5"></div>
              <div className="h-40 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 rounded w-full"></div>
            </div>
          ) : (
            <>
              <FollowButton reference={reference} />

              <h2 className="text-xl font-platypi text-mcdm-marron-500 mb-2">{title}</h2>

              {userRole === 'admin' && (
                <>
                  <p><strong>Référence :</strong> {reference}</p>
                  <p><strong>ECLI :</strong> {ecli}</p>
                </>
              )}
              {contested && (
                <>
                  <hr className="my-4" />
                  <h3 className="text-lg font-bold mb-2">Décision Contestée</h3>
                  <p><strong>Numéro :</strong> {contested.number}</p>
                  <p><strong>Date :</strong> {new Date(contested.date).toLocaleDateString()}</p>
                  <p><strong>Juridiction :</strong> {contested.jurisdiction}</p>
                  <p><strong>Chambre :</strong> {contested.chamber}</p>
                  <p><strong>Solution :</strong> {contested.solution}</p>
                  <p><strong>Intitulé :</strong> {contested.title}</p>
                </>
              )}

              {visa.length > 0 && (
                <>
                  <hr className="my-4" />
                  <h3 className="font-bold mb-2">Textes appliqués</h3>
                  <div className="space-y-4 mb-3">
                    {visa.map((item, index) => {
                      const link = extractLinkFromTitle(item.title);
                      return link && link !== "#" ? (
                        <a
                          key={index}
                          href={link}
                          className="block bg-primary-100 border border-gray-200 rounded-lg p-4 flex justify-between items-center transition hover:border-gray-400 hover:shadow-md dark:text-mcdm-third-500"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="flex-grow text-mcdm-third-500 dark:text-mcdm-primary-100" dangerouslySetInnerHTML={{ __html: item.title }}></span>
                          <div className="flex-shrink-0 ml-4">
                            <LinkIcon
                              width="24px"
                              height="24px"
                              className="text-mcdm-third-500 dark:text-mcdm-primary-100"
                            />
                          </div>
                        </a>
                      ) : (
                        <div
                          key={index}
                          className="block border border-gray-200 bg-mcdm-primary-100 dark:bg-mcdm-third-500 rounded-lg p-4"
                        >
                          <span className="flex-grow text-mcdm-third-500 dark:text-mcdm-primary-100" dangerouslySetInnerHTML={{ __html: item.title }}></span>
                          <div className="flex items-center mt-2">
                            <XCircle width="24px" height="24px" className="text-red-500" />
                            <p className="text-red-500 ml-2">Lien non disponible</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <p><strong>Juridiction :</strong> {jurisdiction}</p>
              <p><strong>Chambre :</strong> {chamber}</p>
              <p><strong>Numéro :</strong> {number}</p>
              <p><strong>Formation :</strong> {formation}</p>
              <p>
                <strong>Publication :</strong>{' '}
                {publication.length > 0 ? publication.join(', ') : 'Non publiée'}
              </p>
              <p><strong>Date :</strong> {new Date(decisionDate).toLocaleDateString()}</p>
              <p><strong>Solution :</strong> {solution}</p>
              <p><strong>Résumé :</strong> {summary}</p>

              <div className="flex flex-wrap items-center">
                <strong className="mr-2">Thèmes :</strong>
                {themes.length > 0 ? themes.map((theme, index) => (
                  <span
                    key={index}
                    className="bg-mcdm-primary-200 text-mcdm-third-500 px-4 py-1 rounded-full mr-2 mb-1 mt-1 text-sm"
                  >
                    {theme}
                  </span>
                )) : 'Aucun thème'}
              </div>

              <hr className="my-4" />

              {/* Onglets pour le texte intégral et les rapprochements */}
              <div className="flex border-b border-mcdm-third-200 dark:border-mcdm-third-600">
                <TabButton active={activeTab === 'texte'} onClick={() => setActiveTab('texte')}>
                  Texte intégral
                </TabButton>
                <TabButton active={activeTab === 'rapprochements'} onClick={() => setActiveTab('rapprochements')}>
                  Rapprochements
                </TabButton>
              </div>

              {renderTabContent()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JurisprudenceModal;