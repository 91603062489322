import React, { createContext, useContext, useState } from 'react';

interface MobileSidebarContextType {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
}

const MobileSidebarContext = createContext<MobileSidebarContextType>({
    isSidebarOpen: false,
    toggleSidebar: () => { },
    closeSidebar: () => { }
});

export const MobileSidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(prev => !prev);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    return (
        <MobileSidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, closeSidebar }}>
            {children}
        </MobileSidebarContext.Provider>
    );
};

export const useMobileSidebar = () => useContext(MobileSidebarContext);