import React, { CSSProperties } from 'react';

interface Square2StackProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
}

const Square2Stack: React.FC<Square2StackProps> = ({ fill = 'currentColor', secondaryfill, width = '19px', height = '19px', style }) => {
  const fillValue = fill || 'currentColor';
  const secondaryFillValue = secondaryfill || fillValue;

  return (
    <svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g fill={fillValue}>
        <defs/>
        <rect className="cls-1" height="480" width="480" fill="none" rx="38.5" ry="38.5" stroke={fillValue} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px" x="370" y="369.36"/>
        <path className="cls-1" d="M370 418.63c0-27.21 22.06-49.27 49.27-49.27H690V259.91c0-27.21-22.06-49.27-49.27-49.27H259.28c-27.21 0-49.27 22.06-49.27 49.27v381.45c0 27.21 22.06 49.27 49.27 49.27h110.73V418.62z" fill="none" stroke={fillValue} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
      </g>
    </svg>
  );
};

export default Square2Stack;
