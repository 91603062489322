import React, { useRef, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Bell from './../icons/Bell';
import NotificationList from './NotificationList';
import useNotifications from './hooks/useNotifications';

const NotificationBell: React.FC = () => {
  const {
    notifications,
    isLoading,
    hasMore,
    loadMore,
    markAsRead,
    totalUnreadCount,
    showNotifications,
    setShowNotifications,
    fetchNotifications,
  } = useNotifications();

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Gestion du clic en dehors pour fermer le dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowNotifications(false);
      }
    };

    if (showNotifications) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications, setShowNotifications]);

  const handleToggleNotifications = () => {
    setShowNotifications(prev => !prev);
    if (!showNotifications) {
      fetchNotifications(1);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleToggleNotifications}
        className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-mcdm-third-300 
             text-mcdm-third-500 dark:text-mcdm-secondary-400
             transition-colors duration-200"
      >
        <Bell stroke="currentColor" fill="none" width="20px" height="20px" />
        {totalUnreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
            {totalUnreadCount}
          </span>
        )}
      </button>

      <AnimatePresence>
        {showNotifications && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className="absolute right-0 mt-2 w-[225px] bg-transparent rounded-lg shadow-lg z-20"
          >
            <NotificationList
              notifications={notifications}
              isLoading={isLoading}
              hasMore={hasMore}
              loadMore={loadMore}
              markAsRead={markAsRead}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NotificationBell;
