// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Personnalisation de la scrollbar */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Mode clair - Par défaut */
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #e7e7e7; /* bg-mcdm-primary-100 */
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #151515; /* bg-mcdm-secondary-500 */
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #000000;
  }
  
  /* Mode sombre */
  .dark .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #1F2937;
  }
  
  .dark .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #F8F9FA; /* bg-mcdm-primary-100 */
    border-radius: 4px;
  }
  
  .dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #F3F4F6;
  }`, "",{"version":3,"sources":["webpack://./src/macadamia-core/components/styles/DossierSidebar.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,UAAU;EACZ;;EAEA,4BAA4B;EAC5B;IACE,yBAAyB,EAAE,wBAAwB;IACnD,kBAAkB;EACpB;;EAEA;IACE,yBAAyB,EAAE,0BAA0B;IACrD,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,gBAAgB;EAChB;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB,EAAE,wBAAwB;IACnD,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Personnalisation de la scrollbar */\n.custom-scrollbar::-webkit-scrollbar {\n    width: 8px;\n  }\n  \n  /* Mode clair - Par défaut */\n  .custom-scrollbar::-webkit-scrollbar-track {\n    background-color: #e7e7e7; /* bg-mcdm-primary-100 */\n    border-radius: 4px;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-thumb {\n    background-color: #151515; /* bg-mcdm-secondary-500 */\n    border-radius: 4px;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-thumb:hover {\n    background-color: #000000;\n  }\n  \n  /* Mode sombre */\n  .dark .custom-scrollbar::-webkit-scrollbar-track {\n    background-color: #1F2937;\n  }\n  \n  .dark .custom-scrollbar::-webkit-scrollbar-thumb {\n    background-color: #F8F9FA; /* bg-mcdm-primary-100 */\n    border-radius: 4px;\n  }\n  \n  .dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {\n    background-color: #F3F4F6;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
