import React from 'react';

function Cog6Tooth(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<defs/>
		<path className="cls-1" id="1708803034909-5701930_cog-6-tooth-2" d="M847.04 611.75l-58.49-33.73c5.9-31.81 5.9-64.43 0-96.24l58.49-33.73a16.58 16.58 0 0 0 7.55-19.19 341.518 341.518 0 0 0-74.99-129.7c-5.17-5.69-13.6-7.05-20.3-3.28l-58.49 33.73a258.777 258.777 0 0 0-83.26-48.04v-67.31c0-7.71-5.36-14.39-12.89-16.05a344.59 344.59 0 0 0-149.7 0 16.426 16.426 0 0 0-12.89 16.05v67.44a266.946 266.946 0 0 0-83.35 48.12l-58.26-33.71a16.28 16.28 0 0 0-20.22 3.14 339.475 339.475 0 0 0-75.07 129.7c-2.32 7.37.82 15.37 7.55 19.19l58.49 33.73c-5.9 31.81-5.9 64.43 0 96.24l-58.49 33.73a16.58 16.58 0 0 0-7.55 19.19 341.418 341.418 0 0 0 75.07 129.7 16.505 16.505 0 0 0 20.22 3.1l58.49-33.73a258.829 258.829 0 0 0 83.35 48.12v67.5c0 7.71 5.36 14.39 12.89 16.05a344.59 344.59 0 0 0 149.7 0 16.426 16.426 0 0 0 12.89-16.05v-67.44a266.946 266.946 0 0 0 83.35-48.12l58.49 33.73a16.278 16.278 0 0 0 20.3-3.16 339.316 339.316 0 0 0 74.99-129.7c2.16-7.44-1-15.4-7.68-19.32l-.19.04zm-317.23 27.78c-60.57 0-109.67-49.1-109.67-109.67s49.1-109.67 109.67-109.67 109.67 49.1 109.67 109.67c-.08 60.54-49.13 109.6-109.67 109.67z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
	</g>
</svg>
	);
};

export default Cog6Tooth;