import React, { CSSProperties } from 'react';

interface SparklesProps {
    stroke?: string;
    fill?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
}

const Sparkles: React.FC<SparklesProps> = ({
    stroke = 'currentColor',
    fill = 'none',
    width = '100%',
    height = '100%',
    style
}) => {
    return (
        <svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g fill={fill}>
                <path
                    d="M417.23 777.84s1.18-73.27-73.27-147.72c-74.45-74.45-148.31-73.86-148.31-73.86 0 0 72.68.59 147.13-73.86s74.45-147.72 74.45-147.72c0 0 .59 75.04 73.86 148.31s147.72 73.27 147.72 73.27c0 0-74.45 0-148.31 73.86s-73.27 147.72-73.27 147.72Z"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
                <path
                    d="M727.24 504.12s.73-45.34-45.34-91.41-91.77-45.7-91.77-45.7c0 0 44.97.37 91.04-45.7 46.07-46.07 46.07-91.41 46.07-91.41 0 0 .37 46.44 45.7 91.77 45.34 45.34 91.41 45.34 91.41 45.34 0 0-46.07 0-91.77 45.7-45.7 45.7-45.34 91.41-45.34 91.41Z"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
                <path
                    d="M621.37 830.11s.47-28.88-28.88-58.22c-29.34-29.34-58.45-29.11-58.45-29.11 0 0 28.64.23 57.98-29.11s29.34-58.22 29.34-58.22c0 0 .23 29.57 29.11 58.45 28.88 28.88 58.22 28.88 58.22 28.88 0 0-29.34 0-58.45 29.11-29.11 29.11-28.88 58.22-28.88 58.22Z"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
            </g>
        </svg>
    );
};

export default Sparkles;
