import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface IDossier {
  _id: string;
  title: string;
  description: string;
}

interface IFavorite {
  _id: string;
  dossier: IDossier;
  favoritedAt: Date;
}

interface FolderGridProps {
  dossiersUpdated: boolean;
  setDossiersUpdated: (updated: boolean) => void;
  selectedFilter: string;
}

const FolderGrid: React.FC<FolderGridProps> = ({ dossiersUpdated, setDossiersUpdated, selectedFilter }) => {
  const [dossiers, setDossiers] = useState<IDossier[]>([]);
  const [favoriteDossiers, setFavoriteDossiers] = useState<IDossier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const fetchDossiers = useCallback(async () => {
    if (!token) {
      logout();
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          logout();
          navigate('/login');
          return;
        }
        throw new Error('Erreur de réseau ou le serveur a retourné une erreur');
      }

      const data: IDossier[] = await response.json();
      setDossiers(data);
      setDossiersUpdated(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des dossiers:', error);
    }
  }, [token, logout, navigate, setDossiersUpdated]);

  const fetchFavoriteDossiers = useCallback(async () => {
    if (!token) {
      logout();
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/favorites/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          logout();
          navigate('/login');
          return;
        }
        throw new Error('Erreur de réseau ou le serveur a retourné une erreur');
      }

      const data: IFavorite[] = await response.json();
      const favoriteDossiersData = data.map(fav => fav.dossier);
      setFavoriteDossiers(favoriteDossiersData);
    } catch (error) {
      console.error('Erreur lors de la récupération des favoris:', error);
    }
  }, [token, logout, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchDossiers(), fetchFavoriteDossiers()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchDossiers, fetchFavoriteDossiers, dossiersUpdated]);

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length <= maxLength) return description;
    return description.substring(0, maxLength) + "(...)";
  };

  const combinedDossiers: IDossier[] = [...dossiers];
  favoriteDossiers.forEach(fav => {
    if (!combinedDossiers.some(dossier => dossier._id === fav._id)) {
      combinedDossiers.push(fav);
    }
  });

  let filteredDossiers: IDossier[] = combinedDossiers;
  if (selectedFilter === 'Favoris') {
    filteredDossiers = favoriteDossiers;
  } else if (selectedFilter === 'Invitations') {
    filteredDossiers = [];
  }

  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
        {Array.from({ length: 5 }).map((_, i) => (
          <div
            key={i}
            className="bg-mcdm-primary-200 dark:bg-mcdm-third-300 p-6 rounded-lg shadow-md h-32 
                     animate-pulse transition-all duration-300"
          />
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
      {filteredDossiers.length > 0 ? (
        filteredDossiers.map((dossier) => (
          <div
            key={dossier._id}
            className="bg-white dark:bg-mcdm-third-400 p-6 rounded-lg shadow-sm shadow-inner
                     cursor-pointer transition-all duration-300
                     hover:shadow-md hover:scale-[1.02]
                     border border-transparent
                     hover:border-mcdm-secondary-300 dark:hover:border-mcdm-secondary-400"
            onClick={() => navigate(`/mes-dossiers/${dossier._id}`)}
          >
            <h2 className="font-bold mb-2 text-mcdm-third-500 dark:text-mcdm-primary-100 
                         font-platypi transition-colors duration-300">
              {dossier.title}
            </h2>
            <p className="text-mcdm-third-400 dark:text-mcdm-primary-200 
                         transition-colors duration-300">
              {truncateDescription(dossier.description, 100)}
            </p>
          </div>
        ))
      ) : (
        <div className="col-span-full text-center text-mcdm-third-500 dark:text-mcdm-primary-200 
                      transition-colors duration-300">
          Aucun dossier trouvé.
        </div>
      )}
    </div>
  );
};

export default FolderGrid;