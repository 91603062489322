import React, { useState } from 'react';
import { IBlock, ITitleBlock, ICompositeTextWidget, IImageBlock, IGifBlock, ISuggestion, IListBlock, IVideoBlock } from './types';
import TitleWidget from './TitleWidget';
import CompositeTextWidget from './composite-text-widget/CompositeTextWidget';
import ListWidget from './ListWidget';
import ImageWidget from './ImageWidget';
import GifWidget from './GifWidget';
import VideoWidget from './video/VideoWidget';
import SuggestionItems from './SuggestionItems';
import { useMacadamia } from '../../contexts/MacadamiaContext';
import { useAuth } from '../../../context/AuthContext';
import { useSparkleAiAnalysis } from '../hooks/useSparkleAiAnalysis';
import Trash from '../../icons/trash';
import Clone from '../../icons/square-2-stack';
import Arrows from '../../icons/Bars3';
import Sparkles from '../../icons/Sparkles';

interface WidgetRendererProps {
  block: IBlock | ICompositeTextWidget | IImageBlock | IVideoBlock;
  onChange: (newBlock: IBlock | ICompositeTextWidget | IImageBlock | IVideoBlock) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  suggestions: ISuggestion[];
  onAcceptSuggestion: (suggestion: ISuggestion) => void;
  onRejectSuggestion: (suggestion: ISuggestion) => void;
  dossierId: string;
  onSuggestionCreated: (newSuggestion: ISuggestion) => void;
  userRole: string | null;
}

const WidgetRenderer: React.FC<WidgetRendererProps> = ({
  block,
  onChange,
  onDuplicate,
  onDelete,
  suggestions,
  onAcceptSuggestion,
  onRejectSuggestion,
  dossierId,
  onSuggestionCreated,
  userRole
}) => {
  const { currentState } = useMacadamia();
  const { token } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  
  // Intégration uniquement du nouveau hook
  const {
    analyzeText,
    applyAnalysisToCompositeBlock,
    isAnalyzing
  } = useSparkleAiAnalysis(token || '', dossierId);

  const handleSparkleClick = async () => {
    if (block.type !== 'compositeText') return;
    
    const compositeBlock = block as ICompositeTextWidget;
    
    // Extraire le texte de tous les blocs de texte
    const allText = compositeBlock.content.blocks
      .map(textBlock => textBlock.rawText)
      .join('\n');
    
    // Lancer l'analyse du texte avec le hook
    const results = await analyzeText(allText);
    
    if (results) {
      // Appliquer les modifications au bloc
      const updatedBlock = applyAnalysisToCompositeBlock(compositeBlock, results);
      
      // Mettre à jour le bloc avec les références enrichies
      onChange(updatedBlock);
      
      console.log('Bloc enrichi avec succès:', updatedBlock);
    }
  };

  const renderBlockContent = () => {
    switch (block.type) {
      case 'image':
        return (
          <ImageWidget
            block={block as IImageBlock}
            onChange={onChange}
            currentState={currentState}
          />
        );
      case 'video':
        return (
          <VideoWidget
            block={block as IVideoBlock}
            onChange={onChange}
            dossierId={dossierId}
          />
        );
      case 'title':
        const titleBlock = block as ITitleBlock;
        return (
          <TitleWidget
            title={titleBlock.content}
            onTitleChange={(newTitle) => onChange({ ...titleBlock, content: newTitle } as ITitleBlock)}
          />
        );
      case 'compositeText':
        const compositeTextBlock = block as ICompositeTextWidget;
        return (
          <CompositeTextWidget
            widget={compositeTextBlock}
            onWidgetChange={onChange}
            mode={currentState}
            dossierId={dossierId}
            onSuggestionCreated={onSuggestionCreated}
            mongoId={block._id}
            userRole={userRole}
          />
        );
      case 'list':
        const listBlock = block as IListBlock;
        return (
          <ListWidget
            block={listBlock}
            onChange={(newBlock) => onChange(newBlock)}
          />
        );
      case 'gif':
        const gifBlock = block as IGifBlock;
        return <GifWidget block={gifBlock} onChange={onChange} />;
      default:
        return <span>Unknown block type: {block.type}</span>;
    }
  };

  const canViewSuggestions = userRole === 'author' || userRole === 'admin';

  // Utiliser uniquement isAnalyzing du nouveau hook
  const isCurrentlyAnalyzing = isAnalyzing;

  return (
    <div
      className="block block-hover"
      style={{ pointerEvents: 'auto' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {currentState === 'editor' && (
        <div className="block-controls">
          <div className="icon-container">
            <div className="icon move-icon">
              <Arrows />
            </div>
            <div onClick={onDuplicate} className="icon">
              <Clone />
            </div>
            <div onClick={onDelete} className="icon">
              <Trash />
            </div>
            {isHovered && block.type === 'compositeText' && (
              <div 
                onClick={isCurrentlyAnalyzing ? undefined : handleSparkleClick} 
                className={`icon ${isCurrentlyAnalyzing ? 'opacity-50 cursor-wait' : 'cursor-pointer'} text-mcdm-secondary-400 hover:text-mcdm-secondary-500 dark:hover:text-mcdm-third-300`}
                title="Analyser et enrichir le texte"
              >
                {isCurrentlyAnalyzing ? (
                  <div className="sparkle-loading">
                    <div className="animate-spin h-5 w-5 border-t-2 border-mcdm-secondary-500 dark:border-mcdm-primary-100 rounded-full"></div>
                  </div>
                ) : (
                  <Sparkles width="19px" height="19px" />
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {renderBlockContent()}
      {canViewSuggestions && suggestions.length > 0 && (
        <div className="suggestions-container mt-2">
          <SuggestionItems
            suggestions={suggestions}
            onAccept={onAcceptSuggestion}
            onReject={onRejectSuggestion}
          />
        </div>
      )}
    </div>
  );
};

export default WidgetRenderer;