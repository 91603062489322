import { DropResult } from 'react-beautiful-dnd';
import { IDossier, IBlock, IChapter } from '../../components/widgets/types';
import { v4 as uuidv4 } from 'uuid';

interface UseDragAndDropManagementProps {
  dossierContent: IDossier | null;
  setDossierContent: React.Dispatch<React.SetStateAction<IDossier | null>>;
  currentChapterIndex: number;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const useDragAndDropManagement = ({
  dossierContent,
  setDossierContent,
  currentChapterIndex,
  setHasUnsavedChanges
}: UseDragAndDropManagementProps) => {
  const handleBlockDragEnd = (result: DropResult) => {
    if (!result.destination || !dossierContent) {
      return;
    }

    const sourceSectionIndex = parseInt(result.source.droppableId, 10);
    const destinationSectionIndex = parseInt(result.destination.droppableId, 10);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const currentChapter = dossierContent.content[currentChapterIndex];

    if (!currentChapter ||
      !currentChapter.sections[sourceSectionIndex] ||
      !currentChapter.sections[destinationSectionIndex]) {
      console.error("Invalid section index", { sourceSectionIndex, destinationSectionIndex });
      return;
    }

    const sourceSection = currentChapter.sections[sourceSectionIndex];
    const destinationSection = currentChapter.sections[destinationSectionIndex];

    if (!sourceSection.blocks[sourceIndex] ||
      (destinationIndex !== sourceIndex && !destinationSection.blocks[destinationIndex])) {
      console.error("Invalid block index", { sourceIndex, destinationIndex });
      return;
    }

    if (sourceSectionIndex === destinationSectionIndex) {
      const updatedBlocks = Array.from(sourceSection.blocks);
      const [movedBlock] = updatedBlocks.splice(sourceIndex, 1);
      updatedBlocks.splice(destinationIndex, 0, movedBlock);

      const updatedSections = [...currentChapter.sections];
      updatedSections[sourceSectionIndex] = {
        ...updatedSections[sourceSectionIndex],
        blocks: updatedBlocks
      };

      const updatedContent = [...dossierContent.content];
      updatedContent[currentChapterIndex] = {
        ...currentChapter,
        sections: updatedSections
      };
      setDossierContent({ ...dossierContent, content: updatedContent });
    } else {
      const sourceBlocks = Array.from(sourceSection.blocks);
      const destinationBlocks = Array.from(destinationSection.blocks);
      const [movedBlock] = sourceBlocks.splice(sourceIndex, 1);
      destinationBlocks.splice(destinationIndex, 0, { ...movedBlock, _id: uuidv4() });

      const updatedSections = [...currentChapter.sections];
      updatedSections[sourceSectionIndex] = {
        ...updatedSections[sourceSectionIndex],
        blocks: sourceBlocks
      };
      updatedSections[destinationSectionIndex] = {
        ...updatedSections[destinationSectionIndex],
        blocks: destinationBlocks
      };

      const updatedContent = [...dossierContent.content];
      updatedContent[currentChapterIndex] = {
        ...currentChapter,
        sections: updatedSections
      };
      setDossierContent({ ...dossierContent, content: updatedContent });
    }

    setHasUnsavedChanges(true);
  };

  return { handleBlockDragEnd };
};

export default useDragAndDropManagement;
