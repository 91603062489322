import React from 'react';

interface VimeoIconProps {
    className?: string;
}

const VimeoIcon: React.FC<VimeoIconProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="vimeo"
    >
        <title id="vimeo">Vimeo Icon</title>
        <path
            d="M166.9,68.7c-0.6,13.1-9.7,31.1-27.3,53.9c-18.2,23.8-33.6,35.7-46.3,35.7c-7.8,0-14.4-7.2-19.8-21.7L62.7,96.6
            c-4-14.5-8.3-21.7-12.9-21.7c-1,0-4.5,2.1-10.5,6.3L33,73.1c6.6-5.8,13.1-11.6,19.6-17.5c8.8-7.7,15.4-11.7,19.9-12.1
            c10.4-1,16.8,6.2,19.3,21.5c2.6,16.5,4.4,26.8,5.4,30.9c3,13.7,6.3,20.6,9.9,20.6c2.8,0,7-4.5,12.6-13.4c5.6-8.9,8.6-15.7,9-20.3
            c0.8-7.7-2.2-11.5-9-11.5c-3.2,0-6.5,0.7-9.9,2.2c6.6-21.7,19.2-32.2,37.7-31.6C161.2,42.2,167.7,51.2,166.9,68.7"
            fill="currentColor"
        />
    </svg>
);

export default VimeoIcon;
