import React, { CSSProperties } from 'react';

interface Bars3Props {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Bars3: React.FC<Bars3Props> = ({ 
  fill = 'currentColor', 
  secondaryfill, 
  width = '19px', 
  height = '19px', 
  style,
  className 
}) => {
  return (
    <svg 
      height={height} 
      width={width} 
      viewBox="0 0 1060 1060" 
      xmlns="http://www.w3.org/2000/svg" 
      style={style}
      className={className}
    >
      <g fill={fill}>
        <defs/>
        <path className="cls-1" d="M263 351.92h534" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
        <path className="cls-1" d="M263 530.24h534" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
        <path className="cls-1" d="M263 708.08h534" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
      </g>
    </svg>
  );
};

export default Bars3;