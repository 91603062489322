import React from 'react';

interface InvitationPromptProps {
  dossierTitle: string;
  onAccept: () => void;
  onReject: () => void;
}

const InvitationPrompt: React.FC<InvitationPromptProps> = ({ dossierTitle, onAccept, onReject }) => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-2xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-center">Invitation à collaborer</h2>
        <p className="mb-6 text-center">Vous avez été invité à collaborer sur le dossier : <strong>{dossierTitle}</strong></p>
        <div className="flex justify-evenly">
          <button
            onClick={onAccept}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
          >
            Accepter
          </button>
          <button
            onClick={onReject}
            className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
          >
            Refuser
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvitationPrompt