import React, { CSSProperties } from 'react';

interface ListBulletProps {
    fill?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const ListBullet: React.FC<ListBulletProps> = ({ fill = '#000000', width = '100%', height = '100%', style, className }) => {
    return (
        <svg
            className={className}
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <g fill={fill}>
                <circle className="cls-1" cx="267.5" cy="717.5" r="41.5" />
                <rect className="cls-1" x="393.5" y="686" width="440.5" height="63" rx="31.5" ry="31.5" />
                <circle className="cls-1" cx="267.5" cy="530.5" r="41.5" />
                <rect className="cls-1" x="393.5" y="499" width="440.5" height="63" rx="31.5" ry="31.5" />
                <circle className="cls-1" cx="267.5" cy="342.5" r="41.5" />
                <rect className="cls-1" x="393.5" y="311" width="440.5" height="63" rx="31.5" ry="31.5" />
            </g>
        </svg>
    );
};

export default ListBullet;
