import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import Clone from '../../icons/Clone';

interface VisibilitySettingsProps {
  visibility: string;
  onVisibilityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const VisibilitySettings: React.FC<VisibilitySettingsProps> = ({ visibility, onVisibilityChange }) => {
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentUrl = window.location.href;

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  const handleQrClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <p className='text-lg font-semibold'>Paramètres de visibilité du dossier</p>
      <div className="flex flex-col mt-4">
        <label className="mb-2 flex items-start">
          <input
            type="radio"
            value="private"
            checked={visibility === 'private'}
            onChange={onVisibilityChange}
            className="form-radio h-4 w-4 text-mcdm-vert-400 transition duration-150 ease-in-out mt-1"
          />
          <span className="ml-2">
            <span>Privé</span>
            <br />
            <span className="text-gray-500 text-sm italic">Seulement vous, et les co-auteurs</span>
          </span>
        </label>
        <label className="mb-2 flex items-start">
          <input
            type="radio"
            value="public"
            checked={visibility === 'public'}
            onChange={onVisibilityChange}
            className="form-radio h-4 w-4 text-mcdm-vert-400 transition duration-150 ease-in-out mt-1"
          />
          <span className="ml-2">
            <span>Public</span>
            <br />
            <span className="text-gray-500 text-sm italic">Visible par tous</span>
          </span>
        </label>
        <label className="mb-2 flex items-start">
          <input
            type="radio"
            value="on_request"
            checked={visibility === 'on_request'}
            onChange={onVisibilityChange}
            className="form-radio h-4 w-4 text-mcdm-vert-400 transition duration-150 ease-in-out mt-1"
          />
          <span className="ml-2">
            <span>Sur demande</span>
            <br />
            <span className="text-gray-500 text-sm italic">Contrôlez l'accès sur mesure</span>
          </span>
        </label>
      </div>

      {visibility === 'public' && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-4">Partage par QR code</h3>
          <div className="flex items-center">
            <QRCode value={currentUrl} size={120} onClick={handleQrClick} style={{ cursor: 'pointer' }} />
          </div>
          <div className="mt-4">
            <button
              onClick={handleCopyUrl}
              className={`px-4 py-2 rounded text-white flex items-center justify-center ${copied ? 'bg-mcdm-vert-400' : 'bg-mcdm-vert-400 hover:bg-mcdm-vert-500'
                }`}
            >
              <Clone fill="white" width="20px" height="20px" className="mr-2" />
              {copied ? 'Copie réussie !' : 'Copier l\'adresse'}
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="bg-white p-8 rounded-lg relative" onClick={(e) => e.stopPropagation()}>
            <QRCode 
              value={currentUrl} 
              size={500} 
              className='mt-0'
            />
          </div>

        </div>
      )}
    </div>
  );
};

export default VisibilitySettings;
