import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import PlusCircle from '../icons/PlusCircle';

// Composant de squelette pour le chargement
const Skeleton: React.FC<{
  width?: string,
  height?: string,
  circle?: boolean,
  style?: React.CSSProperties
}> = ({
  width = '100%',
  height = '16px',
  circle = false,
  style = {}
}) => {
    const defaultStyle = {
      width,
      height,
      borderRadius: circle ? '50%' : '4px',
      margin: '4px 0',
      ...style,
    };

    return (
      <div
        style={defaultStyle}
        className={`skeleton bg-mcdm-third-200 dark:bg-mcdm-primary-100`}
      />
    );
  };

// Types
interface AuthorsBoxProps {
  dossierId: string;
  userRole: string | null;
  onOpenSettingsModal: (tab: string) => void;
}

interface Author {
  _id: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  email: string;
}

interface AuthorDetails {
  author: Author;
  coAuthors: Author[];
}

// Composant principal
const AuthorsBox: React.FC<AuthorsBoxProps> = ({
  dossierId,
  userRole,
  onOpenSettingsModal
}) => {
  const { token } = useAuth();
  const [authorDetails, setAuthorDetails] = useState<AuthorDetails | null>(null);
  const [imageLoadErrors, setImageLoadErrors] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(
          `${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${dossierId}/authors`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails des auteurs');
        }

        const data = await response.json();
        setAuthorDetails(data);
      } catch (error) {
        console.error('Erreur:', error);
        setError(error instanceof Error ? error.message : 'Une erreur est survenue');
      } finally {
        setIsLoading(false);
      }
    };

    if (dossierId && token) {
      fetchAuthorDetails();
    }
  }, [dossierId, token]);

  const handleImageError = (id: string) => {
    setImageLoadErrors(prev => new Set(prev).add(id));
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  // Composant pour l'image de profil
  const ProfileImage: React.FC<{
    src: string | null;
    alt: string;
    firstName: string;
    lastName: string;
    userId: string;
  }> = ({ src, alt, firstName, lastName, userId }) => {
    if (!src || imageLoadErrors.has(userId)) {
      return (
        <div className="w-8 h-8 rounded-full bg-mcdm-third-200 text-white text-xs flex items-center justify-center mr-2">
          {getInitials(firstName, lastName)}
        </div>
      );
    }

    return (
      <img
        src={src}
        alt={alt}
        className="w-8 h-8 rounded-full mr-2 object-cover"
        onError={() => handleImageError(userId)}
      />
    );
  };

  // État de chargement
  if (isLoading) {
    return (
      <div className="pt-5 pl-5 pr-5">
        <h2 className="text-s font-semibold mb-2">Auteur Principal</h2>
        <div className="flex items-center">
          <Skeleton width="32px" height="32px" circle={true} />
          <Skeleton width="100px" style={{ marginLeft: '8px' }} />
        </div>

        <h2 className="text-s font-semibold mt-3 mb-2">Co-Auteurs</h2>
        <ul>
          {[1, 2].map((_, index) => (
            <li key={index} className='text-sm flex items-center mb-3'>
              <Skeleton width="32px" height="32px" circle={true} />
              <Skeleton width="100px" style={{ marginLeft: '8px' }} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  // État d'erreur
  if (error) {
    return (
      <div className="pt-5 pl-5 pr-5 text-red-500">
        <p>Erreur : {error}</p>
      </div>
    );
  }

  // Pas de données
  if (!authorDetails) {
    return (
      <div className="pt-5 pl-5 pr-5">
        <p>Aucune information disponible</p>
      </div>
    );
  }

  // Affichage normal
  return (
    <div className="pt-5 pl-5 pr-5">
      <div className="flex items-center mb-2">
        <h2 className="text-s font-semibold text-mcdm-third-500 dark:text-mcdm-primary-100">
          Auteur Principal
        </h2>
        {(userRole === 'author' || userRole === 'admin') && (
          <button
            onClick={() => onOpenSettingsModal('Auteurs')}
            aria-label="Ajouter Co-Auteur"
            className="ml-2 hover:opacity-80 transition-opacity duration-200"
          >
            <PlusCircle
              className="text-mcdm-secondary-400 dark:text-mcdm-secondary-400"
              width="20px"
              height="20px"
            />
          </button>
        )}
      </div>

      {/* Auteur Principal */}
      <div className="flex items-center">
        <ProfileImage
          src={authorDetails.author.profilePicture}
          alt={`${authorDetails.author.firstName} ${authorDetails.author.lastName}`}
          firstName={authorDetails.author.firstName}
          lastName={authorDetails.author.lastName}
          userId={authorDetails.author._id}
        />
        <span className='text-sm text-mcdm-third-500 dark:text-mcdm-primary-100'>
          {authorDetails.author.firstName} {authorDetails.author.lastName}
        </span>
      </div>

      {/* Co-Auteurs */}
      {authorDetails.coAuthors.length > 0 && (
        <>
          <h2 className="text-s font-semibold text-mcdm-third-500 dark:text-mcdm-primary-100 mt-3 mb-2">
            Co-Auteurs
          </h2>
          <ul>
            {authorDetails.coAuthors.map((coAuthor, index) => (
              <li
                key={`${coAuthor._id}-${index}`}
                className='text-sm flex items-center mb-3 text-mcdm-third-500 dark:text-mcdm-primary-100'
              >
                <ProfileImage
                  src={coAuthor.profilePicture}
                  alt={`${coAuthor.firstName} ${coAuthor.lastName}`}
                  firstName={coAuthor.firstName}
                  lastName={coAuthor.lastName}
                  userId={coAuthor._id}
                />
                <span>
                  {coAuthor.firstName} {coAuthor.lastName}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AuthorsBox;