import React, { useEffect, useRef } from 'react';
import { useMacadamia } from '../../contexts/MacadamiaContext';

interface TitleWidgetProps {
    title: string;
    onTitleChange: (newTitle: string) => void;
}

const TitleWidget: React.FC<TitleWidgetProps> = ({ title, onTitleChange }) => {
    const { currentState } = useMacadamia();
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onTitleChange(e.target.value);
    };

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [title, currentState]);

    useEffect(() => {
        if (currentState === 'editor') {
            adjustHeight();
            setTimeout(adjustHeight, 0);
        }
    }, [currentState]);

    return (
        <div>
            {currentState === 'editor' ? (
                <textarea
                    ref={textareaRef}
                    value={title}
                    onChange={(e) => {
                        handleChange(e);
                        adjustHeight();
                    }}
                    placeholder="Veuillez ajouter votre titre"
                    style={{
                        fontSize: '32px',
                        border: '1px solid rgba(255, 255, 255, 0)',
                        outline: 'none',
                        padding: '0',
                        width: '100%',
                        resize: 'none',
                        overflow: 'hidden',
                        textAlign: 'justify',
                        whiteSpace: 'pre-wrap',
                        backgroundColor: 'transparent',
                    }}
                    rows={1}
                    className="title-widget-editor font-platypi dark:text-mcdm-primary-100"
                />
            ) : (
                <h1
                    style={{
                        fontSize: '32px',
                        textAlign: 'justify',
                        whiteSpace: 'pre-wrap',
                        border: '1px solid rgba(255, 255, 255, 0)',
                    }}
                    className="font-platypi dark:text-mcdm-primary-100"
                >
                    {title}
                </h1>
            )}
        </div>
    );
};

export default TitleWidget;
