import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';

interface CurrentChapterContextType {
  currentChapterIndex: number;
  setCurrentChapterIndex: (index: number) => void;
}

const CurrentChapterContext = createContext<CurrentChapterContextType | undefined>(undefined);

export const CurrentChapterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentChapterIndex, setCurrentChapterIndexState] = useState(0);

  // Optimisation: useCallback pour éviter de recréer cette fonction à chaque rendu
  const setCurrentChapterIndex = useCallback((index: number) => {
    setCurrentChapterIndexState(prevIndex => {
      // N'effectuez le changement que si l'index est différent pour éviter les re-rendus inutiles
      if (prevIndex !== index) {
        return index;
      }
      return prevIndex;
    });
  }, []);

  // Memoïsation de la valeur du contexte pour éviter des re-rendus inutiles
  const contextValue = useMemo(() => ({
    currentChapterIndex,
    setCurrentChapterIndex
  }), [currentChapterIndex, setCurrentChapterIndex]);

  return (
    <CurrentChapterContext.Provider value={contextValue}>
      {children}
    </CurrentChapterContext.Provider>
  );
};

export const useCurrentChapter = (): CurrentChapterContextType => {
  const context = useContext(CurrentChapterContext);
  if (context === undefined) {
    throw new Error('useCurrentChapter must be used within a CurrentChapterProvider');
  }
  return context;
};