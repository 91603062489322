import React, { CSSProperties } from 'react';

interface GifProps {
    stroke?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Gif: React.FC<GifProps> = ({
    stroke = '#000000',
    width = '100%',
    height = '100%',
    style,
    className
}) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            aria-label="Icone GIF"
            role="img"
        >
            <path
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="50px"
                d="m839.26,249H220.74c-24.16,0-43.74,22.55-43.74,50.37v461.27c0,27.82,19.58,50.37,43.74,50.37h618.53c24.15,0,43.74-22.55,43.74-50.37v-461.27c0-27.82-19.58-50.37-43.74-50.37Z"
            />
            <polyline
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="50px"
                points="637.55 623.75 637.55 436.25 737.24 436.25"
            />
            <line
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="50px"
                x1="637.55"
                y1="537.48"
                x2="700.05"
                y2="537.48"
            />
            <line
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="50px"
                x1="541.59"
                y1="436.25"
                x2="541.59"
                y2="623.75"
            />
            <polyline
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="50px"
                points="447.76 436.25 322.76 436.25 322.76 623.75 447.76 623.75 447.76 539.18 408.13 539.18"
            />
        </svg>
    );
};

export default Gif;
