import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface PromoCodeModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (code: string) => Promise<boolean>;
    currentPromoCode: string;
}

export default function PromoCodeModal({ isOpen, onClose, onSubmit, currentPromoCode }: PromoCodeModalProps) {
    const [tempPromoCode, setTempPromoCode] = React.useState('');
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!tempPromoCode.trim()) {
            setError('Veuillez entrer un code promotionnel');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const isValid = await onSubmit(tempPromoCode.trim());
            if (isValid) {
                setTempPromoCode('');
                setError('');
            } else {
                setError('Code promotionnel invalide ou expiré');
            }
        } catch (err) {
            setError('Une erreur est survenue lors de la validation du code');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={onClose}
                        className="absolute inset-0 bg-black/50 dark:bg-black/70"
                    />

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        className="relative bg-white dark:bg-mcdm-third-400 rounded-lg shadow-lg p-6 w-full max-w-md mx-4"
                    >
                        <h3 className="text-lg font-bold text-mcdm-third-500 dark:text-mcdm-primary-100 mb-4 text-center">
                            Saisir un code promotionnel
                        </h3>

                        <form onSubmit={handleSubmit} className="space-y-4">
                            <input
                                type="text"
                                value={tempPromoCode}
                                onChange={(e) => setTempPromoCode(e.target.value)}
                                placeholder="Entrez votre code promo"
                                className="w-full px-3 py-2 border border-mcdm-third-200 dark:border-mcdm-third-300 
                                         rounded-md focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 
                                         dark:focus:ring-mcdm-secondary-400 bg-white dark:bg-mcdm-primary-100 
                                         text-mcdm-third-500 dark:text-mcdm-third-100 
                                         placeholder:text-mcdm-third-300 dark:placeholder:text-mcdm-third-200"
                                disabled={isLoading}
                            />
                            
                            {error && (
                                <p className="text-red-500 dark:text-red-400 text-sm text-center">
                                    {error}
                                </p>
                            )}
                            
                            {currentPromoCode === 'LANCEMENT2024' && (
                                <p className="text-sm text-mcdm-third-400 dark:text-mcdm-primary-300 text-center">
                                    Un code promotionnel est déjà appliqué
                                </p>
                            )}
                            
                            <div className="flex justify-center gap-4">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="px-4 py-2 text-mcdm-third-500 dark:text-mcdm-primary-200 
                                             border border-mcdm-third-200 dark:border-mcdm-third-300 
                                             rounded hover:bg-mcdm-third-50 dark:hover:bg-mcdm-third-300 
                                             transition-colors disabled:opacity-50"
                                    disabled={isLoading}
                                >
                                    Annuler
                                </button>
                                
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 
                                             text-white dark:text-mcdm-third-400 rounded 
                                             hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500 
                                             transition-colors disabled:opacity-50
                                             disabled:hover:bg-mcdm-secondary-400"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" 
                                                 xmlns="http://www.w3.org/2000/svg" 
                                                 fill="none" 
                                                 viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" 
                                                        stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" 
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                                </path>
                                            </svg>
                                            Chargement...
                                        </>
                                    ) : 'Appliquer'}
                                </button>
                            </div>
                        </form>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}