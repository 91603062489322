import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CurrentChapterContextType {
  currentChapterIndex: number;
  setCurrentChapterIndex: (index: number) => void;
}

const CurrentChapterContext = createContext<CurrentChapterContextType | undefined>(undefined);

export const CurrentChapterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);

  return (
    <CurrentChapterContext.Provider value={{ currentChapterIndex, setCurrentChapterIndex }}>
      {children}
    </CurrentChapterContext.Provider>
  );
};

export const useCurrentChapter = (): CurrentChapterContextType => {
  const context = useContext(CurrentChapterContext);
  if (context === undefined) {
    throw new Error('useCurrentChapter must be used within a CurrentChapterProvider');
  }
  return context;
};
