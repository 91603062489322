import React, { createContext, useContext, useState, useEffect, PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface LegifranceAuthContextType {
  legifranceToken: string | null;
  tokenExpiry: number | null;
  setLegifranceToken: (token: string, expiry: number) => void;
  refreshLegifranceToken: () => Promise<void>;
  isTokenValid: boolean;
  isLoading: boolean;
  error: string | null;
}

const LegifranceAuthContext = createContext<LegifranceAuthContextType | undefined>(undefined);

export const LegifranceAuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [legifranceToken, setLegifranceTokenState] = useState<string | null>(localStorage.getItem('legifranceToken'));
  const [tokenExpiry, setTokenExpiry] = useState<number | null>(() => {
    const expiry = localStorage.getItem('legifranceTokenExpiry');
    return expiry ? parseInt(expiry, 10) : null;
  });
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const navigate = useNavigate();

  const setLegifranceToken = (token: string, expiry: number) => {
    localStorage.setItem('legifranceToken', token);
    localStorage.setItem('legifranceTokenExpiry', expiry.toString());
    setLegifranceTokenState(token);
    setTokenExpiry(expiry);
    setIsTokenValid(true);
    setError(null);
    setRetryCount(0);
  };

  const refreshLegifranceToken = useCallback(async () => {
    if (isLoading) return;
    if (legifranceToken && tokenExpiry && Date.now() < tokenExpiry) return;
    if (retryCount >= 3) {
      setError("Impossible de récupérer le token après plusieurs tentatives");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/legifrance/get-oauth-token`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLegifranceToken(data.token, Date.now() + 3600 * 1000);
    } catch (error) {
      console.error('Erreur lors du renouvellement du token Légifrance:', error);
      setIsTokenValid(false);
      setError((error as Error).message);
      setRetryCount(prev => prev + 1);

      if (retryCount >= 2) {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  }, [navigate, legifranceToken, tokenExpiry, retryCount, isLoading]);

  useEffect(() => {
    if (!isTokenValid && !error) {
      refreshLegifranceToken();
    }

    const interval = setInterval(() => {
      if (!error) {
        refreshLegifranceToken();
      }
    }, 3540000); // 59 minutes

    return () => clearInterval(interval);
  }, [refreshLegifranceToken, isTokenValid, error]);

  return (
    <LegifranceAuthContext.Provider
      value={{
        legifranceToken,
        tokenExpiry,
        setLegifranceToken,
        refreshLegifranceToken,
        isTokenValid,
        isLoading,
        error
      }}
    >
      {children}
    </LegifranceAuthContext.Provider>
  );
};

export const useLegifranceAuth = () => {
  const context = useContext(LegifranceAuthContext);
  if (context === undefined) {
    throw new Error('useLegifranceAuth must be used within a LegifranceAuthProvider');
  }
  return context;
};