import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import TopBar from '../../../layout/TopBar';
import JurisprudenceItem from './JurisprudenceItem';

const Jurisprudences = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [suivis, setSuivis] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSuivis = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudence-suivi/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la récupération des suivis');
      }

      const data = await response.json();
      setSuivis(data);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message || 'Impossible de récupérer les suivis. Veuillez réessayer.');
      } else {
        setError('Impossible de récupérer les suivis. Veuillez réessayer.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuivis();
  }, []);

  const handleUnfollow = async (jurisprudenceId: string) => {
    setSuivis(currentSuivis =>
      currentSuivis.filter(suivi => suivi.jurisprudenceId !== jurisprudenceId)
    );
  };

  const filteredSuivis = suivis.filter((suivi) =>
    suivi.jurisprudenceId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-mcdm-primary-100 dark:bg-mcdm-third-500 pb-10">
      <TopBar />
      <div className="pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white dark:bg-mcdm-third-400 rounded-lg shadow-lg dark:shadow-inner p-6">
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:justify-between sm:items-center mb-6">
              <h1 className="text-2xl font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                Jurisprudences Suivies
              </h1>

              <div className="w-full sm:w-72">
                <input
                  type="text"
                  placeholder="Rechercher..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-mcdm-third-300 dark:border-mcdm-third-500 
                           bg-white dark:bg-mcdm-third-300 text-mcdm-third-500 dark:text-mcdm-primary-100
                           focus:ring-2 focus:ring-mcdm-primary-300 dark:focus:ring-mcdm-third-200
                           focus:border-mcdm-primary-300 outline-none transition-all duration-200"
                />
              </div>
            </div>

            {error && (
              <p className="text-red-500 dark:text-red-400 mb-4 text-sm">
                {error}
              </p>
            )}

            <div className="space-y-4">
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <div
                    key={index}
                    className="bg-mcdm-primary-100 dark:bg-mcdm-third-300 p-4 rounded-lg animate-pulse"
                  >
                    <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-full"></div>
                  </div>
                ))
              ) : (
                <>
                  {filteredSuivis.length > 0 ? (
                    filteredSuivis.map((suivi, index) => (
                      <JurisprudenceItem
                        key={index}
                        jurisprudenceId={suivi.jurisprudenceId}
                        onUnfollow={handleUnfollow}
                      />
                    ))
                  ) : (
                    <p className="text-mcdm-third-500 dark:text-mcdm-primary-200">Aucun suivi trouvé.</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jurisprudences;