import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';

interface EditorState {
  originalContent: any[];
  currentContent: any[];
  hasChanges: boolean;
}

interface EditorContextType extends EditorState {
  setCurrentContent: (content: any[]) => void;
  updateContent: (content: any[]) => void;
  saveContent: () => Promise<void>;
}

interface EditorProviderProps {
  children: ReactNode;
}

const EditorContext = createContext<EditorContextType | undefined>(undefined);

export const EditorProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const [editorState, setEditorState] = useState<EditorState>({
    originalContent: [],
    currentContent: [],
    hasChanges: false,
  });

  const setCurrentContent = useCallback((content: any[]) => {
    console.log("Setting current content:", content);
    setEditorState((prevState) => {
      const newState = {
        ...prevState,
        currentContent: content,
        originalContent: prevState.originalContent.length === 0 ? content : prevState.originalContent,
        hasChanges: JSON.stringify(prevState.originalContent) !== JSON.stringify(content),
      };
      console.log("Updated state after setting current content:", newState);
      return newState;
    });
  }, []);

  const updateContent = useCallback((content: any[]) => {
    console.log("Updating content:", content);
    setEditorState((prevState) => {
      const newState = {
        ...prevState,
        currentContent: content,
        hasChanges: JSON.stringify(prevState.originalContent) !== JSON.stringify(content),
      };
      console.log("Updated state after updating content:", newState);
      return newState;
    });
  }, []);

  const saveContent = useCallback(async () => {
    console.log("Attempting to save content", editorState.currentContent);
    if (editorState.currentContent.length > 0) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setEditorState(prevState => {
        const newState = {
          ...prevState,
          originalContent: prevState.currentContent,
          hasChanges: false,
        };
        console.log("Updated state after saving content:", newState);
        return newState;
      });
    } else {
      console.warn("Nothing to save, currentContent is empty");
    }
  }, [editorState.currentContent]);

  useEffect(() => {
    console.log("Editor state updated:", editorState);
  }, [editorState]);

  return (
    <EditorContext.Provider value={{ ...editorState, setCurrentContent, updateContent, saveContent }}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = (): EditorContextType => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};
