import React, { useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

interface Notification {
    _id: string;
    message: string;
    status: 'unread' | 'read';
    createdAt: string;
    type: string;
    relatedEntityId?: string;
}

interface NotificationListProps {
    notifications: Notification[];
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => void;
    markAsRead: (id: string) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({ notifications, isLoading, hasMore, loadMore, markAsRead }) => {
    const lastNotificationElementRef = useRef<HTMLDivElement | null>(null);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    // Observer pour marquer une notification comme lue
    const observeUnreadNotification = useCallback((element: HTMLElement | null, notificationId: string) => {
        if (!element) return;

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                markAsRead(notificationId);
                observer.disconnect();
            }
        }, { threshold: 1.0 });

        observer.observe(element);

        return () => observer.disconnect();
    }, [markAsRead]);

    // Observer pour charger plus de notifications
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !isLoading && hasMore) {
                loadMore();
            }
        }, { threshold: 0.1 });

        if (lastNotificationElementRef.current) {
            observer.observe(lastNotificationElementRef.current);
        }

        return () => observer.disconnect();
    }, [isLoading, hasMore, loadMore]);

    // Observez les notifications non lues
    useEffect(() => {
        const cleanupFunctions = notifications
            .filter(notification => notification.status === 'unread')
            .map(notification => {
                const element = document.getElementById(`notification-${notification._id}`) as HTMLDivElement | null;
                return observeUnreadNotification(element, notification._id);
            });

        return () => cleanupFunctions.forEach(cleanup => cleanup && cleanup());
    }, [notifications, observeUnreadNotification]);

    // Formatage de la date
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Date non disponible';
        }
        return date.toLocaleString();
    };

    if (notifications.length === 0 && !isLoading) {
        return (
            <div className="p-4 bg-white border border-gray-200 rounded-t-lg max-h-[320px] h-[320px] w-[225px] flex flex-col items-center justify-center text-center">
                <span className="text-gray-400 text-sm mt-1">...zZz...</span>
                <span className="text-sm mt-1">Aucune notification</span>
            </div>
        );
    }

    return (
        <div className="bg-white border border-gray-200 rounded-t-lg max-h-[320px] h-[320px] w-[225px] overflow-y-auto" ref={scrollRef}>
            <h2 className="pl-4 pr-4 pt-4 pb-2 text-lg font-platypi text-mcdm-marron-500 text-left mb-2">Notifications</h2>

            {isLoading && notifications.length === 0 && (
                <div className="p-4">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} className="animate-pulse flex flex-col space-y-4 mb-4">
                            <div className="h-4 bg-gray-300 rounded"></div>
                            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                        </div>
                    ))}
                </div>
            )}

            {notifications.map((notification, index) => {
                const dossierUrl = notification.relatedEntityId
                    ? `/mes-dossiers/${notification.relatedEntityId}`
                    : '#';

                const NotificationContent = () => (
                    <>
                        <div className="text-sm mb-2 font-normal">
                            {notification.message}
                        </div>
                        <div className="text-xs text-gray-500">
                            {formatDate(notification.createdAt)}
                        </div>
                    </>
                );

                return (
                    <div
                        key={notification._id || index}
                        id={`notification-${notification._id}`}
                        className={`pl-4 pb-4 mb-0 border-b border-gray-200 last:border-b-0 p-2 ${
                            notification.status === 'unread' ? 'bg-mcdm-vert-300' : 'bg-white'
                        } hover:bg-mcdm-vert-100`}
                        onClick={() => {
                            if (notification.status === 'unread') {
                                markAsRead(notification._id);
                            }
                        }}
                    >
                        {notification.relatedEntityId ? (
                            <Link to={dossierUrl}>
                                <NotificationContent />
                            </Link>
                        ) : (
                            <NotificationContent />
                        )}
                    </div>
                );
            })}

            {notifications.length > 0 && (
                <div ref={lastNotificationElementRef} style={{ height: '20px' }}></div>
            )}

            {isLoading && notifications.length > 0 && (
                <div className="p-4 text-center text-gray-500">
                    Chargement...
                </div>
            )}
        </div>
    );
};

export default NotificationList;