import React, { useState } from 'react';
import { requestPasswordReset } from '../../../services/authService';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            await requestPasswordReset(email);
            setSubmitted(true);
        } catch (err) {
            setError('Une erreur est survenue lors de l\'envoi de l\'email');
        } finally {
            setIsLoading(false);
        }
    };

    if (submitted) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500">
                <div className="bg-white dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-lg px-8 pt-6 pb-8 mb-4 max-w-md w-full">
                    <h2 className="text-xl mb-6 text-center font-400 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                        Email envoyé
                    </h2>
                    <p className="text-center text-mcdm-third-500 dark:text-mcdm-primary-200 mb-4">
                        Si un compte existe avec cette adresse email, vous recevrez un lien de réinitialisation valide pendant 15 minutes.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500">
            <form onSubmit={handleSubmit} className="bg-white dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-lg px-8 pt-6 pb-8 mb-4 max-w-md w-full">
                <h2 className="text-xl mb-6 text-center font-400 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                    Réinitialiser le mot de passe
                </h2>
                
                <div className="mb-4">
                    <label className="block text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        placeholder="Votre email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                {error && <p className="text-red-500 text-xs italic mb-4 text-center">{error}</p>}
                
                <button
                    className={`w-full bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400 py-2 px-4 rounded shadow-inner focus:outline-none focus:shadow-outline ${
                        isLoading 
                            ? 'opacity-50 cursor-not-allowed' 
                            : 'hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500'
                    }`}
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Envoi en cours...
                        </>
                    ) : (
                        'Envoyer le lien de réinitialisation'
                    )}
                </button>
            </form>
        </div>
    );
};

export default ForgotPassword;