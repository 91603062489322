import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface PublicRouteProps {
    children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
    const { isAuthenticated, user } = useAuth();

    // Si l'utilisateur est authentifié, on le redirige vers la page appropriée
    if (isAuthenticated) {
        if (!user?.verifiedUser) {
            return <Navigate to="/verifier-email" replace />;
        }
        if (!user?.plan) {
            return <Navigate to="/choisir-plan" replace />;
        }
        return <Navigate to="/mes-dossiers" replace />;
    }

    return children;
};

export default PublicRoute;