import React, { useState } from 'react';
import { useJuridicalReferences, JuridicalReference } from '../contexts/JuridicalReferencesContext';
import { IDossier } from '../components/widgets/types';

interface JuridicalReferencesModalProps {
    isOpen: boolean;
    onClose: () => void;
    dossierContent: IDossier | null;
}

const JuridicalReferencesModal: React.FC<JuridicalReferencesModalProps> = ({
    isOpen,
    onClose,
    dossierContent
}) => {
    const { references } = useJuridicalReferences();
    const [filterType, setFilterType] = useState<'all' | 'jurisprudence' | 'legifrance'>('all');
    const [searchTerm, setSearchTerm] = useState('');

    // Filtrer les références en fonction du type et du terme de recherche
    const filteredReferences = references.filter(ref => {
        const matchesType = filterType === 'all' || ref.type === filterType;
        const matchesSearch = searchTerm === '' ||
            ref.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
            ref.id.toLowerCase().includes(searchTerm.toLowerCase());

        return matchesType && matchesSearch;
    });

    // Fonction pour naviguer vers la référence dans le document
    const navigateToReference = (reference: JuridicalReference) => {
        // Fermer le modal
        onClose();

        // Trouver l'élément concerné dans le DOM et le mettre en évidence
        setTimeout(() => {
            const elements = document.querySelectorAll(`.jurisprudence-highlight[data-reference-id="${reference.id}"], .legifrance-highlight[data-legifrance-code]`);

            elements.forEach(element => {
                // Vérifier si c'est une référence Legifrance avec les mêmes attributs
                if (element.classList.contains('legifrance-highlight') && reference.type === 'legifrance') {
                    const codeId = element.getAttribute('data-legifrance-code');
                    const sectionId = element.getAttribute('data-legifrance-section');
                    const articleId = element.getAttribute('data-legifrance-article');

                    const expectedCodeId = reference.details.codeId;
                    const expectedSectionId = reference.details.sectionId;
                    const expectedArticleId = reference.details.articleId;

                    if (codeId === expectedCodeId &&
                        (!expectedSectionId || sectionId === expectedSectionId) &&
                        (!expectedArticleId || articleId === expectedArticleId)) {
                        scrollToElement(element);
                        highlightElement(element);
                        return;
                    }
                } else if (element.classList.contains('jurisprudence-highlight') && reference.type === 'jurisprudence') {
                    scrollToElement(element);
                    highlightElement(element);
                    return;
                }
            });
        }, 100);
    };

    // Fonction pour faire défiler jusqu'à l'élément
    const scrollToElement = (element: Element) => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    // Fonction pour mettre en évidence l'élément temporairement
    const highlightElement = (element: Element) => {
        const originalBackground = element.getAttribute('style') || '';
        element.setAttribute('style', 'background-color: #FFEB3B !important; transition: background-color 0.5s;');

        setTimeout(() => {
            element.setAttribute('style', originalBackground);
        }, 2000);
    };

    // Gérer les clics sur l'overlay pour fermer le modal
    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // Seulement si c'est un clic direct sur l'overlay (pas sur le contenu du modal)
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={handleOverlayClick}
        >
            <div className="bg-white dark:bg-mcdm-third-500 rounded-lg shadow-xl p-6 w-11/12 max-w-4xl max-h-[80vh] overflow-hidden flex flex-col">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl text-mcdm-third-500 dark:text-white font-platypi">
                        Références juridiques ({references.length})
                    </h2>
                    <button
                        onClick={onClose}
                        className="text-mcdm-third-400 hover:text-mcdm-third-500 dark:text-mcdm-primary-100 dark:hover:text-white"
                    >
                        ✕
                    </button>
                </div>

                <div className="flex items-center mb-4 space-x-4">
                    <div className="relative flex-grow">
                        <input
                            type="text"
                            placeholder="Rechercher une référence..."
                            className="w-full p-2 border border-gray-300 dark:border-mcdm-third-400 rounded-md dark:bg-mcdm-third-400 dark:text-white"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm && (
                            <button
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300"
                                onClick={() => setSearchTerm('')}
                            >
                                ✕
                            </button>
                        )}
                    </div>

                    <div className="flex space-x-2 text-sm">
                        <button
                            className={`px-3 py-1 rounded-md ${filterType === 'all' ? 'bg-mcdm-third-500 text-white dark:bg-mcdm-primary-300 dark:text-mcdm-third-500' : 'bg-gray-200 text-gray-700 dark:bg-mcdm-third-400 dark:text-mcdm-primary-100'}`}
                            onClick={() => setFilterType('all')}
                        >
                            Tous
                        </button>
                        <button
                            className={`px-3 py-1 rounded-md ${filterType === 'jurisprudence' ? 'bg-mcdm-third-500 text-white dark:bg-mcdm-primary-300 dark:text-mcdm-third-500' : 'bg-gray-200 text-gray-700 dark:bg-mcdm-third-400 dark:text-mcdm-primary-100'}`}
                            onClick={() => setFilterType('jurisprudence')}
                        >
                            Jurisprudences
                        </button>
                        <button
                            className={`px-3 py-1 rounded-md ${filterType === 'legifrance' ? 'bg-mcdm-third-500 text-white dark:bg-mcdm-primary-300 dark:text-mcdm-third-500' : 'bg-gray-200 text-gray-700 dark:bg-mcdm-third-400 dark:text-mcdm-primary-100'}`}
                            onClick={() => setFilterType('legifrance')}
                        >
                            Textes de loi
                        </button>
                    </div>
                </div>

                <div className="overflow-y-auto flex-grow custom-scrollbar">
                    {filteredReferences.length === 0 ? (
                        <div className="text-center py-8 text-gray-500 dark:text-gray-300">
                            {searchTerm ? 'Aucune référence ne correspond à votre recherche.' : 'Aucune référence juridique trouvée dans ce document.'}
                        </div>
                    ) : (
                        <table className="w-full border-collapse">
                            <thead className="bg-gray-100 dark:bg-mcdm-third-400">
                                <tr>
                                    <th className="py-2 px-4 text-left text-mcdm-third-500 dark:text-white">Type</th>
                                    <th className="py-2 px-4 text-left text-mcdm-third-500 dark:text-white">Référence</th>
                                    <th className="py-2 px-4 text-left text-mcdm-third-500 dark:text-white">Texte</th>
                                    <th className="py-2 px-4 text-left text-mcdm-third-500 dark:text-white">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredReferences.map((ref, index) => (
                                    <tr
                                        key={`${ref.id}-${index}`}
                                        className={`border-t border-gray-200 dark:border-mcdm-third-400 hover:bg-gray-50 dark:hover:bg-mcdm-third-400`}
                                    >
                                        <td className="py-3 px-4 text-mcdm-third-500 dark:text-white">
                                            {ref.type === 'jurisprudence' ? 'Jurisprudence' : 'Texte de loi'}
                                        </td>
                                        <td className="py-3 px-4 text-mcdm-third-500 dark:text-white">
                                            {ref.type === 'jurisprudence' ? ref.details.referenceId : (
                                                <>
                                                    {ref.details.codeId}
                                                    {ref.details.sectionId && <span> § {ref.details.sectionId}</span>}
                                                    {ref.details.articleId && <span> Art. {ref.details.articleId}</span>}
                                                </>
                                            )}
                                        </td>
                                        <td className="py-3 px-4 text-mcdm-third-500 dark:text-white truncate max-w-xs">
                                            {ref.text}
                                        </td>
                                        <td className="py-3 px-4">
                                            <button
                                                className="px-3 py-1 bg-mcdm-secondary-400 text-white rounded-md hover:bg-mcdm-secondary-500 text-sm"
                                                onClick={() => navigateToReference(ref)}
                                            >
                                                Voir
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default JuridicalReferencesModal;