import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../lottie/enveloppe.json';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth, User } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface Notification {
  id: string;
  message: string;
  type: 'success' | 'error';
}

const VerifyEmailPage = () => {
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [timer, setTimer] = useState<number>(10);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Référence pour contrôler l'animation Lottie
  const lottieRef = useRef<any>(null);
  const [totalFrames, setTotalFrames] = useState<number>(0);

  // Récupération de logout, user et token
  const { logout, user, token, setUser } = useAuth();
  const navigate = useNavigate();

  // Effet pour rediriger si l'utilisateur a déjà vérifié son email
  useEffect(() => {
    if (user?.verifiedUser) {
      // Rediriger vers /mes-dossiers si l'email est déjà vérifié
      navigate('/mes-dossiers', { replace: true });
    }
  }, [user, navigate]);

  // Obtenir le nombre total de frames de l'animation et figer à la frame 0
  useEffect(() => {
    if (lottieRef.current) {
      setTotalFrames(lottieRef.current.getDuration(true));
      lottieRef.current.goToAndStop(0, true); // Figer à la frame 0
    }
  }, []);

  // Fonction pour ajouter une notification
  const addNotification = (message: string, type: 'success' | 'error') => {
    const id = Math.random().toString(36).substr(2, 9);
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id, message, type },
    ]);

    // Supprimer automatiquement la notification après 5 secondes
    setTimeout(() => {
      removeNotification(id);
    }, 5000);
  };

  // Fonction pour supprimer une notification
  const removeNotification = (id: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  // Gestion du compteur pour le bouton "Recevoir un nouveau code"
  useEffect(() => {
    let interval: number | null = null;
    if (isResendDisabled && timer > 0) {
      interval = window.setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
      if (interval !== null) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [timer, isResendDisabled]);

  const handleSendCode = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/users/send-verification-code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Jouer l'animation de la frame 0 à 35
        if (lottieRef.current) {
          lottieRef.current.playSegments([0, 35], true);
        }

        // Afficher le champ de saisie du code
        setCodeSent(true);

        // Réinitialiser le compteur
        setTimer(10);
        setIsResendDisabled(true);

        // Ajouter une notification
        addNotification(
          'Un code à 5 chiffres a été envoyé à votre adresse email.',
          'success'
        );
      } else {
        addNotification(data.message || 'Une erreur est survenue.', 'error');
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du code de vérification:", error);
      addNotification('Une erreur est survenue.', 'error');
    } finally {
      setLoading(false);
    }
  };


  const handleResendCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/users/send-verification-code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Réinitialiser le compteur
        setTimer(10);
        setIsResendDisabled(true);

        // Jouer l'animation de la frame 0 à 35
        if (lottieRef.current) {
          lottieRef.current.playSegments([0, 35], true);
        }

        // Ajouter une notification
        addNotification('Un nouveau code a été envoyé.', 'success');
      } else {
        addNotification(data.message || 'Une erreur est survenue.', 'error');
      }
    } catch (error) {
      console.error('Erreur lors du renvoi du code de vérification:', error);
      addNotification('Une erreur est survenue.', 'error');
    }
  };

  const handleVerifyCode = async () => {
    if (verificationCode === '') {
      addNotification('Veuillez renseigner le code.', 'error');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/users/verify-email`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ code: verificationCode }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        addNotification(data.message, 'success');
        // Mettre à jour le contexte utilisateur pour refléter que l'email est vérifié
        setUser((prevUser: User | null) => ({
          ...(prevUser as User),
          verifiedUser: true,
        }));

        // Rediriger vers la page suivante
        navigate('/mes-dossiers', { replace: true });
      } else {
        addNotification(data.message || 'Une erreur est survenue.', 'error');
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du code:', error);
      addNotification('Une erreur est survenue.', 'error');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500 px-4 relative">
      {/* Notifications */}
      <div className="fixed top-4 right-4 z-50">
        <AnimatePresence initial={false}>
          {notifications.map((notification) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.5 }}
              className={`mb-4 p-4 rounded shadow-lg text-sm text-white ${notification.type === 'success' ? 'bg-mcdm-secondary-400 dark:bg-mcdm-secondary-500' : 'bg-red-500 dark:bg-red-600'
                }`}
            >
              <div className="flex justify-between items-center">
                <div>{notification.message}</div>
                <button
                  onClick={() => removeNotification(notification.id)}
                  className="ml-4 focus:outline-none"
                >
                  &times;
                </button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <div className="bg-white dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-lg px-8 md:px-16 pt-6 pb-10 mb-4 max-w-md w-full">
        <div className="text-center mb-6">
          {/* Animation Lottie avec inversion en mode sombre */}
          <div className="flex justify-center">
            <div className="dark:invert">
              <Lottie
                lottieRef={lottieRef}
                animationData={animationData}
                loop={false}
                autoplay={false}
                style={{ width: 110, height: 110 }}
              />
            </div>
          </div>
          <h2 className="text-2xl mt-4 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
            Vérifiez votre adresse mail
          </h2>
        </div>
        <p className="text-center mb-2 text-mcdm-third-500 dark:text-mcdm-primary-200">
          Afin d'utiliser la plateforme, vérifions ensemble votre adresse mail :
        </p>
        <p className="text-center mb-6 font-semibold text-mcdm-third-500 dark:text-mcdm-primary-100">
          {user?.email}
        </p>
        <div className="flex flex-col items-center">
          {!codeSent ? (
            <button
              onClick={handleSendCode}
              disabled={loading}
              className={`${loading
                  ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed'
                  : 'bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500'
                } text-white dark:text-mcdm-third-400 py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 dark:focus:ring-mcdm-secondary-500 mb-4 w-full`}
            >
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Patientez...
                </>
              ) : (
                'Recevoir un code'
              )}
            </button>
          ) : (
            <>
              <input
                type="text"
                placeholder="Entrez le code à 5 chiffres"
                value={verificationCode}
                onChange={(e) => {
                  const input = e.target.value.replace(/\D/g, '').slice(0, 5);
                  setVerificationCode(input);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleVerifyCode();
                  }
                }}
                className="
                  mb-4 w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 dark:focus:ring-mcdm-secondary-400 
                  text-[25px] tracking-[10px] text-mcdm-third-500 dark:text-mcdm-third-100 
                  dark:bg-mcdm-primary-100
                  placeholder:text-base placeholder:tracking-normal placeholder:text-gray-500 dark:placeholder:text-gray-400 placeholder:text-center
                "
                maxLength={5}
              />
              <button
                onClick={handleVerifyCode}
                className="bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500 text-white dark:text-mcdm-third-400 py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 dark:focus:ring-mcdm-secondary-500 mb-4 w-full"
              >
                Vérifier
              </button>
              <button
                onClick={handleResendCode}
                disabled={isResendDisabled}
                className={`${isResendDisabled
                    ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed'
                    : 'bg-mcdm-third-500 dark:bg-mcdm-third-300 hover:bg-mcdm-third-600 dark:hover:bg-mcdm-third-400'
                  } text-white dark:text-mcdm-third-500 py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-mcdm-secondary-300 dark:focus:ring-mcdm-secondary-500 mb-4 w-full`}
              >
                Recevoir un nouveau code {isResendDisabled && `(${timer}s)`}
              </button>
            </>
          )}
          <button
            onClick={() => {
              logout();
              navigate('/');
            }}
            className="text-mcdm-secondary-300 dark:text-mcdm-secondary-100 hover:text-mcdm-secondary-400 dark:hover:text-mcdm-secondary-200 text-sm focus:outline-none"
          >
            Mauvaise adresse mail ? Se déconnecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
