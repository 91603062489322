import React, { CSSProperties } from 'react';

interface TrashProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
}

const Trash: React.FC<TrashProps> = ({ fill = 'currentColor', secondaryfill, width = '19px', height = '19px', style }) => {
  const fillValue = fill || 'currentColor';
  const secondaryFillValue = secondaryfill || fillValue;

  return (
    <svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g fill={fillValue}>
        <defs />
        <path className="cls-2" d="M405.5 499.56c-13.81 0-25 11.19-25 25v263c0 13.81 11.19 25 25 25s25-11.19 25-25v-263c0-13.81-11.19-25-25-25z" fill={fillValue} strokeWidth="0px" />
        <path className="cls-2" d="M530.5 499.56c-13.81 0-25 11.19-25 25v263c0 13.81 11.19 25 25 25s25-11.19 25-25v-263c0-13.81-11.19-25-25-25z" fill={fillValue} strokeWidth="0px" />
        <path className="cls-2" d="M655.5 499.56c-13.81 0-25 11.19-25 25v263c0 13.81 11.19 25 25 25s25-11.19 25-25v-263c0-13.81-11.19-25-25-25z" fill={fillValue} strokeWidth="0px" />
        <path className="cls-1" d="M281 375.68h499V885.2c0 27.72-22.51 50.23-50.23 50.23H331.23c-27.72 0-50.23-22.51-50.23-50.23V375.68z" fill="none" stroke={fillValue} strokeMiterlimit="10" strokeWidth="50px" />
        <path className="cls-1" d="M304.59 249.56h450.82c47.79 0 86.59 38.8 86.59 86.59v19.94c0 10.2-8.28 18.47-18.47 18.47H236.47c-10.2 0-18.47-8.28-18.47-18.47v-19.94c0-47.79 38.8-86.59 86.59-86.59z" fill="none" stroke={fillValue} strokeMiterlimit="10" strokeWidth="50px" />
        <path className="cls-1" d="M406 249.56s-1.99-125 124.5-125 124.5 125 124.5 125" fill="none" stroke={fillValue} strokeMiterlimit="10" strokeWidth="50px" />
      </g>
    </svg>
  );
};

export default Trash;
