import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { login as loginService } from '../../../services/authService';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const successMessage = location.state?.message;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const data = await loginService(email, password);
      if (data.token) {
        auth.login(data.token, data.user);
        if (!data.user.verifiedUser) {
          navigate('/verifier-email');
        } else {
          navigate('/mes-dossiers');
        }
      }
    } catch (error) {
      console.error('Erreur de connexion', error);
      setError('Identifiants incorrects.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500">
      <form onSubmit={handleSubmit} className="bg-white dark:bg-mcdm-third-400 shadow-sm shadow-inner rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl mb-6 text-center font-400 font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
          Connectez-vous
        </h2>

        {successMessage && (
          <div className="mb-4 p-3 bg-mcdm-secondary-100 dark:bg-mcdm-secondary-300 border border-mcdm-secondary-200 dark:border-mcdm-secondary-400 text-mcdm-secondary-400 dark:text-white rounded text-sm">
            {successMessage}
          </div>
        )}

        <div className="mb-4">
          <label className="text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="text-mcdm-third-500 dark:text-mcdm-primary-200 text-sm" htmlFor="password">
            Mot de passe
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-mcdm-third-500 dark:text-mcdm-third-100 dark:bg-mcdm-primary-100 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {error && <p className="text-red-500 text-xs italic mb-4 text-center">{error}</p>}

        <div className="flex flex-col items-center">
          <button
            className={`w-full bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 text-white dark:text-mcdm-third-400 py-2 px-4 rounded shadow-inner focus:outline-none focus:shadow-outline mb-4 ${isLoading
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500'
              }`}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Connexion...
              </>
            ) : (
              'Connexion'
            )}
          </button>

          <div className="flex flex-col items-center space-y-2 w-full">
            <Link
              to="/mot-de-passe-oublie"
              className="text-sm text-mcdm-secondary-300 dark:text-mcdm-secondary-100 hover:text-mcdm-secondary-400 dark:hover:text-mcdm-secondary-200"
            >
              Mot de passe oublié ?
            </Link>
            <Link
              to="/register"
              className="text-sm text-mcdm-secondary-300 dark:text-mcdm-secondary-100 hover:text-mcdm-secondary-400 dark:hover:text-mcdm-secondary-200"
            >
              Pas de compte ? S'inscrire
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;