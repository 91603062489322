import React, { CSSProperties } from 'react';

interface ChevronLeftProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const ChevronLeft: React.FC<ChevronLeftProps> = ({ fill = 'currentColor', secondaryfill, width = '100%', height = '100%', style, className }) => {
  return (
    <svg className={className} height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g fill={fill}>
        <defs />
        <path className="cls-1" d="M655 280L405 530l250 250" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px" />
      </g>
    </svg>
  );
};

export default ChevronLeft;
