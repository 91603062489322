import React from 'react';

function FloppyDisk(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<defs/>
		<path className="cls-1" d="M817.89 869H296.04c-28.24 0-51.13-22.89-51.13-51.13V296.13c0-28.24 22.89-51.13 51.13-51.13h396.2c13.63 0 26.7 5.44 36.3 15.12l126.44 127.45a51.098 51.098 0 0 1 14.83 36.11l-.79 394.3c-.06 28.2-22.93 51.03-51.13 51.03z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
		<path className="cls-1" d="M402.16 245h234.62v156H402.16z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
		<path className="cls-1" d="M368.82 682H745v187H368.82z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
	</g>
</svg>
	);
};

export default FloppyDisk;