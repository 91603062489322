import React from 'react';
import { useCurrentChapter } from '../contexts/CurrentChapterContext';
import { useMacadamia } from '../contexts/MacadamiaContext';
import { MobileSidebarProvider } from '../contexts/MobileSidebarContext';
import { useViewport } from './hooks/useViewport';
import useChapterManagement from './hooks/useChapterManagement';
import useDragAndDropManagement from './hooks/useDragAndDropManagement';
import useBlocksManagement from './hooks/useBlocksManagement';
import TopBarDossier from './TopBarDossier';
import DossierSidebar from './DossierSidebar';
import WidgetRenderer from './widgets/WidgetRenderer';
import { IDossier, ISuggestion } from './widgets/types';
import AddWidgetButton from './AddWidgetButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface DossierContentProps {
  dossierContent: IDossier | null;
  dossierId: string;
  title: string;
  setTitle: (title: string) => void;
  toggleSettingsModal: () => void;
  isSettingsModalOpen: boolean;
  lastSaved: Date;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  setDossierContent: React.Dispatch<React.SetStateAction<IDossier | null>>;
  isSaving: boolean;
  handleSave: () => void;
  suggestions: ISuggestion[];
  onAcceptSuggestion: (suggestion: ISuggestion) => void;
  onRejectSuggestion: (suggestion: ISuggestion) => void;
  setSuggestions: React.Dispatch<React.SetStateAction<ISuggestion[]>>;
  userRole: string | null;
}

const DossierContent: React.FC<DossierContentProps> = ({
  userRole,
  dossierContent,
  dossierId,
  title,
  setTitle,
  toggleSettingsModal,
  isSettingsModalOpen,
  lastSaved,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  setDossierContent,
  isSaving,
  handleSave,
  suggestions,
  onAcceptSuggestion,
  onRejectSuggestion,
  setSuggestions
}) => {
  const { currentChapterIndex, setCurrentChapterIndex } = useCurrentChapter();
  const { currentState } = useMacadamia();
  const { isMobile } = useViewport();
  const isEditing = currentState === 'editor';

  const { handleAddChapter, handleEditChapter, handleReorderChapters, handleDeleteChapter } = useChapterManagement({
    dossierContent,
    setDossierContent,
    setCurrentChapterIndex,
    currentChapterIndex,
    setHasUnsavedChanges,
  });

  const { handleBlockDragEnd } = useDragAndDropManagement({
    dossierContent,
    setDossierContent,
    currentChapterIndex,
    setHasUnsavedChanges,
  });

  const {
    handleBlockChange,
    handleBlockChangeLocal,
    handleDuplicateBlock,
    handleDeleteBlock,
    handleAddWidget,
  } = useBlocksManagement({
    dossierContent,
    setDossierContent,
    currentChapterIndex,
    setHasUnsavedChanges,
  });

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle);
  };

  const handleSuggestionCreated = (newSuggestion: ISuggestion) => {
    setSuggestions((prevSuggestions) => [...prevSuggestions, newSuggestion]);
  };

  if (!dossierContent) {
    return <div>Chargement...</div>;
  }

  if (dossierContent.content.length === 0) {
    return (
      <MobileSidebarProvider>
        <div className="min-h-screen bg-gray-100 flex flex-col">
          <TopBarDossier
            dossierId={dossierId}
            toggleSettingsModal={toggleSettingsModal}
            isSettingsModalOpen={isSettingsModalOpen}
            title={title}
            onTitleChange={handleTitleChange}
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving}
            handleSave={handleSave}
            lastSaved={lastSaved}
            userRole={userRole}
          />

          <div className="flex-grow flex justify-center items-center mt-16">
            <button onClick={handleAddChapter}>Ajouter un chapitre</button>
          </div>
        </div>
      </MobileSidebarProvider>
    );
  }

  const currentChapter = dossierContent.content[currentChapterIndex] || dossierContent.content[0];

  return (
    <MobileSidebarProvider>
      <div className="min-h-screen bg-mcdm-primary-200 dark:bg-mcdm-third-500 flex flex-col overflow-hidden">
        <DossierSidebar
          title={title}
          onTitleChange={handleTitleChange}
          isEditing={isEditing}
          userRole={userRole}
          dossierContent={dossierContent}
          onOpenSettingsModal={toggleSettingsModal}
          onAddChapter={handleAddChapter}
          onEditChapter={handleEditChapter}
          onDeleteChapter={handleDeleteChapter}
          onReorderChapters={handleReorderChapters}
        />

        <div className={`flex-1 flex flex-col ${!isMobile ? 'ml-64' : ''}`}>
          <TopBarDossier
            dossierId={dossierId}
            toggleSettingsModal={toggleSettingsModal}
            isSettingsModalOpen={isSettingsModalOpen}
            title={currentChapter.title}
            onTitleChange={(newTitle) => handleEditChapter(currentChapterIndex, newTitle)}
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving}
            handleSave={handleSave}
            lastSaved={lastSaved}
            userRole={userRole}
          />
          <div className="flex-grow pt-28 pb-96 relative">
            <div className="w-full max-w-3xl mx-auto px-4">
              {isEditing ? (
                <DragDropContext onDragEnd={handleBlockDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {currentChapter.sections.map((section, sectionIndex) => (
                          <Droppable key={sectionIndex} droppableId={`${sectionIndex}`}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {section.blocks.map((block, blockIndex) => (
                                  <Draggable key={block._id} draggableId={block._id} index={blockIndex}>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          pointerEvents: 'auto'
                                        }}
                                      >
                                        <div style={{ pointerEvents: 'auto' }}>
                                          <WidgetRenderer
                                            block={block}
                                            onChange={(newBlock) => handleBlockChangeLocal(sectionIndex, blockIndex, newBlock)}
                                            onDuplicate={() => handleDuplicateBlock(sectionIndex, blockIndex)}
                                            onDelete={() => handleDeleteBlock(sectionIndex, blockIndex)}
                                            suggestions={suggestions.filter(s => s.blocId === block._id)}
                                            onAcceptSuggestion={onAcceptSuggestion}
                                            onRejectSuggestion={onRejectSuggestion}
                                            dossierId={dossierId}
                                            onSuggestionCreated={handleSuggestionCreated}
                                            userRole={userRole}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div>
                  {currentChapter.sections.map((section, sectionIndex) => (
                    <div key={sectionIndex}>
                      {section.blocks.map((block, blockIndex) => (
                        <div key={block._id}>
                          <WidgetRenderer
                            block={block}
                            onChange={(newBlock) => handleBlockChangeLocal(sectionIndex, blockIndex, newBlock)}
                            onDuplicate={() => handleDuplicateBlock(sectionIndex, blockIndex)}
                            onDelete={() => handleDeleteBlock(sectionIndex, blockIndex)}
                            suggestions={suggestions.filter(s => s.blocId === block._id)}
                            onAcceptSuggestion={onAcceptSuggestion}
                            onRejectSuggestion={onRejectSuggestion}
                            dossierId={dossierId}
                            onSuggestionCreated={handleSuggestionCreated}
                            userRole={userRole}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {isEditing && (
              <AddWidgetButton onSelectWidget={handleAddWidget} />
            )}
          </div>
        </div>
      </div>
    </MobileSidebarProvider>
  );
};

export default DossierContent;