import { useState, useEffect } from 'react';

interface Resource {
    _id: string;
    titre: string;
    source: {
        nom: string;
        url: string;
    };
    halMetadata?: {
        abstract?: string;
        keywords?: string[];
        authors?: string[];
        publication?: {
            type: string;
            date: string;
        };
    };
}

export const useRessourcesRecommendations = (limit: number = 5) => {
    const [recommendations, setRecommendations] = useState<Resource[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchRecommendations = async () => {
            try {
                setLoading(true);
                setError(null);

                const token = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/recommendations-ressources?limit=${limit}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = await response.json();

                if (data.success) {
                    setRecommendations(data.data);
                } else {
                    setError('Erreur lors de la récupération des recommandations');
                }
            } catch (err) {
                setError('Erreur lors de la récupération des recommandations');
                console.error('Erreur useRessourcesRecommendations:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchRecommendations();
    }, [limit]);

    return { recommendations, loading, error };
};