import React, { useState } from 'react';
import YouTubeVideo from './YouTubeVideo';
import VimeoVideo from './VimeoVideo';
import SelfHostedVideo from './SelfHostedVideo';
import { IVideoBlock } from '../types';
import { useMacadamia } from '../../../contexts/MacadamiaContext';

import YouTubeIcon from '../../../icons/YouTube';
import VimeoIcon from '../../../icons/Vimeo';
import ServerIcon from '../../../icons/Server';

interface VideoWidgetProps {
    block: IVideoBlock;
    onChange: (block: IVideoBlock) => void;
    dossierId: string;
}

const VideoWidget: React.FC<VideoWidgetProps> = ({ block, onChange }) => {
    const { currentState } = useMacadamia();
    const [provider, setProvider] = useState<'youtube' | 'vimeo' | 'selfHosted'>(block.provider || 'youtube');

    const handleProviderChange = (newProvider: 'youtube' | 'vimeo' | 'selfHosted') => {
        setProvider(newProvider);
        onChange({ ...block, provider: newProvider, url: '' });
    };

    const handleUrlChange = (url: string) => {
        onChange({ ...block, url });
    };

    return (
        <div className="flex justify-center mt-4">
            {currentState === 'editor' && !provider ? (
                <div className="bg-white p-8 rounded-xl max-w-[540px] w-full text-center shadow-lg">
                    <h2 className="text-lg font-semibold mb-4">Sélectionnez le type de vidéo</h2>
                    <div className="grid grid-cols-3 gap-4">
                        <div
                            className="w-[140px] h-[80px] rounded-lg bg-white flex flex-col items-center justify-center cursor-pointer transition-all border border-[#ececec] hover:bg-gray-200 hover:shadow-lg"
                            onClick={() => handleProviderChange('youtube')}
                        >
                            <YouTubeIcon className="w-8 h-8 mb-2 text-red-500" />
                            <span className="text-sm">YouTube</span>
                        </div>
                        <div
                            className="w-[140px] h-[80px] rounded-lg bg-white flex flex-col items-center justify-center cursor-pointer transition-all border border-[#ececec] hover:bg-gray-200 hover:shadow-lg"
                            onClick={() => handleProviderChange('vimeo')}
                        >
                            <VimeoIcon className="w-8 h-8 mb-2 text-blue-500" />
                            <span className="text-sm">Vimeo</span>
                        </div>
                        <div
                            className="w-[140px] h-[80px] rounded-lg bg-white flex flex-col items-center justify-center cursor-pointer transition-all border border-[#ececec] hover:bg-gray-200 hover:shadow-lg"
                            onClick={() => handleProviderChange('selfHosted')}
                        >
                            <ServerIcon className="w-8 h-8 mb-2 text-gray-500" />
                            <span className="text-sm">Auto-hébergée</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    {provider === 'youtube' && (
                        <YouTubeVideo
                            url={block.url}
                            onUrlChange={handleUrlChange}
                            provider={provider}
                            onProviderChange={handleProviderChange}
                        />
                    )}
                    {provider === 'vimeo' && (
                        <VimeoVideo
                            url={block.url}
                            onUrlChange={handleUrlChange}
                            provider={provider}
                            onProviderChange={handleProviderChange}
                        />
                    )}
                    {provider === 'selfHosted' && (
                        <SelfHostedVideo
                            url={block.url}
                            onUrlChange={handleUrlChange}
                            provider={provider}
                            onProviderChange={handleProviderChange}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default VideoWidget;
