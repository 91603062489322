import React, { useState } from 'react';
import Plus from '../icons/Plus';
import Heart from '../icons/Heart';
import Image from '../icons/Image';
import Gif from '../icons/Gif';
import PlayCircle from '../icons/PlayCircle';
import Bars3BottomLeft from '../icons/Bars3BottomLeft';
import Title from '../icons/Title';
import ListBullet from '../icons/ListBullet';
import { motion, AnimatePresence } from 'framer-motion';

interface AddWidgetButtonProps {
    onSelectWidget: (widgetType: string) => void;
}

const AddWidgetButton: React.FC<AddWidgetButtonProps> = ({ onSelectWidget }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSelectWidget = (widgetType: string) => {
        onSelectWidget(widgetType);
        setIsMenuOpen(false);
    };

    const widgets = [
        { type: 'title', label: 'Titre' },
        { type: 'CompositeTextWidget', label: 'Texte' },
        { type: 'list', label: 'Liste' },
        { type: 'image', label: 'Image' },
        { type: 'summary', label: 'Sommaire' },
        { type: 'lawTexts', label: 'Textes de loi' },
        { type: 'gif', label: 'GIF' },
        { type: 'video', label: 'Vidéo' },
    ];

    return (
        <div className="relative flex justify-center mt-4">
            <motion.button
                className="bg-white text-mcdm-third-500 rounded-lg w-12 h-12 flex items-center justify-center shadow-md transform transition-transform duration-300 ease-in-out hover:scale-110 active:scale-90"
                onClick={handleToggleMenu}
                style={{
                    boxShadow: isMenuOpen ? '0 4px 14px rgba(0, 0, 0, 0.1)' : '0 2px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s ease-in-out',
                }}
            >
                <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: isMenuOpen ? 45 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <Plus className="w-4 h-4" />
                </motion.div>
            </motion.button>
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        className="absolute top-full mt-2 bg-white border rounded shadow-md p-4"
                    >
                        <div className="grid grid-cols-4 gap-4">
                            {widgets.map((widget) => (
                                <div
                                    key={widget.type}
                                    className="w-[140px] h-[80px] rounded-lg bg-white flex flex-col items-center justify-center cursor-pointer transition-all border border-[#ececec] hover:bg-gray-200 hover:shadow-lg"
                                    onClick={() => handleSelectWidget(widget.type)}
                                >
                                    {widget.type === 'title' ? (
                                        <Title className="w-8 h-8 mb-2" />
                                    ) : widget.type === 'image' ? (
                                        <Image className="w-8 h-8 mb-2" />
                                    ) : widget.type === 'CompositeTextWidget' ? (
                                        <Bars3BottomLeft className="w-8 h-8 mb-2" />
                                    ) : widget.type === 'list' ? (
                                        <ListBullet className="w-8 h-8 mb-2" />
                                    ) : widget.type === 'gif' ? (
                                        <Gif className="w-8 h-8 mb-2" />
                                    ) : widget.type === 'video' ? (
                                        <PlayCircle className="w-8 h-8 mb-2" />
                                    ) : (
                                        <Heart className="w-8 h-8 mb-2" />
                                    )}
                                    <span className="text-sm text-mcdm-third-500 dark:text-mcdm-third-500">{widget.label}</span>
                                </div>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AddWidgetButton;
