import { useState } from 'react';
import { IChapter, IDossier } from '../../components/widgets/types';
import { v4 as uuidv4 } from 'uuid';

interface UseChapterManagementProps {
  dossierContent: IDossier | null;
  setDossierContent: React.Dispatch<React.SetStateAction<IDossier | null>>;
  setCurrentChapterIndex: (index: number) => void;
  currentChapterIndex: number;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const useChapterManagement = ({
  dossierContent,
  setDossierContent,
  setCurrentChapterIndex,
  currentChapterIndex,
  setHasUnsavedChanges,
}: UseChapterManagementProps) => {
  const handleAddChapter = () => {
    if (!dossierContent) return;
    const newChapter: IChapter = {
      _id: uuidv4(),
      type: 'chapter',
      title: 'Nouveau chapitre',
      sections: []
    };
    const updatedContent = [...dossierContent.content, newChapter];
    setDossierContent((prevDossier) => {
      if (!prevDossier) return null;
      return { ...prevDossier, content: updatedContent };
    });
    setCurrentChapterIndex(updatedContent.length - 1);
    setHasUnsavedChanges(true);
  };

  const handleEditChapter = (index: number, newTitle: string) => {
    if (!dossierContent) return;
    const updatedContent = [...dossierContent.content];
    updatedContent[index].title = newTitle;
    setDossierContent((prevDossier) => {
      if (!prevDossier) return null;
      return { ...prevDossier, content: updatedContent };
    });
    setHasUnsavedChanges(true);
  };

  const handleReorderChapters = (updatedChapters: IChapter[]) => {
    setDossierContent((prevDossier) => {
      if (!prevDossier) return null;
      return { ...prevDossier, content: updatedChapters };
    });
    setHasUnsavedChanges(true);
  };  

  const handleDeleteChapter = (index: number) => {
    if (!dossierContent) return;
    const updatedContent = dossierContent.content.filter((_, i) => i !== index);
    setDossierContent((prevDossier) => {
      if (!prevDossier) return null;
      return { ...prevDossier, content: updatedContent };
    });
    let newChapterIndex = currentChapterIndex;
    if (currentChapterIndex >= updatedContent.length) {
      newChapterIndex = updatedContent.length - 1;
    } else if (currentChapterIndex === index) {
      newChapterIndex = Math.max(0, currentChapterIndex - 1);
    }
    setCurrentChapterIndex(newChapterIndex);
    setHasUnsavedChanges(true);
  };

  return {
    handleAddChapter,
    handleEditChapter,
    handleReorderChapters,
    handleDeleteChapter,
  };
};

export default useChapterManagement;
