import { Routes, Route, Navigate } from 'react-router-dom';

import { useSettings } from './context/SettingsContext';
import { useAuth } from './context/AuthContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { ThemeProvider } from './context/ThemeContext';
import { LegifranceAuthProvider } from './macadamia-core/contexts/LegifranceAuthContext';
import { EditorProvider } from './macadamia-core/contexts/EditorContext';
import { CurrentChapterProvider } from './macadamia-core/contexts/CurrentChapterContext';
import { MacadamiaProvider } from './macadamia-core/contexts/MacadamiaContext';

import LoadingSpinner from './components/widgets/LoadingSpinner';
import Maintenance from './components/Maintenance';
import { MaintenanceGuard } from './hooks/useMaintenanceMode';

import Login from './components/pages/auth/Login';
import Register from './components/pages/auth/Register';
import PlanSelectionPage from './components/pages/auth/PlanSelectionPage';
import ForgotPassword from './components/pages/auth/ForgotPassword';
import ResetPassword from './components/pages/auth/ResetPassword';
import VerifyEmailPage from './components/pages/auth/VerifyEmailPage';
import Profile from './components/pages/auth/Profile';

import MyFolders from './components/pages/folders/MyFolders';

import Jurisprudences from './components/pages/jurisprudences/Jurisprudences';

import DossierDetailsWrapper from './macadamia-core/components/DossierDetailsWrapper';

import Status from './components/Status';
import Callback from './components/LegifranceCallback';
import ProtectedRoute from './components/ProtectedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import PublicRoute from './components/PublicRoute';

// Composant spécifique pour les routes protégées nécessitant l'écosystème Macadamia
const MacadamiaRoutes = () => (
  <EditorProvider>
    <CurrentChapterProvider>
      <MacadamiaProvider>
        <LegifranceAuthProvider>
          <Route
            path="/mes-dossiers"
            element={
              <ProtectedRoute>
                <MyFolders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mes-dossiers/:id"
            element={
              <ProtectedRoute>
                <DossierDetailsWrapper />
              </ProtectedRoute>
            }
          />
        </LegifranceAuthProvider>
      </MacadamiaProvider>
    </CurrentChapterProvider>
  </EditorProvider>
);

function App() {
  const { maintenanceMode, isLoading } = useSettings();
  const { user } = useAuth();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (maintenanceMode && user?.role !== 'admin') {
    return <Maintenance />;
  }

  return (
    <ThemeProvider>
      <WebSocketProvider>
        <MaintenanceGuard>
          <Routes>
            {/* Routes publiques et d'authentification */}
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path="/mot-de-passe-oublie"
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/reinitialiser-mot-de-passe"
              element={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/verifier-email"
              element={
                <AuthenticatedRoute>
                  <VerifyEmailPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/choisir-plan"
              element={
                <AuthenticatedRoute>
                  <PlanSelectionPage />
                </AuthenticatedRoute>
              }
            />
            
            {/* Route de profil - n'a pas besoin de l'écosystème Macadamia */}
            <Route
              path="/mon-profil"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            
            {/* Route de jurisprudences - n'a pas besoin de l'écosystème Macadamia */}
            <Route
              path="/jurisprudences"
              element={
                <ProtectedRoute>
                  <Jurisprudences />
                </ProtectedRoute>
              }
            />
            
            {/* Routes spécifiques à Macadamia - Utilisons le composant spécialisé */}
            <Route
              path="/mes-dossiers"
              element={
                <ProtectedRoute>
                  <EditorProvider>
                    <CurrentChapterProvider>
                      <MacadamiaProvider>
                        <LegifranceAuthProvider>
                          <MyFolders />
                        </LegifranceAuthProvider>
                      </MacadamiaProvider>
                    </CurrentChapterProvider>
                  </EditorProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/mes-dossiers/:id"
              element={
                <ProtectedRoute>
                  <EditorProvider>
                    <CurrentChapterProvider>
                      <MacadamiaProvider>
                        <LegifranceAuthProvider>
                          <DossierDetailsWrapper />
                        </LegifranceAuthProvider>
                      </MacadamiaProvider>
                    </CurrentChapterProvider>
                  </EditorProvider>
                </ProtectedRoute>
              }
            />

            {/* Autres routes */}
            <Route path="/status" element={<Status />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="*" element={<Navigate to="/mes-dossiers" />} />
          </Routes>
        </MaintenanceGuard>
      </WebSocketProvider>
    </ThemeProvider>
  );
}

export default App;