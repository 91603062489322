import React from 'react';
import { useMacadamia } from './../contexts/MacadamiaContext';

const MacadamiaSwitcher: React.FC<{ className?: string }> = ({ className }) => {
    const { currentState, switchState } = useMacadamia();

    const toggleMode = () => {
        switchState(currentState === 'editor' ? 'reader' : 'editor');
    };

    const isChecked = currentState === 'editor';

    return (
        <div className={`switch-container ${className}`}>
            <span className="switch-label text-sm text-mcdm-third-500 dark:text-mcdm-primary-100">
                Lecture
            </span>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={toggleMode}
                    className="bg-mcdm-third-500 dark:bg-mcdm-primary-100 focus:ring-mcdm-secondary-400 focus:ring-offset-mcdm-third-500 dark:focus:ring-offset-mcdm-primary-100"
                />
                <span className="slider round bg-mcdm-third-500 dark:bg-mcdm-primary-100 shadow-sm dark:shadow-inner"></span>
            </label>
            <span className="switch-label text-sm text-mcdm-third-500 dark:text-mcdm-primary-100">
                Édition
            </span>
        </div>
    );
};

export default MacadamiaSwitcher;