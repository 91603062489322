import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ThemeSelector from '../components/widgets/ThemeSelector';
import NotificationBell from '../macadamia-core/components/NotificationBell';
import ProfilePictureManager from './ProfilePictureManager';

const TopBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleLogout = () => {
    logout();
    navigate('/login');
    setIsDropdownOpen(false);
  };

  const handleProfileClick = () => {
    navigate('/mon-profil');
    setIsDropdownOpen(false);
  };

  const handleJurisprudencesClick = () => {
    navigate('/jurisprudences');
    setIsDropdownOpen(false);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const menuItems = [
    { label: 'Mon profil', onClick: handleProfileClick },
    { label: 'Mon plan', onClick: () => { } },
    { label: 'Jurisprudences', onClick: handleJurisprudencesClick },
    { label: 'Invitations', onClick: () => { } },
    { label: 'Paramètres', onClick: () => { } }
  ];

  return (
    <div className="bg-mcdm-primary-200 dark:bg-mcdm-third-400 shadow fixed top-0 left-0 right-0 z-10">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="w-8 h-auto cursor-pointer"
                src="/logos/logo-macadamia-v2.png"
                alt="Logo Macadamia"
                onClick={handleLogoClick}
              />
            </div>
          </div>

          <div className="flex items-center">
            <ThemeSelector />
            <NotificationBell />

            <div className="ml-4 relative" ref={dropdownRef}>
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="focus:outline-none"
                style={{ marginTop: '5px' }}
              >
                <ProfilePictureManager
                  size="md"
                  className="border border-gray-200 dark:border-gray-600 shadow-sm transition-all duration-200 
                           rounded-full ring-2 ring-transparent hover:ring-gray-300 dark:hover:ring-gray-600 
                           hover:ring-offset-2 hover:ring-offset-mcdm-primary-200 dark:hover:ring-offset-mcdm-third-400"
                />
              </button>

              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    className="absolute right-0 mt-0 w-48 bg-white dark:bg-mcdm-third-400 
                             rounded-lg shadow-lg shadow-inner py-1 px-2 z-20"
                  >
                    <div className="px-4 mt-2 mb-0 text-sm font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                      {user?.name}
                    </div>
                    <hr className="my-0 mt-0 mx-4 border-gray-200 dark:border-gray-600" />

                    {menuItems.map((item, index) => (
                      <a
                        key={index}
                        onClick={item.onClick}
                        className="block px-4 py-2 mb-1 text-sm text-mcdm-third-500 dark:text-mcdm-primary-200 
                                 hover:bg-gray-100 dark:hover:bg-mcdm-third-300 cursor-pointer rounded-l"
                      >
                        {item.label}
                      </a>
                    ))}

                    <hr className="my-0 mx-4 border-gray-200 dark:border-gray-600" />
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 mb-1 text-sm text-red-600 dark:text-red-400 
                               hover:bg-gray-100 dark:hover:bg-mcdm-third-300 hover:text-red-700 
                               dark:hover:text-red-300 rounded-l"
                    >
                      Déconnexion
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;