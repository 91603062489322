import React, { useState } from 'react';
import { useMacadamia } from '../../../contexts/MacadamiaContext';
import { motion } from 'framer-motion';
import YouTubeIcon from '../../../icons/YouTube';
import VimeoIcon from '../../../icons/Vimeo';
import ServerIcon from '../../../icons/Server';

interface SelfHostedVideoProps {
    url: string;
    onUrlChange: (url: string) => void;
    provider: 'youtube' | 'vimeo' | 'selfHosted';
    onProviderChange: (newProvider: 'youtube' | 'vimeo' | 'selfHosted') => void;
}

const SelfHostedVideo: React.FC<SelfHostedVideoProps> = ({ url, onUrlChange, provider, onProviderChange }) => {
    const { currentState } = useMacadamia();
    const [showMenu, setShowMenu] = useState(false);

    // Sélectionne l'icône appropriée en fonction du fournisseur actuel
    const ProviderIcon = provider === 'youtube' ? YouTubeIcon : provider === 'vimeo' ? VimeoIcon : ServerIcon;

    return (
        <div className="mt-3 mb-3 w-full relative">
            {currentState === 'editor' && (
                <div className="relative flex items-center">
                    <input
                        type="text"
                        placeholder="Veuillez coller le lien de la vidéo"
                        value={url}
                        onChange={(e) => onUrlChange(e.target.value)}
                        className="w-full bg-gray-100 p-3 pl-12 border border-gray-300 text-gray-700 rounded"
                    />
                    <div className="absolute left-3 cursor-pointer" onClick={() => setShowMenu(!showMenu)}>
                        <ProviderIcon className="w-6 h-6 text-gray-500" />
                    </div>

                    {showMenu && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="absolute top-10 left-3 bg-white border border-gray-300 rounded shadow-lg z-10"
                        >
                            <div className="flex flex-col">
                                <div
                                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => { onProviderChange('youtube'); setShowMenu(false); }}
                                >
                                    <YouTubeIcon className="w-5 h-5 text-red-500 mr-2" />
                                    <span>YouTube</span>
                                </div>
                                <div
                                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => { onProviderChange('vimeo'); setShowMenu(false); }}
                                >
                                    <VimeoIcon className="w-5 h-5 text-blue-500 mr-2" />
                                    <span>Vimeo</span>
                                </div>
                                <div
                                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => { onProviderChange('selfHosted'); setShowMenu(false); }}
                                >
                                    <ServerIcon className="w-5 h-5 text-gray-500 mr-2" />
                                    <span>Auto-hébergée</span>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>
            )}

            {url && (
                <div className="video-preview mt-4">
                    <video className="w-[736px] h-[414px]" controls>
                        <source src={url} type="video/mp4" />
                        Votre navigateur ne supporte pas la lecture de vidéos.
                    </video>
                </div>
            )}
        </div>
    );
};

export default SelfHostedVideo;
