import React from 'react';
import { ICompositeTextWidget, ITextBlock, ISuggestion } from '../types';
import SubBlockEditor from './SubBlockEditor';

interface CompositeTextWidgetProps {
  widget: ICompositeTextWidget;
  onWidgetChange: (newWidget: ICompositeTextWidget) => void;
  mode: 'editor' | 'reader';
  dossierId: string;
  onSuggestionCreated: (newSuggestion: ISuggestion) => void;
  mongoId: string;
  userRole: string | null;
}

const CompositeTextWidget: React.FC<CompositeTextWidgetProps> = ({
  widget,
  onWidgetChange,
  mode,
  dossierId,
  onSuggestionCreated,
  mongoId,
  userRole
}) => {
  const handleTextBlockChange = (index: number, newTextBlock: ITextBlock) => {
    const newBlocks = [...widget.content.blocks];
    newBlocks[index] = newTextBlock;
    onWidgetChange({
      ...widget,
      content: {
        ...widget.content,
        blocks: newBlocks,
      },
    });
  };

  return (
    <div
      className={`composite-text-widget text-justify dark:text-mcdm-primary-100 ${
        mode === 'editor' ? 'border border-solid border-transparent hover:border-[#99cba6]' : ''
      }`}
      style={{ padding: '0px' }}
    >
      {widget.content.blocks.map((block, index) => (
        <SubBlockEditor
          key={index}
          textBlock={block}
          onTextBlockChange={(newTextBlock) => handleTextBlockChange(index, newTextBlock)}
          mode={mode}
          dossierId={dossierId}
          onSuggestionCreated={onSuggestionCreated}
          compositeBlockId={mongoId}
          userRole={userRole}
        />
      ))}
    </div>
  );
};

export default CompositeTextWidget;
