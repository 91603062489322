import React from 'react';
import { MacadamiaProvider } from '../contexts/MacadamiaContext';
import { CurrentChapterProvider } from '../contexts/CurrentChapterContext';
import DossierDetails from './DossierDetails';

const DossierDetailsWrapper: React.FC = () => {
  return (
    <MacadamiaProvider>
      <CurrentChapterProvider>
        <DossierDetails />
      </CurrentChapterProvider>
    </MacadamiaProvider>
  );
};

export default DossierDetailsWrapper;
