import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export interface User {
  id: string;
  profilePicture: string;
  name: string;
  email: string;
  role?: 'admin' | 'user';
  verifiedUser?: boolean;
  plan?: string;
  profilePictureUrl?: string;
  profilePictureTimestamp?: number;
}

interface AuthContextType {
  token: string | null;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  login: (token: string, user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  refreshProfilePicture: () => Promise<string | null>;
  getAuthorProfilePicture: (userId: string) => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Cache pour les URLs des photos de profil des auteurs
// Cette variable est à l'extérieur du composant pour persister entre les rendus
const authorProfilePictureCache: Record<string, {url: string, timestamp: number}> = {};

export const AuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const navigate = useNavigate();

  const login = (newToken: string, newUser: User) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('user', JSON.stringify(newUser));
    setToken(newToken);
    setUser(newUser);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    setUser(null);
    navigate('/login');
  };

  // Rafraîchir l'URL de la photo de profil utilisateur
  const refreshProfilePicture = async () => {
    if (!user?.profilePicture || !token) return null;
  
    const now = Date.now();
    // Réduire la durée de mise en cache à 5 minutes pour être plus proactif
    if (
      user.profilePictureUrl &&
      user.profilePictureTimestamp &&
      now - user.profilePictureTimestamp < 5 * 60 * 1000
    ) {
      // On évite de spammer l'API si on a déjà rafraîchi récemment
      return user.profilePictureUrl;
    }
  
    try {
      console.log("Rafraîchissement de l'URL de la photo de profil...");
  
      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/users/refresh-profile-picture`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!response.ok) {
        // Si 401 => on déclenche un logout
        if (response.status === 401) {
          logout();
          return null;
        }
  
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
  
      const data = await response.json();
  
      if (!data.profilePicture) {
        throw new Error("URL de la photo de profil non reçue du serveur");
      }
  
      setUser((prev) =>
        prev
          ? {
              ...prev,
              profilePictureUrl: data.profilePicture,
              profilePictureTimestamp: Date.now(),
            }
          : null
      );
  
      // Mettre à jour le stockage local
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...parsedUser,
            profilePictureUrl: data.profilePicture,
            profilePictureTimestamp: Date.now(),
          })
        );
      }
      
      return data.profilePicture;
    } catch (error) {
      console.error("Erreur lors du rafraîchissement de la photo de profil:", error);
      
      // Réinitialiser l'URL en cas d'erreur
      setUser((prev) =>
        prev
          ? {
              ...prev,
              profilePictureUrl: undefined,
              profilePictureTimestamp: undefined,
            }
          : null
      );
      
      return null;
    }
  };
  
  // Nouvelle fonction pour obtenir les photos de profil des auteurs
  const getAuthorProfilePicture = async (userId: string) => {
    if (!token) return null;
    
    const now = Date.now();
    
    // Vérifier si nous avons déjà une URL valide dans le cache
    if (
      authorProfilePictureCache[userId] &&
      now - authorProfilePictureCache[userId].timestamp < 13 * 60 * 1000 // 13 minutes (15 - 2)
    ) {
      return authorProfilePictureCache[userId].url;
    }
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/users/${userId}/profile-picture`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (!response.ok) {
        if (response.status === 401) {
          logout();
          return null;
        }
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.profilePictureUrl) {
        // Mettre en cache l'URL rafraîchie
        authorProfilePictureCache[userId] = {
          url: data.profilePictureUrl,
          timestamp: now
        };
        return data.profilePictureUrl;
      }
      
      return null;
    } catch (error) {
      console.error("Erreur lors de la récupération de la photo de profil de l'auteur:", error);
      return null;
    }
  };

  useEffect(() => {
    const handleAuthChange = (event: CustomEvent<any>) => {
      if (!event.detail.isAuthenticated) {
        logout();
      }
    };

    window.addEventListener('authChange', handleAuthChange as EventListener);
    
    // Effet pour gérer le retour de visibilité
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshProfilePicture();
      }
    };
    
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('authChange', handleAuthChange as EventListener);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Rafraîchissement initial
  useEffect(() => {
    if (user?.profilePicture) {
      refreshProfilePicture();
    }
    
    // Configuration d'un rafraîchissement périodique
    const refreshInterval = setInterval(() => {
      if (user?.profilePicture) {
        refreshProfilePicture();
      }
    }, 10 * 60 * 1000); // Rafraîchir toutes les 10 minutes
    
    return () => {
      clearInterval(refreshInterval);
    };
  }, [user?.profilePicture]);

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUser,
        login,
        logout,
        isAuthenticated: !!token,
        refreshProfilePicture,
        getAuthorProfilePicture,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};