import React, { useState } from 'react';
import { useMacadamia } from '../../../contexts/MacadamiaContext';
import { motion } from 'framer-motion';
import YouTubeIcon from '../../../icons/YouTube';
import VimeoIcon from '../../../icons/Vimeo';
import ServerIcon from '../../../icons/Server';

interface YouTubeVideoProps {
    url: string;
    onUrlChange: (url: string) => void;
    provider: 'youtube' | 'vimeo' | 'selfHosted';
    onProviderChange: (newProvider: 'youtube' | 'vimeo' | 'selfHosted') => void;
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ url, onUrlChange, provider, onProviderChange }) => {
    const { currentState } = useMacadamia();
    const [showMenu, setShowMenu] = useState(false);

    const ProviderIcon = provider === 'youtube' ? YouTubeIcon : provider === 'vimeo' ? VimeoIcon : ServerIcon;

    return (
        <div className="mt-3 mb-3 w-full relative">
            {currentState === 'editor' && (
                <>
                    <div className="relative flex items-center">
                        <input
                            type="text"
                            placeholder="Veuillez coller le lien de la vidéo"
                            value={url}
                            onChange={(e) => onUrlChange(e.target.value)}
                            className="w-full bg-gray-100 p-3 pl-12 border border-gray-300 text-gray-700 rounded"
                        />
                        <div className="absolute left-3 cursor-pointer" onClick={() => setShowMenu(!showMenu)}>
                            <ProviderIcon className="w-6 h-6 text-gray-500" />
                        </div>

                        {showMenu && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                className="absolute top-10 left-3 bg-white border border-gray-300 rounded shadow-lg z-10"
                            >
                                <div className="flex flex-col">
                                    <div
                                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => { onProviderChange('youtube'); setShowMenu(false); }}
                                    >
                                        <YouTubeIcon className="w-5 h-5 text-red-500 mr-2" />
                                        <span>YouTube</span>
                                    </div>
                                    <div
                                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => { onProviderChange('vimeo'); setShowMenu(false); }}
                                    >
                                        <VimeoIcon className="w-5 h-5 text-blue-500 mr-2" />
                                        <span>Vimeo</span>
                                    </div>
                                    <div
                                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => { onProviderChange('selfHosted'); setShowMenu(false); }}
                                    >
                                        <ServerIcon className="w-5 h-5 text-gray-500 mr-2" />
                                        <span>Auto-hébergée</span>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </div>

                    <div className="video-preview mt-4">
                        <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                            {url ? (
                                <iframe
                                    className="absolute top-0 left-0 w-full h-full"
                                    src={`https://www.youtube.com/embed/${extractYouTubeId(url)}`}
                                    frameBorder="0"
                                    allowFullScreen
                                    title="YouTube Video"
                                />
                            ) : (
                                <div className="absolute top-0 left-0 w-full h-full bg-gray-100 border-2 border-dashed border-gray-300 rounded flex items-center justify-center">
                                    <div className="text-center">
                                        <YouTubeIcon className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                                        <p className="text-gray-500">Collez le lien YouTube pour afficher la vidéo</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}

            {currentState !== 'editor' && url && (
                <div className="video-preview mt-4">
                    <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                        <iframe
                            className="absolute top-0 left-0 w-full h-full"
                            src={`https://www.youtube.com/embed/${extractYouTubeId(url)}`}
                            frameBorder="0"
                            allowFullScreen
                            title="YouTube Video"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const extractYouTubeId = (url: string): string => {
    const regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?|shorts)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regExp);
    return match && match[1] ? match[1] : '';
};

export default YouTubeVideo;