const API_URL = `${process.env.REACT_APP_URL_API_MACADAMIA}/users/`;

export const login = async (email: string, password: string) => {
  try {
    const response = await fetch(API_URL + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error('Erreur de réseau ou le serveur a retourné une erreur');
    }

    const data = await response.json();
    console.log('Données utilisateur reçues :', data.user);

    if (data.token && data.user) {
      // Solution temporaire : Ajouter l'email si non présent
      if (!data.user.email) {
        data.user.email = email;
      }
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('token', data.token);
      window.dispatchEvent(new Event('storageChange'));
      return { token: data.token, user: data.user };
    }

    return data;
  } catch (error) {
    console.error('Erreur lors de la connexion:', error);
    throw error;
  }
};

// Fonction pour s'inscrire
export const register = async ({ email, password, civility, firstName, lastName }: { email: string, password: string, civility: string, firstName: string, lastName: string }) => {
  try {
    const registerResponse = await fetch(API_URL + 'register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        civility,
        firstName,
        lastName,
      }),
    });

    if (!registerResponse.ok) {
      const errorData = await registerResponse.json();
      throw new Error(errorData.message || 'Erreur lors de la création du compte');
    }

    const registerData = await registerResponse.json();

    // Si l'inscription est réussie, connectez immédiatement l'utilisateur
    if (registerData.message === "Utilisateur créé avec succès") {
      const loginData = await login(email, password);
      return loginData; // Retournez les données de connexion
    }

    return registerData;
  } catch (error) {
    console.error('Erreur lors de la création du compte:', error);
    throw error;
  }
};

// Fonction pour se déconnecter
export const logout = () => {
  localStorage.removeItem('user');
  window.dispatchEvent(new CustomEvent('authChange', { detail: { isAuthenticated: false } }));
};

// Fonction pour demander la réinitialisation d'un mot de passe
export const requestPasswordReset = async (email: string) => {
  try {
    const response = await fetch(API_URL + 'request-password-reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erreur lors de la demande de réinitialisation');
    }

    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la demande de réinitialisation:', error);
    throw error;
  }
};

// Fonction pour réinitialiser le mot de passe
export const resetPassword = async (token: string, password: string) => {
  try {
    const response = await fetch(API_URL + 'reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erreur lors de la réinitialisation');
    }

    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la réinitialisation:', error);
    throw error;
  }
};