import React from 'react';
import { useJuridicalReferences } from '../contexts/JuridicalReferencesContext';

interface JuridicalReferencesCounterProps {
  className?: string;
  onClick?: () => void;
}

const JuridicalReferencesCounter: React.FC<JuridicalReferencesCounterProps> = ({ 
  className = "",
  onClick
}) => {
  const { totalReferences } = useJuridicalReferences();
  
  return (
    <div className={`flex items-center ${className}`}>
      <div 
        className="bg-mcdm-third-500 dark:bg-mcdm-primary-300 text-white dark:text-mcdm-third-500 px-3 py-1 rounded-md text-sm font-medium cursor-pointer hover:bg-mcdm-third-400 dark:hover:bg-mcdm-primary-400 transition-colors" 
        style={{ transform: 'translateY(-1px)' }}
        onClick={onClick}
        title="Voir toutes les références juridiques"
      >
        <span className="font-bold">{totalReferences}</span> références
      </div>
    </div>
  );
};

export default JuridicalReferencesCounter;