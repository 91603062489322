import React from 'react';

interface ExclamationCircleProps {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  secondaryfill?: string;
}

const ExclamationCircle: React.FC<ExclamationCircleProps> = ({
  className = '',
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  secondaryfill,
}) => {
  const finalSecondaryFill = secondaryfill || fill;

  return (
    <svg
      className={className}
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <circle
          cx="530"
          cy="530"
          fill="none"
          r="313"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <circle
          cx="530"
          cy="654.75"
          fill={finalSecondaryFill}
          r="31"
          strokeWidth="0px"
        />
        <path
          d="M530 374.25v187"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
      </g>
    </svg>
  );
};

export default ExclamationCircle;
