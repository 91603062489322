import { useAuth } from '../../../context/AuthContext';
import TopBar from '../../../layout/TopBar';
import ProfilePictureManager from '../../../layout/ProfilePictureManager';

const Profile = () => {
    const { user } = useAuth();

    return (
        <div className="min-h-screen bg-mcdm-primary-200 dark:bg-mcdm-third-500">
            <TopBar />
            <div className="pt-24 px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto bg-white dark:bg-mcdm-third-400 p-8 rounded-lg shadow-lg dark:shadow-inner">
                    <h1 className="text-xl font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100 mb-4">Mon Profil</h1>

                    <div className="flex items-center space-x-4">
                        <div className="relative">
                            <ProfilePictureManager
                                size="lg"
                                editable
                                className="rounded-full shadow-sm dark:shadow-inner hover:shadow-md dark:hover:shadow-md transition-all duration-200"
                            />
                        </div>
                        <div>
                            <p className="text-lg font-medium text-gray-900 dark:text-mcdm-primary-200">{user?.name}</p>
                            <p className="text-sm text-gray-500 dark:text-mcdm-primary-100">{user?.email}</p>
                        </div>
                    </div>

                    <div className="mt-6">
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-mcdm-primary-200">Informations supplémentaires</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;