import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useCurrentChapter } from '../contexts/CurrentChapterContext';
import { JuridicalReferencesProvider } from '../contexts/JuridicalReferencesContext';
import DossierContent from './DossierContent';
import { IDossier } from './widgets/types';
import { ObjectId } from 'bson';
import DossierSettingsModal from './DossierSettingsModal';
import { useSuggestions } from './hooks/useSuggestions';
import { useLegifranceTokenManagement } from './hooks/useLegifranceTokenManagement';
import { useWebSocket } from '../../context/WebSocketContext';
import InvitationPrompt from './InvitationPrompt';

const Spinner: React.FC = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
  </div>
);

const DossierDetails: React.FC = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const [dossierContent, setDossierContent] = useState<IDossier | null>(null);
  const [unauthorized, setUnauthorized] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [settingsModalTab, setSettingsModalTab] = useState<'Général' | 'Auteurs'>('Général');
  const { token } = useAuth();
  const [title, setTitle] = useState('');
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { currentChapterIndex } = useCurrentChapter();
  const { isTokenValid, legifranceToken, checkAndRefreshToken } = useLegifranceTokenManagement();
  const navigate = useNavigate();
  const { socket } = useWebSocket();
  const [invitation, setInvitation] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${id}/role`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user role');
        }

        const data = await response.json();
        setUserRole(data.role);
      } catch (error) {
        console.error(error);
        setUserRole(null);
      }
    };

    if (id && token) {
      fetchUserRole();
    }
  }, [id, token]);

  // Provient du hook useSuggestions
  const {
    suggestions,
    fetchSuggestions,
    handleAcceptSuggestion,
    handleRejectSuggestion,
    setSuggestions
  } = useSuggestions(token ?? '', id ?? '', setDossierContent, setHasUnsavedChanges);


  const saveContent = useCallback(async () => {
    if (!dossierContent) return;

    setIsSaving(true);

    const updatedContent = dossierContent.content.map(chapter => ({
      ...chapter,
      _id: chapter._id.length !== 24 ? new ObjectId().toHexString() : chapter._id,
      sections: chapter.sections.map(section => ({
        ...section,
        _id: section._id.length !== 24 ? new ObjectId().toHexString() : section._id,
        blocks: section.blocks.map(block => ({
          ...block,
          _id: block._id.length !== 24 ? new ObjectId().toHexString() : block._id,
        })),
      })),
    }));

    const updatedDossier = {
      ...dossierContent,
      title,
      updatedAt: new Date().toISOString(),
      content: updatedContent,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedDossier),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde du dossier');
      }

      const savedDossier = await response.json();
      setDossierContent(savedDossier);
      const newLastSaved = new Date(savedDossier.updatedAt);
      setLastSaved(newLastSaved);
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du contenu:', error);
    } finally {
      setIsSaving(false);
    }
  }, [dossierContent, title, token, id]);

  const fetchDossierDetails = useCallback(async () => {
    if (!token || !id) {
      setUnauthorized(true);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        if (response.status === 403) {
          setUnauthorized(true);
          return;
        }
        throw new Error('Erreur lors de la récupération des détails du dossier');
      }

      const data = await response.json();
      if (data.invitation) {
        setInvitation(data.invitation);
        setDossierContent({ title: data.dossier.title } as IDossier);
      } else {
        setDossierContent(data);
        setTitle(data.title);
        setLastSaved(new Date(data.updatedAt));
      }
    } catch (error) {
      console.error('Erreur:', error);
    } finally {
      setIsLoading(false);
    }
  }, [token, id, navigate]);

  useEffect(() => {
    const initialize = async () => {
      if (!isTokenValid) {
        await checkAndRefreshToken();
      }
      await fetchDossierDetails();
      await fetchSuggestions();
    };

    initialize();
    // Écouter les nouvelles suggestions
    if (socket) {
      socket.on('newSuggestion', (newSuggestion) => {
        if (newSuggestion.dossierId === id) {
          setSuggestions(prevSuggestions => [...prevSuggestions, newSuggestion]);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off('newSuggestion');
      }
    };
  }, [checkAndRefreshToken, fetchDossierDetails, fetchSuggestions, isTokenValid, socket, id, setSuggestions]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasUnsavedChanges) {
        saveContent();
      }
    }, 20000); // 20 seconds

    return () => clearInterval(interval);
  }, [hasUnsavedChanges, saveContent]);

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle);
    setHasUnsavedChanges(true);
  };

  const toggleSettingsModal = (tab: 'Général' | 'Auteurs' = 'Général') => {
    setSettingsModalTab(tab);
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  const handleSaveSettings = (updatedDossier: IDossier) => {
    setDossierContent(updatedDossier);

    fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${updatedDossier._id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedDossier),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Dossier mis à jour:', data);
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour du dossier:', error);
      });
  };

  const handleAcceptInvitation = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/invitations/${invitation.token}/respond`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ status: 'accepted' }),
      });

      if (response.ok) {
        setInvitation(null);
        await fetchDossierDetails();
      } else {
        console.error('Erreur lors de l\'acceptation de l\'invitation');
      }
    } catch (error) {
      console.error('Erreur:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectInvitation = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/invitations/${invitation.token}/respond`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ status: 'rejected' }),
      });

      if (response.ok) {
        navigate('/dossiers');
      } else {
        console.error('Erreur lors du refus de l\'invitation');
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen bg-mcdm-primary-100 dark:bg-mcdm-third-500">
        <Spinner />
      </div>
    );
  }

  if (unauthorized) {
    return (
      <div className="min-h-screen bg-mcdm-beige-100 flex justify-center items-center">
        <div className="text-center p-4">
          <p className="text-lg font-semibold">Vous n'avez pas accès à ce dossier.</p>
          <button
            onClick={() => navigate('/dossiers')}
            className="mt-4 px-4 py-2 bg-mcdm-vert-300 text-white rounded hover:bg-mcdm-vert-400"
          >
            Retour à la liste des dossiers
          </button>
        </div>
      </div>
    );
  }

  if (invitation) {
    return (
      <InvitationPrompt
        dossierTitle={dossierContent?.title || ''}
        onAccept={handleAcceptInvitation}
        onReject={handleRejectInvitation}
      />
    );
  }

  if (!dossierContent || !lastSaved || !id) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <JuridicalReferencesProvider initialDossier={dossierContent}>
      <div data-dossier-id={id}>
        <DossierContent
          dossierContent={dossierContent}
          title={title}
          setTitle={handleTitleChange}
          toggleSettingsModal={toggleSettingsModal}
          isSettingsModalOpen={isSettingsModalOpen}
          lastSaved={lastSaved}
          hasUnsavedChanges={hasUnsavedChanges}
          setHasUnsavedChanges={setHasUnsavedChanges}
          setDossierContent={setDossierContent}
          isSaving={isSaving}
          handleSave={saveContent}
          suggestions={suggestions}
          onAcceptSuggestion={handleAcceptSuggestion}
          onRejectSuggestion={handleRejectSuggestion}
          dossierId={id}
          setSuggestions={setSuggestions}
          userRole={userRole}
        />
        <DossierSettingsModal
          isOpen={isSettingsModalOpen}
          onClose={toggleSettingsModal}
          dossier={dossierContent}
          onSave={handleSaveSettings}
          initialTab={settingsModalTab}
          userRole={userRole}
        />
      </div>
    </JuridicalReferencesProvider>
  );
};

export default DossierDetails;