import React, { CSSProperties } from 'react';

interface HeartFullProps {
  stroke?: string;
  fill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
}

const HeartFull: React.FC<HeartFullProps> = ({ stroke = 'currentColor', fill = 'currentColor', width = '100%', height = '100%', style }) => {
  return (
    <svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g fill={fill}>
        <path
          className="cls-1"
          d="M850 406.15c0-82.07-74.63-148.62-166.68-148.62-68.8 0-127.89 37.19-153.32 90.26-25.42-53.07-84.52-90.26-153.35-90.26-91.98 0-166.65 66.55-166.65 148.62 0 238.45 320 396.31 320 396.31S850 644.6 850 406.15z"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="50px"
        />
      </g>
    </svg>
  );
};

export default HeartFull;
