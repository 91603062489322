import React, { createContext, useState, useContext, useEffect } from 'react';

interface SettingsContextType {
    maintenanceMode: boolean;
    setMaintenanceMode: (mode: boolean) => void;
    allowRegistrations: boolean;
    isLoading: boolean;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const [allowRegistrations, setAllowRegistrations] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/settings/public`
                );

                if (response.ok) {
                    const data = await response.json();
                    setMaintenanceMode(data.maintenanceMode);
                    setAllowRegistrations(data.allowRegistrations);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des paramètres:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSettings();
    }, []);

    return (
        <SettingsContext.Provider value={{ 
            maintenanceMode, 
            setMaintenanceMode,
            allowRegistrations, 
            isLoading 
        }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings doit être utilisé à l\'intérieur d\'un SettingsProvider');
    }
    return context;
};