import React, { CSSProperties } from 'react';

interface BoldProps {
    stroke?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Bold: React.FC<BoldProps> = ({ stroke = 'currentColor', width = '100%', height = '100%', style, className }) => {
    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <g fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="100px">
                <path
                    d="m355,313h226.81c73.1,0,104.53,39.86,116.6,85.41,17.62,66.56-33.36,131.59-102.21,131.59h-241.19v-217Z"
                />
                <path
                    d="m355,747h226.81c73.1,0,104.53-39.86,116.6-85.41,17.62-66.56-33.36-131.59-102.21-131.59h-241.19v217Z"
                />
            </g>
        </svg>
    );
};

export default Bold;
