import React, { CSSProperties } from 'react';

interface BellProps {
    stroke?: string;
    fill?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
}

const Bell: React.FC<BellProps> = ({ stroke = 'currentColor', fill = 'none', width = '100%', height = '100%', style }) => {
    return (
        <svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g fill={fill}>
                <path
                    className="cls-1"
                    d="M634.58 724.43a856.63 856.63 0 0 0 141.55-29.04c25.55-7.54 36.53-37.56 22.16-59.99a321.95 321.95 0 0 1-50.88-174.17v-26.92c0-118.95-96.43-239.31-215.38-239.31S316.65 315.36 316.65 434.31v26.92a321.98 321.98 0 0 1-50.91 174.17c-14.45 22.53-3.23 52.52 22.45 60.08 45.63 13.44 92.84 23.2 141.28 28.95m205.11 0a870.813 870.813 0 0 1-205.11 0m205.11 0c18.14 56.64-13.06 117.27-69.71 135.41-56.64 18.14-117.27-13.06-135.41-69.71a107.743 107.743 0 0 1 0-65.7"
                    fill="none"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
            </g>
        </svg>
    );
};

export default Bell;
