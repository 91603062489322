import React, { CSSProperties } from 'react';

interface TitleProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Title: React.FC<TitleProps> = ({ fill = 'none', stroke = '#000000', width = '100%', height = '100%', style, className }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px">
        <polyline points="279.75 341.75 342.25 279.75 529.75 279.75 529.75 779.75" />
        <polyline points="780.25 342.25 717.75 280.25 530.25 280.25 530.25 780.25" />
        <line x1="405.25" y1="780.25" x2="654.25" y2="780.25" />
      </g>
    </svg>
  );
};

export default Title;
