import { useCallback } from 'react';
import { useLegifranceAuth } from '../../contexts/LegifranceAuthContext';

export const useLegifranceTokenManagement = () => {
    const { legifranceToken, isTokenValid, refreshLegifranceToken } = useLegifranceAuth();

    const checkAndRefreshToken = useCallback(async () => {
        if (!isTokenValid) {
            await refreshLegifranceToken();
        }
    }, [isTokenValid, refreshLegifranceToken]);

    return { isTokenValid, legifranceToken, checkAndRefreshToken };
};