import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../../../context/AuthContext';
import { CodeDisplay } from './CodeDisplay';
import { SectionDisplay } from './SectionDisplay';
import { ArticleDisplay } from './ArticleDisplay';

interface LegifranceModalProps {
    isVisible: boolean;
    onClose: () => void;
    isLoading: boolean;
    codeId?: string;
    sectionId?: string;
    articleId?: string;
}

interface LegifranceData {
    code?: {
        id: string;
        title: string;
        description?: string;
    };
    section?: {
        id: string;
        title: string;
        description?: string;
    };
    article?: {
        id: string;
        titre: string;
        texte: string;
        texteHtml: string;
        nature: string;
        dateDebut: string;
        dateFin: string;
        etat: string;
    };
}

interface ApiResponse {
    success: boolean;
    data: LegifranceData;
    error?: {
        message: string;
        details?: any;
        status: number;
    };
}

const LegifranceModal: React.FC<LegifranceModalProps> = ({
    isVisible,
    onClose,
    isLoading: externalLoading,
    codeId,
    sectionId,
    articleId,
}) => {
    const { token } = useAuth();
    const [data, setData] = useState<LegifranceData>({});
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);

    const determineEndpoint = useCallback(() => {
        const endpoint =
            articleId && sectionId && codeId
                ? 'legifrance/details-article'
                : sectionId && codeId
                    ? 'legifrance/details-section'
                    : codeId
                        ? 'legifrance/details-code'
                        : null;
        return endpoint;
    }, [articleId, sectionId, codeId]);

    const fetchData = useCallback(async () => {
        if (!token) {
            setError("Erreur d'authentification");
            return;
        }

        const endpoint = determineEndpoint();
        if (!endpoint) {
            setError('Paramètres invalides pour la requête');
            return;
        }

        try {
            const url = `${process.env.REACT_APP_URL_API_MACADAMIA}/${endpoint}`;
            const body = { codeId, sectionId, articleId };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                    errorData.error?.message || 'Erreur lors de la récupération des détails'
                );
            }

            const responseData = (await response.json()) as ApiResponse;
            if (!responseData.success) {
                throw new Error(
                    responseData.error?.message || 'Erreur lors de la récupération des données'
                );
            }
            return responseData.data;
        } catch (error) {
            console.error('Erreur complète:', error);
            throw error;
        }
    }, [token, determineEndpoint, codeId, sectionId, articleId]);

    useEffect(() => {
        let isMounted = true;

        const loadData = async () => {
            if (!isVisible || !token || hasAttemptedFetch) return;

            setIsLoading(true);
            setError(null);

            try {
                const result = await fetchData();
                if (isMounted) {
                    setData(result || {});
                    setHasAttemptedFetch(true);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error instanceof Error ? error.message : 'Une erreur est survenue');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        loadData();

        return () => {
            isMounted = false;
        };
    }, [isVisible, token, fetchData, hasAttemptedFetch]);

    useEffect(() => {
        if (!isVisible) {
            setData({});
            setError(null);
            setHasAttemptedFetch(false);
        }
    }, [isVisible]);

    const renderContent = () => {
        if (articleId && data.article) {
            return <ArticleDisplay article={data.article} />;
        }
        if (sectionId && data.code && data.section) {
            return (
                <>
                    <CodeDisplay code={data.code} />
                    <SectionDisplay section={data.section} />
                </>
            );
        }
        if (data.code) {
            return <CodeDisplay code={data.code} />;
        }
        return (
            <div className="text-gray-500 text-sm">
                Aucune donnée disponible
            </div>
        );
    };

    if (!isVisible) return null;

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-20 z-30 transition-opacity duration-300"
            onClick={onClose}
        >
            <div
                className="legifrance-sidebar fixed inset-y-0 right-0 w-[320px] bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-lg shadow-inner z-30 overflow-y-auto transform transition-transform duration-300"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="sticky pt-5 top-0 bg-mcdm-primary-100 dark:bg-mcdm-third-400 p-4 border-b border-mcdm-primary-200 dark:border-mcdm-primary-300 z-20 flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-mcdm-third-500 dark:text-mcdm-primary-100">
                        {articleId
                            ? 'Article Légifrance'
                            : sectionId
                                ? 'Section Légifrance'
                                : 'Code Légifrance'}
                    </h2>
                    <button
                        onClick={onClose}
                        className="text-mcdm-third-500 dark:text-mcdm-primary-100"
                        aria-label="Fermer"
                    >
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="p-4">
                    {isLoading ? (
                        <div className="animate-pulse space-y-4">
                            <div className="h-8 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-3/4"></div>
                            <div className="h-4 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-1/2"></div>
                            <div className="h-32 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full"></div>
                        </div>
                    ) : error ? (
                        <div className="text-red-600 text-sm p-4 bg-red-50 rounded">
                            {error}
                        </div>
                    ) : (
                        <>
                            <div className="space-y-4">
                                {data && data.article ? renderContent() : (
                                    <div className="text-gray-500 text-sm">
                                        Chargement des données...
                                    </div>
                                )}
                            </div>
                            {/* Bouton "Voir sur Legifrance" uniquement pour un article */}
                            {articleId && data.article && (
                                <div className="pt-4">
                                    <a
                                        href={`https://www.legifrance.gouv.fr/codes/article_lc/${data.article.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block text-center px-4 py-2 bg-mcdm-secondary-400 dark:bg-mcdm-primary-100 hover:bg-mcdm-secondary-500 text-white rounded"
                                    >
                                        Voir sur Legifrance
                                    </a>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LegifranceModal;
