import React, { useEffect, useState, useCallback, useMemo, memo, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import PlusCircle from '../icons/PlusCircle';

// Définition des types de props pour le composant Skeleton
interface SkeletonProps {
  width?: string;
  height?: string;
  circle?: boolean;
  style?: React.CSSProperties;
}

// Composant de squelette pour le chargement - mémoïsé
const Skeleton = memo(({
  width = '100%',
  height = '16px',
  circle = false,
  style = {}
}: SkeletonProps) => {
  const defaultStyle = {
    width,
    height,
    borderRadius: circle ? '50%' : '4px',
    margin: '4px 0',
    ...style,
  };

  return (
    <div
      style={defaultStyle}
      className={`skeleton bg-mcdm-third-200 dark:bg-mcdm-primary-100`}
    />
  );
});

Skeleton.displayName = 'Skeleton';

// Types
interface AuthorsBoxProps {
  dossierId: string;
  userRole: string | null;
  onOpenSettingsModal: (tab: string) => void;
}

interface Author {
  _id: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  email: string;
}

interface AuthorDetails {
  author: Author;
  coAuthors: Author[];
}

// Définition des types de props pour le composant ProfileImage
interface ProfileImageProps {
  src: string | null;
  alt: string;
  firstName: string;
  lastName: string;
  userId: string;
  onImageError: (id: string) => void;
}

// Cache pour mémoriser les détails des auteurs par dossierId
const authorsCache = new Map<string, {data: AuthorDetails, timestamp: number}>();

// Composant pour l'image de profil - mémoïsé
const ProfileImage = memo(({
  src, 
  alt, 
  firstName, 
  lastName, 
  userId,
  onImageError
}: ProfileImageProps) => {
  const getInitials = () => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };
  
  if (!src) {
    return (
      <div className="w-8 h-8 rounded-full bg-mcdm-third-200 text-white text-xs flex items-center justify-center mr-2">
        {getInitials()}
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className="w-8 h-8 rounded-full mr-2 object-cover"
      onError={() => onImageError(userId)}
    />
  );
});

ProfileImage.displayName = 'ProfileImage';

// Composant principal - mémoïsé
const AuthorsBox = memo(({
  dossierId,
  userRole,
  onOpenSettingsModal
}: AuthorsBoxProps) => {
  const { token } = useAuth();
  const [authorDetails, setAuthorDetails] = useState<AuthorDetails | null>(null);
  const [imageLoadErrors, setImageLoadErrors] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Référence pour vérifier si le composant est monté
  const isMountedRef = useRef(true);
  // Référence pour suivre la dernière requête
  const lastRequestRef = useRef<AbortController | null>(null);

  // Gestionnaire d'erreur d'image mémoïsé
  const handleImageError = useCallback((id: string) => {
    setImageLoadErrors(prev => {
      const newSet = new Set(prev);
      newSet.add(id);
      return newSet;
    });
  }, []);

  useEffect(() => {
    // Réinitialiser lors du montage
    isMountedRef.current = true;
    
    // Nettoyage lors du démontage
    return () => {
      isMountedRef.current = false;
      // Annuler toute requête en cours
      if (lastRequestRef.current) {
        lastRequestRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    const fetchAuthorDetails = async () => {
      // Annuler toute requête précédente
      if (lastRequestRef.current) {
        lastRequestRef.current.abort();
      }

      // Créer un nouveau contrôleur d'annulation
      const abortController = new AbortController();
      lastRequestRef.current = abortController;

      // Vérifiez si les données sont en cache et récentes (moins de 2 minutes)
      const now = Date.now();
      const cachedData = authorsCache.get(dossierId);
      const isCacheValid = cachedData && (now - cachedData.timestamp < 2 * 60 * 1000);

      if (isCacheValid) {
        setAuthorDetails(cachedData.data);
        setIsLoading(false);
        return;
      }

      try {
        if (!isMountedRef.current) return;
        
        setIsLoading(true);
        setError(null);

        const response = await fetch(
          `${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${dossierId}/authors`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            signal: abortController.signal
          }
        );

        if (!isMountedRef.current) return;

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails des auteurs');
        }

        const data = await response.json();
        
        if (!isMountedRef.current) return;
        
        // Mettre en cache les données
        authorsCache.set(dossierId, { data, timestamp: now });
        setAuthorDetails(data);
      } catch (error) {
        if (!isMountedRef.current) return;
        
        // Vérifier si c'est une erreur d'abandon
        const isAbortError = error instanceof DOMException && error.name === 'AbortError';
        
        if (!isAbortError) {
          console.error('Erreur:', error);
          setError(error instanceof Error ? error.message : 'Une erreur est survenue');
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }
    };

    if (dossierId && token) {
      // Utiliser un délai pour éviter les requêtes multiples en cascade
      const timeoutId = setTimeout(fetchAuthorDetails, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [dossierId, token]);

  // Mémoïsation du rendu pour les squelettes de chargement
  const loadingContent = useMemo(() => (
    <div className="pt-5 pl-5 pr-5">
      <h2 className="text-s font-semibold mb-2">Auteur Principal</h2>
      <div className="flex items-center">
        <Skeleton width="32px" height="32px" circle={true} />
        <Skeleton width="100px" style={{ marginLeft: '8px' }} />
      </div>

      <h2 className="text-s font-semibold mt-3 mb-2">Co-Auteurs</h2>
      <ul>
        {[1, 2].map((_, index) => (
          <li key={index} className='text-sm flex items-center mb-3'>
            <Skeleton width="32px" height="32px" circle={true} />
            <Skeleton width="100px" style={{ marginLeft: '8px' }} />
          </li>
        ))}
      </ul>
    </div>
  ), []);

  // État de chargement
  if (isLoading) {
    return loadingContent;
  }

  // État d'erreur
  if (error) {
    return (
      <div className="pt-5 pl-5 pr-5 text-red-500">
        <p>Erreur : {error}</p>
      </div>
    );
  }

  // Pas de données
  if (!authorDetails) {
    return (
      <div className="pt-5 pl-5 pr-5">
        <p>Aucune information disponible</p>
      </div>
    );
  }

  // Affichage normal
  return (
    <div className="pt-5 pl-5 pr-5">
      <div className="flex items-center mb-2">
        <h2 className="text-s font-semibold text-mcdm-third-500 dark:text-mcdm-primary-100">
          Auteur Principal
        </h2>
        {(userRole === 'author' || userRole === 'admin') && (
          <button
            onClick={() => onOpenSettingsModal('Auteurs')}
            aria-label="Ajouter Co-Auteur"
            className="ml-2 hover:opacity-80 transition-opacity duration-200"
          >
            <PlusCircle
              className="text-mcdm-secondary-400 dark:text-mcdm-secondary-400"
              width="20px"
              height="20px"
            />
          </button>
        )}
      </div>

      {/* Auteur Principal */}
      <div className="flex items-center">
        <ProfileImage
          src={authorDetails.author.profilePicture}
          alt={`${authorDetails.author.firstName} ${authorDetails.author.lastName}`}
          firstName={authorDetails.author.firstName}
          lastName={authorDetails.author.lastName}
          userId={authorDetails.author._id}
          onImageError={handleImageError}
        />
        <span className='text-sm text-mcdm-third-500 dark:text-mcdm-primary-100'>
          {authorDetails.author.firstName} {authorDetails.author.lastName}
        </span>
      </div>

      {/* Co-Auteurs */}
      {authorDetails.coAuthors.length > 0 && (
        <>
          <h2 className="text-s font-semibold text-mcdm-third-500 dark:text-mcdm-primary-100 mt-3 mb-2">
            Co-Auteurs
          </h2>
          <ul>
            {authorDetails.coAuthors.map((coAuthor) => (
              <li
                key={coAuthor._id}
                className='text-sm flex items-center mb-3 text-mcdm-third-500 dark:text-mcdm-primary-100'
              >
                <ProfileImage
                  src={coAuthor.profilePicture}
                  alt={`${coAuthor.firstName} ${coAuthor.lastName}`}
                  firstName={coAuthor.firstName}
                  lastName={coAuthor.lastName}
                  userId={coAuthor._id}
                  onImageError={handleImageError}
                />
                <span>
                  {coAuthor.firstName} {coAuthor.lastName}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
});

AuthorsBox.displayName = 'AuthorsBox';

export default AuthorsBox;