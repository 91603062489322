import React, { useState, useRef, useEffect } from 'react';
import { useViewport } from './hooks/useViewport';
import { useMobileSidebar } from '../contexts/MobileSidebarContext';
import { useJuridicalReferences } from '../contexts/JuridicalReferencesContext';
import ThemeSelector from '../../components/widgets/ThemeSelector';
import MacadamiaSwitcher from './MacadamiaSwitcher';
import AddFavorite from './AddFavorite';
import LastSavedDate from './LastSavedDate';
import JuridicalReferencesCounter from './JuridicalReferencesCounter';
import FloppyDisk from '../../macadamia-icons/floppy-disk';
import Cog6Tooth from '../../macadamia-icons/cog-6-tooth';
import ShareButton from './ShareButton';
import Bars3 from '../icons/Bars3';

interface TopBarDossierProps {
  toggleSettingsModal: (tab?: string) => void;
  isSettingsModalOpen: boolean;
  title: string;
  onTitleChange: (newTitle: string) => void;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
  handleSave: () => void;
  lastSaved: Date;
  dossierId: string;
  userRole: string | null;
  onShowReferences?: () => void; // Nouvelle prop pour gérer l'affichage du modal des références
}

// Composant pour le titre avec tooltip
const TruncatedTitle: React.FC<{ title: string, className?: string }> = ({ title, className = "" }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  
  // Vérifier si le titre est tronqué (s'il dépasse la largeur du conteneur)
  const isTitleTruncated = () => {
    const element = titleRef.current;
    return element ? element.scrollWidth > element.clientWidth : false;
  };

  // Ajuster la position du tooltip pour éviter qu'il ne sorte de l'écran
  useEffect(() => {
    if (showTooltip && tooltipRef.current) {
      const tooltipElement = tooltipRef.current;
      const viewportWidth = window.innerWidth;
      const tooltipRect = tooltipElement.getBoundingClientRect();
      
      if (tooltipRect.right > viewportWidth) {
        const overflow = tooltipRect.right - viewportWidth;
        tooltipElement.style.transform = `translate(calc(-50% - ${overflow + 20}px), 0)`;
      } else if (tooltipRect.left < 0) {
        const overflow = Math.abs(tooltipRect.left);
        tooltipElement.style.transform = `translate(calc(-50% + ${overflow + 20}px), 0)`;
      }
    }
  }, [showTooltip]);

  return (
    <div 
      className={`relative ${className}`}
      onMouseEnter={() => isTitleTruncated() && setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div 
        ref={titleRef}
        className="w-[300px] overflow-hidden text-ellipsis whitespace-nowrap"
      >
        {title}
      </div>
      
      {showTooltip && (
        <div 
          ref={tooltipRef}
          className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 
                    bg-white dark:bg-mcdm-third-500 text-mcdm-third-500 dark:text-white 
                    px-4 py-2 rounded shadow-lg z-50 text-sm whitespace-normal w-[300px]"
        >
          {title}
          <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 
                        border-8 border-transparent border-b-white dark:border-b-mcdm-third-500" />
        </div>
      )}
    </div>
  );
};

const TopBarDossier: React.FC<TopBarDossierProps> = ({
  toggleSettingsModal,
  title,
  hasUnsavedChanges,
  isSaving,
  handleSave,
  lastSaved,
  dossierId,
  userRole,
  onShowReferences
}) => {
  const { isMobile } = useViewport();
  const { toggleSidebar } = useMobileSidebar();
  const { totalReferences } = useJuridicalReferences();
  const canEdit = userRole === 'author' || userRole === 'co-author' || userRole === 'admin';

  if (isMobile) {
    return (
      <div className="fixed top-0 h-12 bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-md z-9 w-full flex items-center px-4">
        <div className="flex items-center w-1/2">
          <button
            onClick={toggleSidebar}
            className="mr-3 text-mcdm-third-400 dark:text-mcdm-secondary-400 flex-shrink-0"
          >
            <Bars3
              width="24px"
              height="24px"
            />
          </button>
          <TruncatedTitle 
            title={title} 
            className="text-sm font-normal text-mcdm-third-500 dark:text-mcdm-primary-100"
          />
        </div>

        <div className="flex items-center justify-end w-1/2 space-x-2">
          <ThemeSelector />
          <div className="transform -translate-y-px">
            <AddFavorite dossierId={dossierId} />
          </div>
          {canEdit && (
            <div
              className="p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer text-mcdm-third-500 dark:text-mcdm-secondary-400 dark:hover:text-mcdm-primary-100"
              onClick={() => toggleSettingsModal('Général')}
            >
              <Cog6Tooth width="24px" height="24px" />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="stickyheadbar top-0 flex-shrink-0 h-16 bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-md dark:shadow-inner z-30 w-full flex justify-between items-center px-4">
      <div className="flex justify-start" style={{ width: '40%' }}>
        <TruncatedTitle 
          title={title} 
          className="text-sm font-normal text-mcdm-third-500 dark:text-mcdm-primary-100 p-2"
        />
      </div>

      <div className="flex justify-end items-center" style={{ width: '60%' }}>
        <JuridicalReferencesCounter 
          className="mr-3" 
          onClick={onShowReferences} 
        />
        <LastSavedDate lastSaved={lastSaved} />
        <div className='mr-1'>
          <ThemeSelector />
        </div>
        <div className="mr-2 mt-1" style={{ transform: 'translateY(-1px)' }}>
          <AddFavorite dossierId={dossierId} />
        </div>
        {canEdit && (
          <>
            <div className="p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 mr-2 cursor-pointer hover:text-mcdm-third-500 dark:text-mcdm-secondary-400 dark:hover:text-mcdm-primary-100" onClick={() => toggleSettingsModal('Général')}>
              <Cog6Tooth width="24px" height="24px" />
            </div>
            <div className={`mr-2 cursor-pointer p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 ${hasUnsavedChanges ? 'text-mcdm-secondary-400 dark:text-mcdm-secondary-400' : 'text-mcdm-third-500 dark:text-mcdm-primary-100'} ${isSaving ? 'animate-spin' : ''}`}
              onClick={handleSave}
            >
              <FloppyDisk width="24px" height="24px" />
            </div>
          </>
        )}
        {canEdit && <ShareButton className="ml-2" onShareClick={() => toggleSettingsModal('Visibilité')} />}
        {canEdit && <MacadamiaSwitcher className="ml-2" />}
      </div>
    </div>
  );
};

export default TopBarDossier;