import React, { CSSProperties } from 'react';

interface CloneProps {
  fill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Clone: React.FC<CloneProps> = ({ fill = 'currentColor', width = '24px', height = '24px', style, className }) => {
  return (
    <svg 
      height={height} 
      width={width} 
      viewBox="0 0 24 24" 
      xmlns="http://www.w3.org/2000/svg"  
      style={style} 
      className={className}
    >
      <path 
        d="M19 2H9c-1.1 0-1.99.9-1.99 2L7 15c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 13H9V4h10v11zM5 6H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-1H6c-1.1 0-2-.9-2-2V6z" 
        fill={fill} 
      />
    </svg>
  );
};

export default Clone;
