import React, { useState, useEffect, useRef } from 'react';
import { IGifBlock } from './types';
import { useMacadamia } from '../../contexts/MacadamiaContext';
import { motion } from 'framer-motion';

interface GifWidgetProps {
  block: IGifBlock;
  onChange: (newBlock: IGifBlock) => void;
}

const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

const GifWidget: React.FC<GifWidgetProps> = ({ block, onChange }) => {
  const { currentState } = useMacadamia();
  const [searchTerm, setSearchTerm] = useState('');
  const [gifs, setGifs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const gifsPerPage = 25;
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Fonction pour rechercher les GIFs
  const fetchGifs = async (query: string, page: number) => {
    if (query.trim() === '') {
      setGifs([]);
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const offset = page * gifsPerPage;
      const response = await fetch(
        `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${encodeURIComponent(
          query
        )}&limit=${gifsPerPage}&offset=${offset}&rating=g&lang=fr`
      );

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des GIFs.');
      }

      const data = await response.json();
      setGifs(data.data);
    } catch (err) {
      console.error(err);
      setError('Erreur lors de la récupération des GIFs.');
    } finally {
      setIsLoading(false);
    }
  };

  // Gestion de la recherche avec débounce
  useEffect(() => {
    if (currentState !== 'editor') return;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchGifs(searchTerm, 0);
      setCurrentPage(0);
    }, 500); // Débounce de 500ms

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm, currentState]);

  // Gestion de la pagination
  useEffect(() => {
    if (currentState !== 'editor') return;

    if (currentPage === 0) return;
    fetchGifs(searchTerm, currentPage);
  }, [currentPage, searchTerm, currentState]);

  // Fonction pour sélectionner un GIF
  const handleGifSelect = (gifUrl: string) => {
    onChange({ ...block, src: gifUrl });
    setSearchTerm('');
    setGifs([]);
    setCurrentPage(0);
  };

  // Fonction pour aller à la page suivante
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Fonction pour aller à la page précédente
  const handlePrevPage = () => {
    if (currentPage > 0) setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="gif-widget p-4 border rounded-lg bg-gray-50">
      {currentState === 'editor' && !block.src && (
        <div className="mt-4 flex flex-col space-y-4 w-full">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border rounded w-full"
            placeholder="Rechercher un GIF..."
          />
        </div>
      )}

      <div className="flex flex-col items-center">
        {block.src ? (
          <img
            src={block.src}
            alt="GIF sélectionné"
            className="w-full h-auto rounded"
            loading="lazy"
          />
        ) : (
          searchTerm === '' &&
          gifs.length === 0 && (
            <div className="w-full h-48 flex items-center justify-center bg-gray-200 rounded">
              <span className="text-gray-500">Aucun GIF sélectionné</span>
            </div>
          )
        )}
      </div>

      {currentState === 'editor' && !block.src && (
        <div className="mt-4 flex flex-col space-y-4 w-full">
          {isLoading && <p className="text-center">Chargement...</p>}
          {error && <p className="text-center text-red-500">{error}</p>}
          {!isLoading && !error && gifs.length > 0 && (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {gifs.map((gif) => (
                <motion.div
                  key={gif.id}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="cursor-pointer"
                  onClick={() => handleGifSelect(gif.images.fixed_width.url)}
                >
                  <img
                    src={gif.images.fixed_width.url}
                    alt={gif.title}
                    className="w-full h-auto rounded"
                    loading="lazy"
                  />
                </motion.div>
              ))}
            </div>
          )}
          {!isLoading && gifs.length === gifsPerPage && (
            <div className="flex justify-between mt-4">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 0}
                className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition ${
                  currentPage === 0 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Précédent
              </button>
              <button
                onClick={handleNextPage}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
              >
                Suivant
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GifWidget;
