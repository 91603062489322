import React from 'react';

function PlusCircle(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 1060 1060" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<defs/>
		<circle className="cls-1" cx="530" cy="530" fill="none" r="313" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
		<path className="cls-1" d="M530 408v244m122-122H408" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
	</g>
</svg>
	);
};

export default PlusCircle;