import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';

interface EditorState {
  originalContent: any[];
  currentContent: any[];
  hasChanges: boolean;
}

interface EditorContextType {
  originalContent: any[];
  currentContent: any[];
  hasChanges: boolean;
  setCurrentContent: (content: any[]) => void;
  updateContent: (content: any[]) => void;
  saveContent: () => Promise<void>;
}

interface EditorProviderProps {
  children: ReactNode;
}

const EditorContext = createContext<EditorContextType | undefined>(undefined);

export const EditorProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const [editorState, setEditorState] = useState<EditorState>({
    originalContent: [],
    currentContent: [],
    hasChanges: false,
  });

  // Optimisation: useCallback pour éviter de recréer ces fonctions à chaque rendu
  const setCurrentContent = useCallback((content: any[]) => {
    setEditorState((prevState) => ({
      ...prevState,
      currentContent: content,
      originalContent: prevState.originalContent.length === 0 ? content : prevState.originalContent,
      hasChanges: JSON.stringify(prevState.originalContent) !== JSON.stringify(content),
    }));
  }, []);

  const updateContent = useCallback((content: any[]) => {
    setEditorState((prevState) => ({
      ...prevState,
      currentContent: content,
      hasChanges: JSON.stringify(prevState.originalContent) !== JSON.stringify(content),
    }));
  }, []);

  const saveContent = useCallback(async () => {
    if (editorState.currentContent.length > 0) {
      // Simulation d'une sauvegarde asynchrone
      await new Promise(resolve => setTimeout(resolve, 500));
      setEditorState(prevState => ({
        ...prevState,
        originalContent: prevState.currentContent,
        hasChanges: false,
      }));
    }
  }, [editorState.currentContent]);

  // Memoïsation de la valeur du contexte pour éviter des re-rendus inutiles
  const contextValue = useMemo(() => ({
    ...editorState,
    setCurrentContent,
    updateContent,
    saveContent
  }), [editorState, setCurrentContent, updateContent, saveContent]);

  return (
    <EditorContext.Provider value={contextValue}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = (): EditorContextType => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};