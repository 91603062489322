import React, { CSSProperties } from 'react';

interface UnderlineProps {
    stroke?: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

const Underline: React.FC<UnderlineProps> = ({ stroke = '#54408e', width = '100%', height = '100%', style, className }) => {
    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <g fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px">
                <path d="m345,309v280.25c0,31.52,14.93,61.58,41.17,81.39,28.11,21.23,73.38,41.44,143.83,41.44s114.14-19.52,142.35-40.34c27-19.93,42.65-50.38,42.65-82.52v-280.23" />
                <line x1="345" y1="809.5" x2="715" y2="809.5" />
            </g>
        </svg>
    );
};

export default Underline;
