import { v4 as uuidv4 } from 'uuid';
import { IDossier, IBlock, ICompositeTextWidget, ITitleBlock, ISection } from '../../components/widgets/types';

interface UseBlocksManagementProps {
  dossierContent: IDossier | null;
  setDossierContent: React.Dispatch<React.SetStateAction<IDossier | null>>;
  currentChapterIndex: number;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const useBlocksManagement = ({
  dossierContent,
  setDossierContent,
  currentChapterIndex,
  setHasUnsavedChanges,
}: UseBlocksManagementProps) => {

  const handleBlockChangeLocal = (sectionIndex: number, blockIndex: number, newBlock: IBlock | ICompositeTextWidget) => {
    if (!dossierContent) return;
    const updatedContent = [...dossierContent.content];
    const currentChapter = updatedContent[currentChapterIndex] || updatedContent[0];
    if (!currentChapter) {
      console.error('Aucun chapitre disponible');
      return;
    }
    const updatedSections = [...currentChapter.sections];
    if (!updatedSections[sectionIndex]) {
      console.error('Section index hors limites');
      return;
    }
    const updatedBlocks = [...updatedSections[sectionIndex].blocks];
    if (!updatedBlocks[blockIndex]) {
      console.error('Block index hors limites');
      return;
    }
  
    updatedBlocks[blockIndex] = newBlock;
    updatedSections[sectionIndex] = { ...updatedSections[sectionIndex], blocks: updatedBlocks };
    currentChapter.sections = updatedSections;
    updatedContent[currentChapterIndex] = currentChapter;
    setDossierContent({ ...dossierContent, content: updatedContent });
    setHasUnsavedChanges(true);
  };

const handleDuplicateBlock = (sectionIndex: number, blockIndex: number) => {
    if (!dossierContent) return;
    const updatedContent = [...dossierContent.content];
    const currentChapter = updatedContent[currentChapterIndex];
    const updatedSections = [...currentChapter.sections];
    const updatedBlocks = [...updatedSections[sectionIndex].blocks];

    const blockToDuplicate = { ...updatedBlocks[blockIndex] };

    blockToDuplicate._id = uuidv4();
    blockToDuplicate.id = uuidv4();

    if (blockToDuplicate.type === 'compositeText') {
      const compositeTextBlock = blockToDuplicate as ICompositeTextWidget;
      compositeTextBlock.content.blocks = compositeTextBlock.content.blocks.map(textBlock => ({
        ...textBlock,
        _id: uuidv4()
      }));
    }

    updatedBlocks.splice(blockIndex + 1, 0, blockToDuplicate);
    updatedSections[sectionIndex] = { ...updatedSections[sectionIndex], blocks: updatedBlocks };
    currentChapter.sections = updatedSections;
    updatedContent[currentChapterIndex] = currentChapter;
    setDossierContent({ ...dossierContent, content: updatedContent });
    setHasUnsavedChanges(true);
};

const handleBlockChange = (chapterIndex: number, sectionIndex: number, blockIndex: number, newBlock: IBlock) => {
    if (!dossierContent) return;

    const updatedContent = dossierContent.content.map((chapter, idx) => {
      if (idx === chapterIndex) {
        const updatedSections = chapter.sections.map((section, sIdx) => {
          if (sIdx === sectionIndex) {
            const updatedBlocks = section.blocks.map((block, bIdx) => {
              if (bIdx === blockIndex) {
                if (newBlock.type === 'compositeText') {
                  return {
                    ...newBlock,
                    content: {
                      blocks: [(newBlock as ICompositeTextWidget).content.blocks[0]]
                    }
                  };
                }
                return newBlock;
              }
              return block;
            });
            return { ...section, blocks: updatedBlocks };
          }
          return section;
        });
        return { ...chapter, sections: updatedSections };
      }
      return chapter;
    });

    setDossierContent({ ...dossierContent, content: updatedContent });
    setHasUnsavedChanges(true);
};

const handleDeleteBlock = (sectionIndex: number, blockIndex: number) => {
    if (!dossierContent) return;
    const updatedContent = [...dossierContent.content];
    const currentChapter = updatedContent[currentChapterIndex];
    const updatedSections = [...currentChapter.sections];
    const updatedBlocks = updatedSections[sectionIndex].blocks.filter((_, idx) => idx !== blockIndex);
    updatedSections[sectionIndex] = { ...updatedSections[sectionIndex], blocks: updatedBlocks };
    currentChapter.sections = updatedSections;
    updatedContent[currentChapterIndex] = currentChapter;
    setDossierContent({ ...dossierContent, content: updatedContent });
    setHasUnsavedChanges(true);
};

const handleAddWidget = (widgetType: string) => {
  if (!dossierContent) return;

  const newId = uuidv4();
  let newBlock: IBlock | ICompositeTextWidget;

  if (widgetType === 'CompositeTextWidget') {
      newBlock = {
          _id: newId,
          id: newId,
          type: 'compositeText',
          content: {
              blocks: [{ id: newId, _id: newId, rawText: '', entities: [] }]
          }
      } as ICompositeTextWidget;
  } else if (widgetType === 'title') {
      newBlock = {
          _id: newId,
          id: newId,
          type: 'title',
          content: 'Nouveau Titre',
      } as ITitleBlock;
  } else {
      newBlock = {
          _id: newId,
          id: newId,
          type: widgetType,
      };
  }

  const updatedContent = dossierContent.content.map((chapter, index) => {
      if (index === currentChapterIndex) {
          const updatedSections = [...chapter.sections];
          if (updatedSections.length === 0) {
              updatedSections.push({ type: 'text', blocks: [newBlock], _id: uuidv4() } as ISection);
          } else {
              updatedSections[0].blocks.push(newBlock);
          }
          return { ...chapter, sections: updatedSections };
      }
      return chapter;
  });

  setDossierContent((prevDossier) => {
      if (!prevDossier) return null;
      return { ...prevDossier, content: updatedContent };
  });

  setHasUnsavedChanges(true);
};

return {
    handleBlockChangeLocal,
    handleBlockChange,
    handleDuplicateBlock,
    handleDeleteBlock,
    handleAddWidget,
  };
};

export default useBlocksManagement;
