import React, { CSSProperties } from 'react';

interface ImageProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ fill = 'currentColor', secondaryfill, width = '100%', height = '100%', style, className }) => {
  const finalFill = fill || 'currentColor';
  const finalSecondaryFill = secondaryfill || finalFill;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 1060 1060"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g fill={finalFill}>
        <rect
          height="626"
          width="626"
          fill="none"
          rx="96.58"
          ry="96.58"
          stroke={finalFill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
          x="217"
          y="217"
        />
        <path
          d="M218.66 620S335 432.5 398 432.5c125 0 124 250 187 250s124-125 187.5-125 62.5.5 62.5.5"
          fill="none"
          stroke={finalFill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="50px"
        />
        <circle cx="648" cy="433" fill={finalSecondaryFill} r="62" />
      </g>
    </svg>
  );
};

export default Image;
