import React from 'react';

interface ArticleData {
    id: string;
    titre: string;
    texte: string;
    texteHtml: string;
    nature: string;
    dateDebut: string;
    dateFin: string;
    etat: string;
}

interface ArticleDisplayProps {
    article: ArticleData;
}

export const ArticleDisplay: React.FC<ArticleDisplayProps> = ({ article }) => {
    // Fonction de formatage de date
    const formatDate = (timestamp: string) => {
        if (!timestamp) return 'N/A';
        const date = new Date(parseInt(timestamp));
        return new Intl.DateTimeFormat('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date);
    };

    return (
        <div className="space-y-4">

            <div className="bg-mcdm-primary-100 dark:bg-mcdm-third-300 rounded-lg p-4">
                {article.texteHtml ? (
                    <div
                        className="text-sm prose max-w-none font-platypi text-base/6"
                        dangerouslySetInnerHTML={{ __html: article.texteHtml }}
                    />
                ) : (
                    <div className="text-sm whitespace-pre-wrap">{article.texte}</div>
                )}
            </div>
            <div className="bg-mcdm-primary-100 dark:bg-mcdm-third-400 p-4 rounded-lg">
                <div className="text-sm text-mcdm-third-500 dark:text-mcdm-primary-100 mb-1">
                    Article {article.titre}
                </div>
                <div className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mb-2">
                    ID: {article.id}
                </div>
                {article.nature && (
                    <div className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mb-2">
                        Nature: {article.nature}
                    </div>
                )}
                <div className="grid grid-cols-2 gap-4 text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mb-4">
                    <div>Date de début: {formatDate(article.dateDebut)}</div>
                    {article.dateFin && <div>Date de fin: {formatDate(article.dateFin)}</div>}
                </div>
                {article.etat && (
                    <div className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mb-4">
                        État: {article.etat}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArticleDisplay;
