import React, { CSSProperties } from 'react';

interface CheckCircleProps {
  fill?: string;
  secondaryfill?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const CheckCircle: React.FC<CheckCircleProps> = ({ fill = 'currentColor', secondaryfill, width = '100%', height = '100%', style, className }) => {
  return (
    <svg 
      className={className}
      height={height} 
      width={width} 
      viewBox="0 0 1060 1060" 
      xmlns="http://www.w3.org/2000/svg" 
      style={style}
    >
      <g fill={fill}>
        <defs/>
        <circle className="cls-1" cx="530" cy="530" fill="none" r="313" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
        <path className="cls-1" d="M443 530l75 75 150-150" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="50px"/>
      </g>
    </svg>
  );
};

export default CheckCircle;
