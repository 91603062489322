import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AuthorAvatar from './AuthorAvatar'; // Nouveau composant importé

interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string | null;
}

interface IAuthorDetails {
  author: IUser;
  coAuthors: IUser[];
}

interface IDossier {
  _id: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  settings: {
    visibility: string;
  };
  author: string;
  coAuthors: string[];
}

interface IFavorite {
  _id: string;
  dossier: IDossier;
  favoritedAt: Date;
}

interface FolderGridProps {
  dossiersUpdated: boolean;
  setDossiersUpdated: (updated: boolean) => void;
  selectedFilter: string;
}

const FolderGrid: React.FC<FolderGridProps> = ({ dossiersUpdated, setDossiersUpdated, selectedFilter }) => {
  const [dossiers, setDossiers] = useState<IDossier[]>([]);
  const [favoriteDossiers, setFavoriteDossiers] = useState<IDossier[]>([]);
  const [authorDetails, setAuthorDetails] = useState<Record<string, IAuthorDetails>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { token, user } = useAuth();
  const navigate = useNavigate();

  const fetchAuthorDetails = useCallback(async (dossierId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${dossierId}/authors`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Erreur lors de la récupération des détails des auteurs');

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erreur:', error);
      return null;
    }
  }, [token]);

  const fetchDossiers = useCallback(async () => {
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/user`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login');
          return;
        }
        throw new Error('Erreur réseau');
      }

      const data: IDossier[] = await response.json();
      setDossiers(data);

      // Récupérer les détails des auteurs pour chaque dossier
      const authorsDetails: Record<string, IAuthorDetails> = {};
      await Promise.all(
        data.map(async (dossier) => {
          const details = await fetchAuthorDetails(dossier._id);
          if (details) {
            authorsDetails[dossier._id] = details;
          }
        })
      );

      setAuthorDetails(authorsDetails);
      setDossiersUpdated(false);
    } catch (error) {
      console.error('Erreur:', error);
    }
  }, [token, navigate, setDossiersUpdated, fetchAuthorDetails]);

  const fetchFavoriteDossiers = useCallback(async () => {
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/favorites/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login');
          return;
        }
        throw new Error('Erreur réseau');
      }

      const data: IFavorite[] = await response.json();
      const favoriteDossiersData = data.map(fav => fav.dossier);
      setFavoriteDossiers(favoriteDossiersData);
    } catch (error) {
      console.error('Erreur:', error);
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchDossiers(), fetchFavoriteDossiers()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchDossiers, fetchFavoriteDossiers, dossiersUpdated]);

  // Ajoutez ce nouvel effet pour rafraîchir les données lorsque l'utilisateur revient à l'onglet
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Récupérer les données lors du retour sur l'onglet
        fetchDossiers();
        fetchFavoriteDossiers();
      }
    };
    
    window.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchDossiers, fetchFavoriteDossiers]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const getVisibilityBadge = (visibility: string) => {
    const baseClasses = "text-xs px-2 py-1 rounded-full font-medium";

    switch (visibility) {
      case 'public':
        return (
          <span className={`${baseClasses} bg-mcdm-green-300 text-white`}>
            Public
          </span>
        );
      case 'private':
        return (
          <span className={`${baseClasses} bg-mcdm-secondary-300 text-white`}>
            Privé
          </span>
        );
      default:
        return (
          <span className={`${baseClasses} bg-mcdm-primary-400 text-white`}>
            Sur demande
          </span>
        );
    }
  };

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length <= maxLength) return description;
    return `${description.substring(0, maxLength)}(...)`;
  };

  // Composant Tooltip conservé pour d'autres usages dans FolderGrid
  const Tooltip: React.FC<{
    content: string;
    children: React.ReactNode;
  }> = ({ content, children }) => {
    return (
      <div className="relative group">
        {children}
        <div className="absolute invisible opacity-0 group-hover:visible group-hover:opacity-100 
                        bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1
                        bg-mcdm-third-500 text-white text-xs rounded whitespace-nowrap
                        transition-all duration-200 z-10">
          {content}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 
                         border-4 border-transparent border-t-mcdm-third-500" />
        </div>
      </div>
    );
  };

  const renderDossierCard = (dossier: IDossier) => {
    const authors = authorDetails[dossier._id];
    const isAuthor = user?.id === authors?.author._id;

    return (
      <div
        key={dossier._id}
        onClick={() => navigate(`/mes-dossiers/${dossier._id}`)}
        className="bg-mcdm-primary-200 dark:bg-mcdm-third-400 rounded-lg shadow-sm hover:shadow-md 
                   transition-all duration-300 cursor-pointer p-4 border border-transparent 
                   hover:border-mcdm-secondary-300 dark:hover:border-mcdm-secondary-400"
      >
        <div className="mb-2">
          {getVisibilityBadge(dossier.settings.visibility)}
        </div>

        {/* Titre */}
        <h2 className="font-bold text-mcdm-third-500 dark:text-mcdm-primary-100 font-platypi mb-2">
          {dossier.title}
        </h2>

        {/* Description */}
        <p className="text-mcdm-third-400 dark:text-mcdm-primary-200 text-sm mb-3">
          {truncateDescription(dossier.description, 100)}
        </p>

        {/* Dates sur une seule ligne */}
        <div className="text-xs text-mcdm-third-300 dark:text-mcdm-primary-300 mb-3">
          Créé le {formatDate(dossier.createdAt)} - modifié le {formatDate(dossier.updatedAt)}
        </div>

        {/* Section auteurs */}
        {authors && (
          <div>
            <div className="flex items-center">
              {/* Utilisation du nouveau composant AuthorAvatar */}
              <AuthorAvatar user={authors.author} className="mr-2" />
              <span className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200">
                {isAuthor ? 'Vous êtes l\'auteur' : `Par ${authors.author.firstName} ${authors.author.lastName}`}
              </span>
            </div>

            {authors.coAuthors.length > 0 && (
              <div className="mt-2 flex items-center">
                <span className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mr-2">
                  Co-auteurs:
                </span>
                <div className="flex -space-x-2">
                  {authors.coAuthors.map((coAuthor) => (
                    /* Utilisation du nouveau composant AuthorAvatar */
                    <AuthorAvatar
                      key={coAuthor._id}
                      user={coAuthor}
                      className="border-2 border-white dark:border-mcdm-third-400"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {[...Array(8)].map((_, i) => (
          <div
            key={i}
            className="bg-mcdm-primary-200 dark:bg-mcdm-third-300 p-6 rounded-lg 
                     shadow-md h-48 animate-pulse"
          />
        ))}
      </div>
    );
  }

  const combinedDossiers = [...dossiers];
  favoriteDossiers.forEach(fav => {
    if (!combinedDossiers.some(dossier => dossier._id === fav._id)) {
      combinedDossiers.push(fav);
    }
  });

  let filteredDossiers = combinedDossiers;

  if (selectedFilter === 'Favoris') {
    filteredDossiers = favoriteDossiers;
  }

  // Tri par date de dernière modification (updatedAt) en ordre décroissant :
  filteredDossiers = filteredDossiers.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {filteredDossiers.length > 0 ? (
        filteredDossiers.map((dossier) => renderDossierCard(dossier))
      ) : (
        <div className="col-span-full text-center text-mcdm-third-500 dark:text-mcdm-primary-200">
          Aucun dossier trouvé.
        </div>
      )}
    </div>
  );
};

export default FolderGrid;