import React from 'react';
import Share from './../icons/Share';

interface ShareButtonProps {
  className?: string;
  onShareClick: () => void;
}

const ShareButton: React.FC<ShareButtonProps> = ({ className, onShareClick }) => {
  return (
    <button
      onClick={onShareClick}
      className={`flex items-center mr-3 pt-2 pb-2 pl-3 pr-3 bg-mcdm-green-400 dark:bg-mcdm-green-400 text-white dark:text-mcdm-primary-100 text-sm rounded-full hover:bg-mcdm-green-500 dark:hover:bg-mcdm-green-500 ${className}`}
    >
      <Share width="16px" height="16px" className="mr-2" />
      Partager
    </button>
  );
};

export default ShareButton;