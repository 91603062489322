import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import Heart from './../icons/Heart';
import HeartFull from './../icons/HeartFull';

interface AddFavoriteProps {
  dossierId: string;
}

const AddFavorite: React.FC<AddFavoriteProps> = ({ dossierId }) => {
  const { token } = useAuth();
  const { isDarkMode, colors } = useTheme();
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfFavorite = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/favorites/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const favorites = await response.json();
          const isFavorited = favorites.some((fav: any) => fav.dossier._id === dossierId);
          setIsFavorite(isFavorited);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du statut de favori:', error);
      } finally {
        setLoading(false);
      }
    };
    checkIfFavorite();
  }, [dossierId, token]);

  const toggleFavorite = async () => {
    try {
      let response;
      if (isFavorite) {
        response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/favorites/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ dossierId }),
        });
      } else {
        response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/favorites/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ dossierId }),
        });
      }
      if (response.ok) {
        setIsFavorite((prev) => !prev);
      } else {
        console.error('Erreur lors du changement du statut de favori');
      }
    } catch (error) {
      console.error('Erreur lors du changement du statut de favori:', error);
    }
  };

  if (loading) {
    return (
      <div className="w-7 h-7 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
    );
  }

  const getHeartStyles = () => {
    if (isFavorite) {
      return {
        fill: colors.secondary[400],
        stroke: colors.secondary[400]
      };
    }
    
    return {
      fill: 'none',
      stroke: colors.secondary[400]
    };
  };

  const { fill, stroke } = getHeartStyles();

  return (
    <button 
      onClick={toggleFavorite}
      aria-label={isFavorite ? "Retirer des favoris" : "Ajouter aux favoris"}
      className="p-1 rounded-full hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 transition-colors focus:outline-none"
    >
      {isFavorite ? (
        <HeartFull 
          fill={fill}
          stroke={stroke}
          width="24px"
          height="24px"
          style={{ transition: 'all 0.2s ease-in-out' }}
        />
      ) : (
        <Heart
          fill={fill}
          stroke={stroke}
          width="24px"
          height="24px"  
          style={{ transition: 'all 0.2s ease-in-out' }}
        />
      )}
    </button>
  );
};

export default AddFavorite;