import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useWebSocket } from '../../context/WebSocketContext';
import Bell from './../icons/Bell';
import NotificationList from './NotificationList';
import ProfilePictureManager from '../../layout/ProfilePictureManager';

interface Notification {
  _id: string;
  message: string;
  status: 'unread' | 'read';
  createdAt: string;
  type: string;
  relatedEntityId?: string;
}

const UserProfile: React.FC = () => {
  const { user, token } = useAuth();
  const { socket } = useWebSocket();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  // Récupération du nombre de notifications non lues
  const fetchUnreadCount = useCallback(async () => {
    if (!user || !token) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/notifications/${user.id}/unread-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTotalUnreadCount(data.unreadCount);
    } catch (error) {
      console.error('Erreur lors de la récupération du nombre de notifications non lues:', error);
    }
  }, [user, token]);

  // Récupération des notifications
  const fetchNotifications = useCallback(async (page: number) => {
    if (!user || !token) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/notifications/${user.id}?page=${page}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      // Ne pas réinitialiser les notifications sauf si c'est la première page
      setNotifications(prevNotifications =>
        page === 1 ? data.notifications : [...prevNotifications, ...data.notifications]
      );
      setHasMore(data.currentPage < data.totalPages);
      setCurrentPage(data.currentPage); // Mettre à jour la page actuelle
    } catch (error) {
      console.error('Erreur lors de la récupération des notifications:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user, token]);

  // Marquer une notification comme lue
  const markAsRead = useCallback(async (notificationId: string) => {
    try {
      await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/notifications/${notificationId}/read`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification._id === notificationId
            ? { ...notification, status: 'read' }
            : notification
        )
      );
      setTotalUnreadCount(prevCount => Math.max(prevCount - 1, 0));
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la notification comme lue:', error);
    }
  }, [token]);

  useEffect(() => {
    if (user && token && socket) {
      fetchUnreadCount();
      fetchNotifications(1); // Charger la première page

      socket.emit('authenticate', user.id);

      const handleNewNotification = (notificationData: Notification) => {
        setNotifications(prevNotifications => [notificationData, ...prevNotifications]);
        fetchUnreadCount();
        if (showNotifications) {
          fetchNotifications(1); // Rafraîchir les notifications si le panneau est ouvert
        }
      };

      socket.on('newNotification', handleNewNotification);

      return () => {
        socket.off('newNotification', handleNewNotification);
      };
    }
  }, [user, token, socket, fetchNotifications, fetchUnreadCount, showNotifications]);

  const loadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      fetchNotifications(currentPage + 1); // Charger la page suivante
    }
  }, [hasMore, isLoading, currentPage, fetchNotifications]);

  if (!user) {
    return null;
  }

  return (
    <div className="relative p-4 border-t border-gray-200">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ProfilePictureManager
            size="md"
            className="mr-2 border border-mcdm-third-500 dark:border-mcdm-primary-100 rounded-full"
          />
          <span className="font-normal text-mcdm-third-500 dark:text-mcdm-primary-100">{user.name}</span>
        </div>
        <div className="relative">
          <button
            onClick={() => {
              setShowNotifications(!showNotifications);
              if (!showNotifications) {
                fetchNotifications(1); // Rafraîchir lors de l'ouverture des notifications
              }
            }}
            className="relative"
          >
            <Bell stroke="currentColor" fill="none" width="24px" height="24px" />
            {totalUnreadCount > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                {totalUnreadCount}
              </span>
            )}
          </button>

          {showNotifications && (
            <div className="absolute right-0 bottom-[39px] mb-3 w-[225px]">
              <NotificationList
                notifications={notifications}
                isLoading={isLoading}
                hasMore={hasMore}
                loadMore={loadMore}
                markAsRead={markAsRead}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;