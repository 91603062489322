export const generateJurisprudenceTitle = (
    jurisdiction: string,
    location: string,
    number: string,
    decisionDate: string
) => {
    if (jurisdiction === "Cour d'appel") {
        const locationPart = location ? `de ${location.replace(/^Cour d'appel de /, '')}` : '';
        return `${decisionDate || 'Date inconnue'} - Cour d'appel ${locationPart} ${number ? `- RG n° ${number}` : ''}`.trim();
    }
    return `${decisionDate || 'Date inconnue'} - ${jurisdiction || 'Juridiction inconnue'} ${number ? `n° ${number}` : ''}`.trim();
};
