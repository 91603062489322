import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export interface User {
  id: string;
  profilePicture: string;
  name: string;
  email: string;
  role?: 'admin' | 'user';
  verifiedUser?: boolean;
  plan?: string;
  profilePictureUrl?: string;
  profilePictureTimestamp?: number;
}

interface AuthContextType {
  token: string | null;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  login: (token: string, user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  refreshProfilePicture: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const navigate = useNavigate();

  const login = (newToken: string, newUser: User) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('user', JSON.stringify(newUser));
    setToken(newToken);
    setUser(newUser);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    setUser(null);
    navigate('/login');
  };

  const refreshProfilePicture = async () => {
    if (!user?.profilePicture || !token) return;

    const now = Date.now();

    if (
      user.profilePictureUrl &&
      user.profilePictureTimestamp &&
      now - user.profilePictureTimestamp < 10 * 60 * 1000
    ) {
      return;
    }

    try {
      console.log('Rafraîchissement de l\'URL de la photo de profil...');

      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/users/refresh-profile-picture`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();

      if (!data.profilePicture) {
        throw new Error('URL de la photo de profil non reçue du serveur');
      }

      setUser(prev => prev ? {
        ...prev,
        profilePictureUrl: data.profilePicture,
        profilePictureTimestamp: Date.now()
      } : null);

      // Mettre à jour le stockage local
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        localStorage.setItem('user', JSON.stringify({
          ...parsedUser,
          profilePictureUrl: data.profilePicture,
          profilePictureTimestamp: Date.now()
        }));
      }

    } catch (error) {
      console.error('Erreur lors du rafraîchissement de la photo de profil:', error);

      // Réinitialiser l'URL en cas d'erreur pour forcer une nouvelle tentative
      setUser(prev => prev ? {
        ...prev,
        profilePictureUrl: undefined,
        profilePictureTimestamp: undefined
      } : null);
    }
  };

  useEffect(() => {
    const handleAuthChange = (event: CustomEvent<any>) => {
      if (!event.detail.isAuthenticated) {
        logout();
      }
    };

    window.addEventListener('authChange', handleAuthChange as EventListener);

    return () => {
      window.removeEventListener('authChange', handleAuthChange as EventListener);
    };
  }, [logout, navigate]);

  useEffect(() => {
    refreshProfilePicture();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUser,
        login,
        logout,
        isAuthenticated: !!token,
        refreshProfilePicture,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
