import React from 'react';

interface FilterTabsProps {
    selectedFilter: string;
    onFilterChange: (filter: string) => void;
}

const FilterTabs: React.FC<FilterTabsProps> = ({ selectedFilter, onFilterChange }) => {
    return (
        <div className="flex flex-wrap gap-2">
            {['Tous', 'Favoris', 'Invitations'].map((filter) => (
                <button
                    key={filter}
                    className={`
                        px-4 py-2 rounded-md text-sm flex-1 sm:flex-none 
                        transition-all duration-200 shadow-inner
                        ${selectedFilter === filter
                            ? 'bg-mcdm-secondary-400 dark:bg-mcdm-secondary-400 text-white dark:text-mcdm-primary-100 hover:bg-mcdm-secondary-500 dark:hover:bg-mcdm-secondary-500'
                            : 'bg-white dark:bg-mcdm-third-400 text-mcdm-third-500 dark:text-mcdm-primary-200 hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300'
                        }
                    `}
                    onClick={() => onFilterChange(filter)}
                >
                    {filter}
                </button>
            ))}
        </div>
    );
};

export default FilterTabs;