import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../context/SettingsContext';
import { useAuth } from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import LoadingSpinner from '../components/widgets/LoadingSpinner';

export function useMaintenanceMode() {
    const navigate = useNavigate();
    const { maintenanceMode, setMaintenanceMode, isLoading } = useSettings();
    const { user } = useAuth();
    const { socket } = useWebSocket();

    const handleMaintenanceChange = useCallback((newMaintenanceMode: boolean) => {
        console.log('Mode maintenance changé:', newMaintenanceMode);
        setMaintenanceMode(newMaintenanceMode);
        if (newMaintenanceMode && user?.role !== 'admin') {
            navigate('/maintenance');
        }
    }, [setMaintenanceMode, user, navigate]);

    useEffect(() => {
        if (!socket) return;

        // Écoute des mises à jour de maintenance
        socket.on('maintenanceUpdate', (data: { maintenanceMode: boolean }) => {
            console.log('Événement maintenanceUpdate reçu:', data);
            handleMaintenanceChange(data.maintenanceMode);
        });

        // Polling de secours
        const pollingInterval = setInterval(async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/settings/public`
                );
                if (response.ok) {
                    const data = await response.json();
                    if (data.maintenanceMode !== maintenanceMode) {
                        handleMaintenanceChange(data.maintenanceMode);
                    }
                }
            } catch (error) {
                console.error('Erreur lors du polling maintenance:', error);
            }
        }, 30000);

        return () => {
            socket.off('maintenanceUpdate');
            clearInterval(pollingInterval);
        };
    }, [socket, maintenanceMode, handleMaintenanceChange]);

    return { maintenanceMode, isLoading };
}

export const MaintenanceGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const { maintenanceMode, isLoading } = useMaintenanceMode();
    const { user } = useAuth();

    useEffect(() => {
        if (!isLoading && maintenanceMode && user?.role !== 'admin') {
            navigate('/maintenance');
        }
    }, [maintenanceMode, isLoading, user, navigate]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return <>{children}</>;
};