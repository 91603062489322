import React from 'react';

interface YouTubeIconProps {
    className?: string;
}

const YouTubeIcon: React.FC<YouTubeIconProps> = ({ className }) => (
    <svg
        id="youtube"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        className={className}
        fill="currentColor"
    >
        <path d="M166.1,67.3c-1.6-5.9-6.2-10.5-12.2-12c-10.8-2.9-54-2.9-54-2.9s-43.1,0-53.8,2.9c-5.9,1.6-10.6,6.2-12.2,12 C31,77.8,31,99.9,31,99.9s0,22.3,2.8,32.8c1.7,5.8,6.3,10.4,12.2,12c10.7,2.8,53.8,2.8,53.8,2.8s43.3,0,54-2.8 c6-1.6,10.6-6.3,12.2-12c3-10.5,3-32.8,3-32.8S169.1,77.8,166.1,67.3z M85.8,120.3V79.7l35.7,20.3L85.8,120.3z"/>
    </svg>
);

export default YouTubeIcon;
