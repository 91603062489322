import React, { createContext, useContext, useEffect, useState } from 'react';

interface ThemeColors {
    primary: {
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
    };
    secondary: {
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
    };
    third: {
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
    };
    green?: {
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
    };
    blue?: {
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
    };
}

interface ThemeVariant {
    light: ThemeColors;
    dark: ThemeColors;
}

interface Themes {
    [key: string]: ThemeVariant;
}

interface ThemeContextType {
    currentTheme: string;
    isDarkMode: boolean;
    isSystemTheme: boolean;
    themes: Themes;
    colors: ThemeColors;
    changeTheme: (theme: string) => void;
    toggleThemeMode: (mode: 'light' | 'dark' | 'system') => void;
}

const THEMES: Themes = {
    'jcs': {
        light: {
            primary: {
                100: '#FBECDD',
                200: '#F9E1C8',
                300: '#F5CBA3',
                400: '#F1B67E',
                500: '#EDA15A'
            },
            secondary: {
                100: '#AEC25B',
                200: '#9CB342',
                300: '#819437',
                400: '#68772C',
                500: '#4E5921'
            },
            third: {
                100: '#8F5228',
                200: '#6F3F1F',
                300: '#472814',
                400: '#301B0D',
                500: '#100904'
            }
        },
        dark: {
            primary: {
                100: '#FBECDD',
                200: '#F9E1C8',
                300: '#F5CBA3',
                400: '#F1B67E',
                500: '#EDA15A'
            },
            secondary: {
                100: '#AEC25B',
                200: '#9CB342',
                300: '#819437',
                400: '#68772C',
                500: '#4E5921'
            },
            third: {
                100: '#160b00',
                200: '#6F3F1F',
                300: '#472814',
                400: '#301B0D',
                500: '#100904'
            }
        }
    },
    'eve-anne': {
        light: {
            primary: {
                100: '#E4FCEB',
                200: '#C8F9D6',
                300: '#A3F5BA',
                400: '#7EF19F',
                500: '#5AED83'
            },
            secondary: {
                100: '#86FF0D',
                200: '#7AF500',
                300: '#66CC00',
                400: '#52A300',
                500: '#3D7A00'
            },
            third: {
                100: '#0B68FE',
                200: '#0156DF',
                300: '#0147B7',
                400: '#01378D',
                500: '#01235A'
            }
        },
        dark: {
            primary: {
                100: '#F8FFF1',
                200: '#EBFFD6',
                300: '#D8FFAD',
                400: '#C4FF85',
                500: '#B0FF5C'
            },
            secondary: {
                100: '#A3FF47',
                200: '#8FFF1F',
                300: '#7AF500',
                400: '#66CC00',
                500: '#57AC01'
            },
            third: {
                100: '#0052F5',
                200: '#0044CC',
                300: '#0036A3',
                400: '#00297A',
                500: '#001848'
            }
        }
    },
    'germain': {
        light: {
            primary: {
                100: '#D5D9D1',
                200: '#C2C8BC',
                300: '#ADB6A5',
                400: '#98A38F',
                500: '#839178'
            },
            secondary: {
                100: '#FBBEB1',
                200: '#F99D8A',
                300: '#F87C63',
                400: '#F55536',
                500: '#F43A15'
            },
            third: {
                100: '#1783A1',
                200: '#12667D',
                300: '#0D4959',
                400: '#072730',
                500: '#030F12'
            }
        },
        dark: {
            primary: {
                100: '#FFFFFF',
                200: '#EBEBEB',
                300: '#D6D6D6',
                400: '#C2C2C2',
                500: '#ADADAD'
            },
            secondary: {
                100: '#FEBCAE',
                200: '#FE9A86',
                300: '#FD795E',
                400: '#FC5130',
                500: '#FB350E'
            },
            third: {
                100: '#1CA0C4',
                200: '#1783A1',
                300: '#12667D',
                400: '#0D4959',
                500: '#072730'
            }
        }
    },
    'chaplin': {
        light: {
            primary: {
                100: '#FFFFFF',
                200: '#F5F5F5',
                300: '#E0E0E0',
                400: '#CCCCCC',
                500: '#B3B3B3'
            },
            secondary: {
                100: '#A6A6A6',
                200: '#8C8C8C',
                300: '#737373',
                400: '#595959',
                500: '#404040' 
            },
            third: {
                100: '#333333',
                200: '#262626',
                300: '#1A1A1A',
                400: '#0D0D0D',
                500: '#000000' 
            },
            green: {
                100: '#2ABB7F',
                200: '#22A06B',
                300: '#216E4E',
                400: '#164B35',
                500: '#1C3329'
            },
            blue: {
                100: '#388BFF',
                200: '#1D7AFC',
                300: '#0C66E4',
                400: '#0055CC',
                500: '#09326C'
            }
        },
        dark: {
            primary: {
                100: '#DEE4EA',
                200: '#C7D1DB',
                300: '#B6C2CF',
                400: '#9FADBC',
                500: '#8C9BAB' 
            },
            secondary: {
                100: '#404040',
                200: '#595959',
                300: '#737373',
                400: '#8C8C8C',
                500: '#A6A6A6' 
            },
            third: {
                100: '#282E33',
                200: '#22272B',
                300: '#1D2125',
                400: '#161A1D',
                500: '#101214'  
            },
            green: {
                100: '#2ABB7F',
                200: '#22A06B',
                300: '#216E4E',
                400: '#164B35',
                500: '#1C3329'
            },
            blue: {
                100: '#388BFF',
                200: '#1D7AFC',
                300: '#0C66E4',
                400: '#0055CC',
                500: '#09326C'
            }
        }
    }
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function useTheme() {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
    const [currentTheme, setCurrentTheme] = useState('chaplin');
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isSystemTheme, setIsSystemTheme] = useState(true);

    // Effet initial pour charger les préférences
    useEffect(() => {
        // Charger le thème
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme && THEMES[savedTheme]) {
            setCurrentTheme(savedTheme);
        }

        // Charger le mode (sombre/clair/système)
        const savedMode = localStorage.getItem('themeMode');
        if (savedMode === 'light' || savedMode === 'dark') {
            setIsSystemTheme(false);
            setIsDarkMode(savedMode === 'dark');
        } else {
            // Utiliser les préférences système
            setIsSystemTheme(true);
            setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
        }
    }, []);

    // Effet pour gérer les changements de préférence système
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleChange = (e: MediaQueryListEvent) => {
            if (isSystemTheme) {
                setIsDarkMode(e.matches);
            }
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, [isSystemTheme]);

    // Effet pour appliquer les changements
    useEffect(() => {
        const root = document.documentElement;
        const currentColors = THEMES[currentTheme][isDarkMode ? 'dark' : 'light'];

        // Appliquer les variables CSS pour chaque type de couleur avec des types explicitement annotés
        Object.entries(currentColors).forEach(([type, shades]) => {
            Object.entries(shades as Record<string, string>).forEach(([shade, color]) => {
                root.style.setProperty(`--${type}-${shade}`, color);
            });
        });

        // Gérer le mode sombre
        if (isDarkMode) {
            root.classList.add('dark');
        } else {
            root.classList.remove('dark');
        }

        // Sauvegarder les préférences
        localStorage.setItem('theme', currentTheme);
        localStorage.setItem('themeMode', isSystemTheme ? 'system' : isDarkMode ? 'dark' : 'light');
    }, [isDarkMode, currentTheme, isSystemTheme]);

    // Assurez-vous que ces fonctions sont bien définies
    const changeTheme = (newTheme: string) => {
        if (THEMES[newTheme]) {
            setCurrentTheme(newTheme);
        }
    };

    const toggleThemeMode = (mode: 'light' | 'dark' | 'system') => {
        if (mode === 'system') {
            setIsSystemTheme(true);
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            setIsDarkMode(prefersDark);
        } else {
            setIsSystemTheme(false);
            setIsDarkMode(mode === 'dark');
        }
    };

    return (
        <ThemeContext.Provider
            value={{
                currentTheme,
                isDarkMode,
                isSystemTheme,
                themes: THEMES,
                changeTheme,
                toggleThemeMode,
                colors: THEMES[currentTheme][isDarkMode ? 'dark' : 'light']
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}