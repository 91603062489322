import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface DeleteSettingsProps {
    dossier: {
        _id: string;
        title: string;
    };
    onClose: () => void;
    userRole: string | null;
}

const DeleteSettings: React.FC<DeleteSettingsProps> = ({ dossier, onClose, userRole }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [confirmText, setConfirmText] = useState('');
    const navigate = useNavigate();

    const handleConfirmTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConfirmText(e.target.value.toUpperCase());
    };

    const isDeleteEnabled = confirmText === 'CONFIRMER';

    const handleDelete = async () => {
        if (!isDeleteEnabled) return;

        try {
            setIsDeleting(true);
            setError(null);

            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${dossier._id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression du dossier');
            }

            navigate('/dossiers');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Une erreur est survenue');
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-medium text-red-600">Supprimer ce dossier</h3>

            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                <p className="text-gray-700 mb-4">
                    La suppression d'un dossier est une action irréversible. Toutes les données du dossier seront définitivement perdues :
                </p>
                <ul className="list-disc list-inside text-gray-600 space-y-1 mb-4">
                    <li>Contenu du dossier</li>
                    <li>Images associées</li>
                    <li>Historique des modifications</li>
                    <li>Commentaires et suggestions</li>
                    <li>Invitations en cours etc.</li>
                </ul>
            </div>

            {error && (
                <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                </div>
            )}

            {userRole === 'author' || userRole === 'admin' ? (
                <div className="space-y-4">
                    <div>
                        <label htmlFor="confirmText" className="block text-sm font-medium text-gray-700 mb-1">
                            Tapez "CONFIRMER" pour activer la suppression
                        </label>
                        <input
                            id="confirmText"
                            type="text"
                            value={confirmText}
                            onChange={handleConfirmTextChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                            placeholder="CONFIRMER"
                        />
                    </div>
                    
                    <button
                        onClick={handleDelete}
                        disabled={!isDeleteEnabled || isDeleting}
                        className={`w-full px-4 py-2 text-white rounded flex items-center justify-center
                            ${isDeleteEnabled ? 'bg-red-500 hover:bg-red-600' : 'bg-gray-400'}
                            disabled:opacity-50 disabled:cursor-not-allowed`}
                    >
                        {isDeleting ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Suppression en cours...
                            </>
                        ) : (
                            'Supprimer ce dossier'
                        )}
                    </button>
                </div>
            ) : (
                <p className="text-gray-600 bg-gray-50 p-4 rounded-lg border border-gray-200">
                    Seuls l'auteur principal et les administrateurs peuvent supprimer ce dossier.
                </p>
            )}
        </div>
    );
};

export default DeleteSettings;