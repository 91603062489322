import React from 'react';

interface PlayCircleProps {
    fill?: string;
    secondaryfill?: string;
    width?: string | number;
    height?: string | number;
    className?: string;
}

const PlayCircle: React.FC<PlayCircleProps> = (props) => {
    const fill = props.fill || 'currentColor';
    const secondaryfill = props.secondaryfill || fill;
    const width = props.width || '100%';
    const height = props.height || '100%';
    const className = props.className || '';

    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 1060 1060"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g fill={fill}>
                <defs />
                <circle
                    className="cls-1"
                    cx="539.28"
                    cy="538.71"
                    r="313"
                    fill="none"
                    stroke={fill}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
                <path
                    className="cls-1"
                    d="M635.36 511.44L474.7 418.68c-21-12.12-47.25 3.03-47.25 27.28v185.51c0 24.25 26.25 39.4 47.25 27.28l160.66-92.76c21-12.12 21-42.43 0-54.56z"
                    fill="none"
                    stroke={fill}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="50px"
                />
            </g>
        </svg>
    );
};

export default PlayCircle;
