import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useWebSocket } from '../../../context/WebSocketContext';

interface Notification {
  _id: string;
  message: string;
  status: 'unread' | 'read';
  createdAt: string;
  type: string;
}

const useNotifications = () => {
  const { user, token } = useAuth();
  const { socket } = useWebSocket();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  const fetchUnreadCount = useCallback(async () => {
    if (!user || !token) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/notifications/${user.id}/unread-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTotalUnreadCount(data.unreadCount);
    } catch (error) {
      console.error('Erreur lors de la récupération du nombre de notifications non lues:', error);
    }
  }, [user, token]);

  const fetchNotifications = useCallback(async (page: number) => {
    if (!user || !token) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/notifications/${user.id}?page=${page}&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      setNotifications(prevNotifications =>
        page === 1 ? data.notifications : [...prevNotifications, ...data.notifications]
      );
      setHasMore(data.currentPage < data.totalPages);
      setCurrentPage(data.currentPage);
    } catch (error) {
      console.error('Erreur lors de la récupération des notifications:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user, token]);

  const markAsRead = useCallback(async (notificationId: string) => {
    try {
      await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/notifications/${notificationId}/read`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification._id === notificationId
            ? { ...notification, status: 'read' }
            : notification
        )
      );
      setTotalUnreadCount(prevCount => Math.max(prevCount - 1, 0));
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la notification comme lue:', error);
    }
  }, [token]);

  useEffect(() => {
    if (user && token && socket) {
      fetchUnreadCount();
      fetchNotifications(1);

      socket.emit('authenticate', user.id);

      const handleNewNotification = (notificationData: Notification) => {
        setNotifications(prevNotifications => [notificationData, ...prevNotifications]);
        fetchUnreadCount();
        if (showNotifications) {
          fetchNotifications(1);
        }
      };

      socket.on('newNotification', handleNewNotification);

      return () => {
        socket.off('newNotification', handleNewNotification);
      };
    }
  }, [user, token, socket, fetchNotifications, fetchUnreadCount, showNotifications]);

  const loadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      fetchNotifications(currentPage + 1);
    }
  }, [hasMore, isLoading, currentPage, fetchNotifications]);

  return {
    notifications,
    isLoading,
    hasMore,
    loadMore,
    markAsRead,
    totalUnreadCount,
    showNotifications,
    setShowNotifications,
    fetchNotifications,
  };
};

export default useNotifications;
