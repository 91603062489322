// src/components/jurisprudence/JurisprudenceSimilairesTab.tsx
import React, { useState, useEffect } from 'react';
import { generateJurisprudenceTitle } from '../../../macadamia-core/components/hooks/useTitleJurisprudence';

interface JurisprudenceSimilaire {
    id: string;
    jurisdiction: string;
    location?: string;
    number: string;
    decision_date: string;
    summary?: string;
    themes?: string[];
    source_level?: 'direct' | 'theme'; 
    score?: number;
    original_reference?: string;
}

interface JurisprudenceSimilairesTabProps {
    jurisprudenceId: string;
    themes?: string[];
}

// Fonction pour nettoyer le HTML et extraire le texte pur
const cleanHtmlTags = (htmlString: string): string => {
    if (!htmlString) return '';
    // Remplace les balises HTML par leur contenu
    return htmlString.replace(/<[^>]*>([^<]*)<\/[^>]*>/g, '$1');
};

const JurisprudenceSimilairesTab: React.FC<JurisprudenceSimilairesTabProps> = ({ jurisprudenceId, themes }) => {
    const [casSimilaires, setCasSimilaires] = useState<JurisprudenceSimilaire[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [directCount, setDirectCount] = useState<number>(0);
    const [themeCount, setThemeCount] = useState<number>(0);
    const [activeFilter, setActiveFilter] = useState<'all' | 'direct' | 'theme'>('all');

    useEffect(() => {
        const fetchCasSimilaires = async () => {
            try {
                setLoading(true);
                console.log(`Recherche de cas similaires pour la jurisprudence ${jurisprudenceId}`);

                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('Token d\'authentification manquant');
                    setError('Authentification requise');
                    setLoading(false);
                    return;
                }

                // Utiliser directement l'ID pour la recherche hiérarchisée
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudences-similaires/par-id?id=${jurisprudenceId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                console.log('Status code:', response.status);

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error('Réponse d\'erreur:', errorText);

                    if (response.status === 404) {
                        console.log('Route non trouvée, tentative alternative');
                        setError('La fonctionnalité des cas similaires est temporairement indisponible. Veuillez réessayer plus tard.');
                        setLoading(false);
                        return;
                    }

                    let errorData;
                    try {
                        errorData = JSON.parse(errorText);
                    } catch (e) {
                        errorData = { message: errorText };
                    }
                    throw new Error(errorData.message || `Erreur ${response.status}: ${response.statusText}`);
                }

                const data = await response.json();
                console.log('Données reçues:', data);

                // Mise à jour des compteurs
                setDirectCount(data.direct_count || 0);
                setThemeCount(data.theme_count || 0);

                setCasSimilaires(data.results || []);
            } catch (err) {
                console.error('Erreur capturée:', err);
                if (err instanceof Error) {
                    setError(err.message || 'Impossible de récupérer les cas similaires. Veuillez réessayer.');
                } else {
                    setError('Impossible de récupérer les cas similaires. Veuillez réessayer.');
                }
            } finally {
                setLoading(false);
            }
        };

        // Récupérer les cas similaires seulement si on a un ID
        if (jurisprudenceId) {
            fetchCasSimilaires();
        } else {
            setLoading(false);
            setError('ID de jurisprudence manquant');
        }
    }, [jurisprudenceId, themes]);

    // Filtrer les résultats selon le type sélectionné
    const filteredCasSimilaires = casSimilaires.filter(cas => {
        if (activeFilter === 'all') return true;
        return cas.source_level === activeFilter;
    });

    if (loading) {
        return (
            <div className="p-0 md:p-4 space-y-4">
                <div className="animate-pulse h-4 bg-mcdm-primary-300 dark:bg-mcdm-primary-100 rounded w-3/4"></div>
                <div className="animate-pulse h-4 bg-mcdm-primary-300 dark:bg-mcdm-primary-100 rounded w-2/3"></div>
                <div className="animate-pulse h-4 bg-mcdm-primary-300 dark:bg-mcdm-primary-100 rounded w-1/2"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-0 md:p-4">
                <p className="text-red-500 dark:text-red-400">{error}</p>
            </div>
        );
    }

    if (casSimilaires.length === 0) {
        return (
            <div className="p-0 md:p-4">
                <p className="text-mcdm-third-400 dark:text-mcdm-primary-200">Aucun cas similaire trouvé.</p>
            </div>
        );
    }

    // Pluralisation correcte du message
    const resultsCountMessage = casSimilaires.length === 1
        ? "1 cas similaire trouvé"
        : `${casSimilaires.length} cas similaires trouvés`;

    return (
        <div className="p-0 md:p-4">
            <div className="flex flex-col justify-between items-start gap-3 mb-4">
                <p className="text-mcdm-third-400 dark:text-mcdm-primary-200 text-sm md:text-base">
                    {resultsCountMessage}
                </p>

                {/* Filtres empilés verticalement par défaut, sans condition de breakpoint */}
                {(directCount > 0 || themeCount > 0) && (
                    <div className="flex flex-col space-y-2 w-full">
                        <button
                            onClick={() => setActiveFilter('all')}
                            className={`px-2 py-1 rounded-full w-full text-xs md:text-sm ${
                                activeFilter === 'all'
                                    ? 'bg-mcdm-secondary-500 text-white dark:bg-mcdm-secondary-400 dark:text-mcdm-primary-100'
                                    : 'bg-mcdm-primary-200 text-mcdm-third-500 dark:bg-mcdm-third-400 dark:text-mcdm-primary-200'
                            }`}
                        >
                            Tous ({casSimilaires.length})
                        </button>

                        {directCount > 0 && (
                            <button
                                onClick={() => setActiveFilter('direct')}
                                className={`px-2 py-1 rounded-full w-full text-xs md:text-sm ${
                                    activeFilter === 'direct'
                                        ? 'bg-mcdm-secondary-500 text-white dark:bg-mcdm-secondary-400 dark:text-mcdm-primary-100'
                                        : 'bg-mcdm-primary-200 text-mcdm-third-500 dark:bg-mcdm-third-400 dark:text-mcdm-primary-200'
                                }`}
                            >
                                <span className="md:hidden">Directs ({directCount})</span>
                                <span className="hidden md:inline">Rapprochements directs ({directCount})</span>
                            </button>
                        )}

                        {themeCount > 0 && (
                            <button
                                onClick={() => setActiveFilter('theme')}
                                className={`px-2 py-1 rounded-full w-full text-xs md:text-sm ${
                                    activeFilter === 'theme'
                                        ? 'bg-mcdm-secondary-500 text-white dark:bg-mcdm-secondary-400 dark:text-mcdm-primary-100'
                                        : 'bg-mcdm-primary-200 text-mcdm-third-500 dark:bg-mcdm-third-400 dark:text-mcdm-primary-200'
                                }`}
                            >
                                Par thèmes ({themeCount})
                            </button>
                        )}
                    </div>
                )}
            </div>

            <div className="space-y-3 md:space-y-4">
                {filteredCasSimilaires.map((cas, index) => (
                    <div
                        key={index}
                        className={`p-3 md:p-4 border rounded-lg ${cas.source_level === 'direct'
                            ? 'border-mcdm-secondary-400 dark:border-mcdm-secondary-300 bg-mcdm-primary-50 dark:bg-mcdm-third-400'
                            : 'border-mcdm-third-300 dark:border-mcdm-third-600 bg-mcdm-primary-100 dark:bg-mcdm-third-400'
                            }`}
                    >
                        {/* Header avec titre et badge - meilleur wrapping sur mobile */}
                        <div className="flex flex-wrap justify-between items-start gap-2">
                            <h3 className="text-mcdm-third-500 dark:text-mcdm-primary-100 font-medium text-sm md:text-base flex-grow">
                                {generateJurisprudenceTitle(
                                    cas.jurisdiction,
                                    cas.location || '',
                                    cas.number,
                                    cas.decision_date
                                )}
                            </h3>

                            {/* Badge optimisé pour mobile */}
                            {cas.source_level === 'direct' && (
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-mcdm-secondary-200 text-mcdm-secondary-700 dark:bg-mcdm-secondary-300 dark:text-mcdm-primary-100 flex-shrink-0">
                                    <span className="md:hidden">Direct</span>
                                    <span className="hidden md:inline">Rapprochement direct</span>
                                </span>
                            )}
                        </div>

                        {/* Afficher la référence originale si disponible, nettoyée des balises HTML */}
                        {cas.original_reference && (
                            <p className="text-xs italic text-mcdm-secondary-600 dark:text-mcdm-secondary-300 mt-1 line-clamp-1 md:line-clamp-none">
                                Référence: {cleanHtmlTags(cas.original_reference)}
                            </p>
                        )}

                        <p className="text-xs md:text-sm text-mcdm-third-400 dark:text-mcdm-primary-200 mt-2 line-clamp-3 md:line-clamp-none">
                            {cas.summary || 'Aucun résumé disponible.'}
                        </p>
                        {cas.themes && cas.themes.length > 0 && (
                            <div className="mt-2 md:mt-3">
                                <p className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mb-1">Thèmes:</p>
                                <div className="flex flex-wrap gap-1">
                                    {cas.themes.map((theme, i) => (
                                        <span
                                            key={i}
                                            className="px-2 py-0.5 text-xs rounded-full truncate max-w-[150px] md:max-w-none
                                                bg-mcdm-primary-200 text-mcdm-third-500
                                                dark:bg-mcdm-primary-200 dark:text-mcdm-third-500"
                                            title={theme}
                                        >
                                            {theme}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JurisprudenceSimilairesTab;