import { useState } from 'react';
import { ICompositeTextWidget, ITextBlock } from '../widgets/types';

// Définition de l'interface pour les résultats d'analyse
interface AnalysisResult {
  originalText: string;
  legalReferences: LegalReference[];
  summary?: string;
}

// Interface pour les références légales
interface LegalReference {
  text: string;
  type: string;
  found: boolean;
  codeId?: string;
  codeTitle?: string;
  sectionId?: string;
  sectionTitle?: string;
  articleId?: string;
  articleNum?: string;
  position?: {
    start: number;
    end: number;
  };
}

export const useSparkleAiAnalysis = (authToken: string, dossierId: string) => {
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);

  /**
   * Analyse le texte fourni en utilisant l'API d'IA
   */
  const analyzeText = async (text: string): Promise<AnalysisResult | null> => {
    setIsAnalyzing(true);
    
    try {
      const apiUrl = `${process.env.REACT_APP_URL_API_MACADAMIA}/composite-text-ai/analyze-text`;
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          dossierId,
          text
        })
      });

      if (!response.ok) {
        throw new Error(`Erreur API: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Analyse IA réponse:', result);
      
      if (result.success && result.data) {
        setAnalysisResult(result.data);
        return result.data;
      } else {
        console.error('Réponse API invalide:', result);
        return null;
      }
    } catch (error) {
      console.error('Erreur lors de l\'analyse du texte:', error);
      return null;
    } finally {
      setIsAnalyzing(false);
    }
  };

  /**
   * Vérifie si une référence légale doit être enrichie
   */
  const shouldEnrichReference = (reference: LegalReference): boolean => {
    // Ne pas enrichir les références dont l'ID d'article commence par "JORFA"
    if (reference.articleId && reference.articleId.startsWith('JORFA')) {
      console.log(`Référence ignorée (ID article JORFA): ${reference.text}`);
      return false;
    }

    // Ne pas enrichir les références qui n'ont pas été trouvées
    if (!reference.found) {
      return false;
    }

    return true;
  }

  /**
   * Applique les résultats de l'analyse à un bloc de texte composite
   * Utilise une approche par tokens pour éviter toute corruption HTML
   */
  const applyAnalysisToCompositeBlock = (
    compositeBlock: ICompositeTextWidget,
    analysisResult: AnalysisResult
  ): ICompositeTextWidget => {
    if (!analysisResult || !analysisResult.legalReferences || analysisResult.legalReferences.length === 0) {
      console.log('Pas de références légales trouvées pour l\'enrichissement');
      return compositeBlock;
    }
  
    console.log('Application des références légales:', analysisResult.legalReferences);
  
    // Création d'une copie profonde du bloc composite
    const updatedBlock = JSON.parse(JSON.stringify(compositeBlock)) as ICompositeTextWidget;
    
    // Filtrer uniquement les références valides avec position
    const validReferences = analysisResult.legalReferences
      .filter(ref => ref.found && ref.position && shouldEnrichReference(ref))
      .sort((a, b) => (a.position?.start || 0) - (b.position?.start || 0));
      
    if (validReferences.length === 0) {
      console.log('Aucune référence valide à enrichir après filtrage');
      return updatedBlock;
    }

    // Calculer les offsets de chaque bloc dans le texte complet
    const blockOffsets: number[] = [];
    let currentOffset = 0;
    
    for (const block of updatedBlock.content.blocks) {
      blockOffsets.push(currentOffset);
      currentOffset += block.rawText.length + 1; // +1 pour compenser le saut de ligne entre blocs
    }
    
    // Traiter chaque bloc individuellement
    for (let blockIndex = 0; blockIndex < updatedBlock.content.blocks.length; blockIndex++) {
      const block = updatedBlock.content.blocks[blockIndex];
      const blockStart = blockOffsets[blockIndex];
      const blockEnd = blockIndex + 1 < blockOffsets.length 
        ? blockOffsets[blockIndex + 1] - 1 // -1 pour exclure le saut de ligne
        : blockStart + block.rawText.length;
      
      // Filtrer les références qui appartiennent à ce bloc
      const blockReferences = validReferences.filter(ref => {
        const refStart = ref.position?.start || 0;
        return refStart >= blockStart && refStart < blockEnd;
      });
      
      if (blockReferences.length === 0) {
        continue; // Aucune référence dans ce bloc
      }
      
      // Pour ce bloc, nous allons construire le texte enrichi en divisant
      // le texte en segments non-chevauchants
      
      // Étape 1: Créer des segments de texte et stocker les métadonnées de référence
      interface Segment {
        type: 'text' | 'reference';
        content: string;
        reference?: LegalReference;
      }
      
      // Diviser le texte en segments en fonction des positions des références
      const splitPoints = new Set<number>();
      splitPoints.add(0); // Début du texte
      splitPoints.add(block.rawText.length); // Fin du texte
      
      // Ajouter tous les points de début et de fin de référence
      for (const ref of blockReferences) {
        if (!ref.position) continue;
        
        // Convertir les positions globales en positions locales au bloc
        const localStart = ref.position.start - blockStart;
        const localEnd = ref.position.end - blockStart;
        
        if (localStart >= 0 && localStart <= block.rawText.length) {
          splitPoints.add(localStart);
        }
        
        if (localEnd >= 0 && localEnd <= block.rawText.length) {
          splitPoints.add(localEnd);
        }
      }
      
      // Convertir en tableau et trier
      const sortedSplitPoints = Array.from(splitPoints).sort((a, b) => a - b);
      
      // Créer les segments de texte entre les points de séparation
      const segments: Segment[] = [];
      
      for (let i = 0; i < sortedSplitPoints.length - 1; i++) {
        const start = sortedSplitPoints[i];
        const end = sortedSplitPoints[i + 1];
        
        if (start === end) continue; // Ignorer les segments vides
        
        const segmentText = block.rawText.substring(start, end);
        
        // Déterminer si ce segment fait partie d'une référence
        let isReference = false;
        let referenceData: LegalReference | undefined;
        
        for (const ref of blockReferences) {
          if (!ref.position) continue;
          
          const localStart = ref.position.start - blockStart;
          const localEnd = ref.position.end - blockStart;
          
          // Si ce segment est complètement inclus dans la référence
          if (start >= localStart && end <= localEnd) {
            isReference = true;
            referenceData = ref;
            break;
          }
        }
        
        segments.push({
          type: isReference ? 'reference' : 'text',
          content: segmentText,
          reference: referenceData
        });
      }
      
      // Étape 2: Reconstruire le HTML enrichi à partir des segments
      let enrichedText = '';
      
      // Traiter chaque segment
      segments.forEach((segment, index) => {
        if (segment.type === 'text') {
          // Segment de texte normal
          enrichedText += segment.content;
        } else if (segment.type === 'reference' && segment.reference) {
          const ref = segment.reference;
          
          // Vérification supplémentaire pour être sûr
          if (!shouldEnrichReference(ref)) {
            enrichedText += segment.content;
            return;
          }
          
          // Déterminer le type de balise à générer
          const attributes = [];
          
          // Attributs communs
          attributes.push('nature="code"');
          
          // Code ID
          if (ref.codeId) {
            attributes.push(`data-legifrance-code="${ref.codeId}"`);
          }
          
          // Section ID si disponible
          if (ref.sectionId) {
            attributes.push(`data-legifrance-section="${ref.sectionId}"`);
          }
          
          // Article ID si disponible et si c'est un article
          if (ref.type.toLowerCase() === 'article' && ref.articleId) {
            attributes.push(`data-legifrance-article="${ref.articleId}"`);
          }
          
          // Construire la balise span complète
          const startTag = `<span class="legifrance-highlight" ${attributes.join(' ')}>`;
          const endTag = '</span>';
          
          enrichedText += startTag + segment.content + endTag;
        }
      });
      
      // Mettre à jour le bloc avec le texte enrichi
      updatedBlock.content.blocks[blockIndex].rawText = enrichedText;
    }
    
    return updatedBlock;
  };

  return {
    isAnalyzing,
    analyzeText,
    applyAnalysisToCompositeBlock,
    analysisResult
  };
};