import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function LegifranceCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const state = query.get('state');

    if (code) {
      // Stockez le code dans le local storage ou gérez-le comme nécessaire
      localStorage.setItem('auth_code', code);
      // Redirigez l'utilisateur vers la page de statut ou une autre page de votre choix
      navigate('/status');
    } else {
      // Gérez les erreurs ou les états ici
      console.error('Code d\'autorisation manquant');
    }
  }, [location, navigate]);

  return (
    <div>
      <h1>Traitement de la redirection OAuth...</h1>
    </div>
  );
}

export default LegifranceCallback;
