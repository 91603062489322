import React, { useState, useRef, useEffect, memo } from 'react';
import { useAuth } from '../context/AuthContext';

interface ProfilePictureManagerProps {
    size?: 'sm' | 'md' | 'lg';
    className?: string;
    editable?: boolean;
}

// Utilisation de React.memo pour éviter les re-rendus inutiles
const ProfilePictureManager = memo(({
    size = 'md',
    className = '',
    editable = false
}: ProfilePictureManagerProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [error, setError] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    // Utilisez uniquement les props nécessaires du contexte d'authentification
    const { user, token, setUser, refreshProfilePicture } = useAuth();

    // Gardez une référence au timestamp de dernière mise à jour
    const lastRefreshTimestampRef = useRef<number>(0);

    const sizes = {
        sm: 'h-8 w-8',
        md: 'h-10 w-10',
        lg: 'h-16 w-16'
    };

    // Vérifier si l'URL est valide avec une marge de sécurité avant expiration
    const isProfilePictureUrlValid = () => {
        if (!user?.profilePictureUrl || !user?.profilePictureTimestamp) {
            return false;
        }
        const now = Date.now();
        // Considérer l'URL comme invalide 2 minutes avant son expiration réelle (900s = 15min)
        return now - user.profilePictureTimestamp < 13 * 60 * 1000;
    };

    // Effet pour gérer le focus de la fenêtre
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                // L'utilisateur est revenu sur l'onglet, rafraîchir l'image si nécessaire
                if (!isProfilePictureUrlValid()) {
                    refreshProfilePicture().catch(err => {
                        console.error('Erreur lors du rafraîchissement après retour :', err);
                        setError(true);
                    });
                }
            }
        };

        // S'abonner aux événements de visibilité
        window.addEventListener('visibilitychange', handleVisibilityChange);
        
        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [refreshProfilePicture]);

    // Effet pour gérer le rafraîchissement initial et périodique
    useEffect(() => {
        const refreshPicture = async () => {
            // Ajout d'une limitation de tempo pour éviter des rafraîchissements trop fréquents
            const now = Date.now();
            if (now - lastRefreshTimestampRef.current < 5000) { // Limiter à une fois toutes les 5 secondes
                return;
            }

            if (!isProfilePictureUrlValid() && retryCount < 3) {
                try {
                    lastRefreshTimestampRef.current = now;
                    await refreshProfilePicture();
                    setError(false);
                } catch (err) {
                    console.error('Erreur de rafraîchissement:', err);
                    setError(true);
                    setRetryCount(prev => prev + 1);
                }
            }
        };

        refreshPicture();
        
        // Ajouter un rafraîchissement périodique
        const refreshInterval = setInterval(() => {
            if (!isProfilePictureUrlValid()) {
                refreshPicture();
            }
        }, 5 * 60 * 1000); // Vérifier toutes les 5 minutes
        
        return () => {
            clearInterval(refreshInterval);
        };
    }, [user?.profilePicture, retryCount, refreshProfilePicture]);

    // Gestionnaire pour les erreurs de chargement d'image
    const handleImageError = () => {
        // Si l'image ne charge pas (probablement URL expirée), tenter de rafraîchir
        if (!error) {
            setError(true);
            refreshProfilePicture()
                .then(() => setError(false))
                .catch(() => setRetryCount(prev => prev + 1));
        }
    };

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.[0]) return;

        try {
            setIsLoading(true);
            setError(false);

            const file = event.target.files[0];
            if (file.size > 5 * 1024 * 1024) { // 5MB
                throw new Error('Image trop volumineuse (max 5MB)');
            }

            const formData = new FormData();
            formData.append('image', file);

            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/users/profile-picture`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error(`Erreur ${response.status}: ${await response.text()}`);
            }

            const data = await response.json();

            setUser(prev => prev ? {
                ...prev,
                profilePicture: data.profilePictureKey,
                profilePictureUrl: undefined,
                profilePictureTimestamp: undefined
            } : null);

            setRetryCount(0);
            // Mettre à jour le timestamp de dernière mise à jour
            lastRefreshTimestampRef.current = Date.now();
            await refreshProfilePicture();

        } catch (err) {
            console.error('Erreur lors de l\'upload:', err);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getInitials = () => {
        if (!user?.name) return '';
        return user.name.split(' ')
            .map(part => part[0]?.toUpperCase())
            .slice(0, 2)
            .join('');
    };

    return (
        <div className={`relative ${sizes[size]} ${className}`}>
            {isLoading && (
                <div className="absolute inset-0 bg-gray-200 dark:bg-mcdm-primary-100 animate-pulse rounded-full z-20" />
            )}

            {/* Bordure extérieure arrondie */}
            <div className="absolute inset-0 rounded-full border border-gray-200 dark:border-gray-600 
                          hover:border-gray-400 dark:hover:border-gray-500 transition-colors duration-200" />

            <div
                className={`relative h-full w-full rounded-full overflow-hidden 
                          ${editable ? 'cursor-pointer group' : ''}`}
                onClick={() => editable && fileInputRef.current?.click()}
            >
                {user?.profilePictureUrl && !error ? (
                    <img
                        src={user.profilePictureUrl}
                        alt={`Photo de profil de ${user?.name}`}
                        className="h-full w-full object-cover"
                        onError={handleImageError}
                    />
                ) : (
                    <div className="h-full w-full bg-mcdm-third-200 dark:bg-mcdm-primary-100 
                                  text-white dark:text-mcdm-third-500 
                                  flex items-center justify-center text-sm font-medium">
                        {getInitials()}
                    </div>
                )}

                {editable && (
                    <>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            className="hidden"
                            accept="image/*"
                        />
                        <div className="absolute inset-0 flex items-center justify-center 
                                      bg-black dark:bg-black bg-opacity-0 dark:bg-opacity-0 
                                      group-hover:bg-opacity-30 dark:group-hover:bg-opacity-40 
                                      transition-all rounded-full z-10">
                            <svg
                                className="w-6 h-6 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

ProfilePictureManager.displayName = 'ProfilePictureManager';

export default ProfilePictureManager;