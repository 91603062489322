import React, { useState } from 'react';
import { useCurrentChapter } from '../contexts/CurrentChapterContext';
import { useMacadamia } from '../contexts/MacadamiaContext';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { ObjectId } from 'bson';
import AutoResizeTextarea from './AutoResizeTextarea';
import Trash from '../icons/trash';
import Plus from '../icons/Plus';

interface IChapter {
  type: string;
  title: string;
  sections: any[];
  _id: string;
}

const MacadamiaChapters: React.FC<{
  content: IChapter[],
  className?: string,
  itemClassName?: string,
  activeItemClassName?: string,
  onAddChapter: (newChapter: IChapter) => void,
  onEditChapter: (index: number, newTitle: string) => void,
  onDeleteChapter: (index: number) => void,
  onReorderChapters: (updatedChapters: IChapter[]) => void
}> = ({
  content,
  className = "bg-mcdm-primary-100 dark:bg-mcdm-third-400",
  itemClassName = "p-4 my-2 rounded-lg bg-white dark:bg-mcdm-third-300 text-mcdm-third-500 dark:text-mcdm-primary-100 hover:shadow-md transition-shadow duration-200",
  activeItemClassName = "border-l-4 border-mcdm-secondary-400",
  onAddChapter,
  onEditChapter,
  onDeleteChapter,
  onReorderChapters
}) => {
    const { currentChapterIndex, setCurrentChapterIndex } = useCurrentChapter();
    const { currentState } = useMacadamia();
    const [confirmDeleteIndex, setConfirmDeleteIndex] = useState<number | null>(null);

    const handleTitleChange = (index: number, newTitle: string) => {
      onEditChapter(index, newTitle);
    };

    const handleDeleteClick = (e: React.MouseEvent, index: number) => {
      e.stopPropagation();
      setConfirmDeleteIndex(index);
    };

    const handleConfirmDelete = (index: number) => {
      onDeleteChapter(index);
      setConfirmDeleteIndex(null);
    };

    const handleCancelDelete = () => {
      setConfirmDeleteIndex(null);
    };

    const addNewChapter = () => {
      const newChapter = {
        _id: new ObjectId().toHexString(),
        type: 'chapter',
        title: 'Nouveau chapitre',
        sections: []
      };

      onAddChapter(newChapter);
    };

    const handleChapterDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) {
        return;
      }

      const updatedChapters = Array.from(content);
      const [movedChapter] = updatedChapters.splice(sourceIndex, 1);
      updatedChapters.splice(destinationIndex, 0, movedChapter);

      onReorderChapters(updatedChapters);
    };

    return currentState === 'editor' ? (
      <DragDropContext onDragEnd={handleChapterDragEnd}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={`${className} p-4 rounded-lg`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {content.map((chapter, index) => (
                <Draggable key={chapter._id} draggableId={chapter._id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`relative ${itemClassName} ${currentChapterIndex === index ? activeItemClassName : ''
                        } hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-200`}
                      onClick={() => setCurrentChapterIndex(index)}
                    >
                      <AutoResizeTextarea
                        value={chapter.title}
                        onChange={(newTitle) => handleTitleChange(index, newTitle)}
                        className="chapter-input border-none bg-transparent w-full text-mcdm-third-500 dark:text-mcdm-primary-100"
                      />
                      <div className="absolute right-0 top-0 h-full flex items-center">
                        {content.length > 1 && (
                          <button
                            className="p-2 text-mcdm-third-400 dark:text-mcdm-primary-200 hover:text-mcdm-secondary-400"
                            onClick={(e) => handleDeleteClick(e, index)}
                          >
                            <Trash width="19px" height="19px" />
                          </button>
                        )}
                        {confirmDeleteIndex === index && (
                          <div className="absolute right-full top-0 mt-1 p-4 bg-white dark:bg-mcdm-third-400 border rounded shadow-md z-10" style={{ width: '150px' }}>
                            <p className="mb-2 text-mcdm-third-500 dark:text-mcdm-primary-100">Confirmer ?</p>
                            <button
                              className="text-mcdm-secondary-400 hover:text-mcdm-secondary-500 mr-4"
                              onClick={() => handleConfirmDelete(index)}
                            >
                              Oui
                            </button>
                            <button
                              className="text-red-500 hover:text-red-600"
                              onClick={handleCancelDelete}
                            >
                              Annuler
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div
                className="flex justify-center items-center p-4 cursor-pointer text-mcdm-secondary-400 hover:text-mcdm-secondary-500 dark:text-mcdm-secondary-300 dark:hover:text-mcdm-secondary-400"
                onClick={addNewChapter}
              >
                <Plus width="15px" height="15px" className="mr-3" />
                <span>Ajouter un chapitre</span>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <div className={`${className} p-4 rounded-lg`}>
        {content.map((chapter, index) => (
          <div
            key={chapter._id}
            className={`relative ${itemClassName} ${currentChapterIndex === index ? activeItemClassName : ''
              } hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-200`}
            onClick={() => setCurrentChapterIndex(index)}
          >
            <span className="text-mcdm-third-500 dark:text-mcdm-primary-100">{chapter.title}</span>
          </div>
        ))}
      </div>
    );
  };

export default MacadamiaChapters;