import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';

interface LegifranceSearchProps {
    onSelect: (reference: string) => void;
}

interface LegifranceResult {
    titles: {
        id: string;
        title: string;
    }[];
    nature: string;
    date: string;
    etat: string;
    nor: string;
    text?: string;
    datePublication?: string;
    dateSignature?: string;
}

const DOCUMENT_TYPES = [
    { value: 'LOI', label: 'Loi' },
    { value: 'ORDONNANCE', label: 'Ordonnance' },
    { value: 'DECRET', label: 'Décret' },
    { value: 'ARRETE', label: 'Arrêté' },
    { value: 'CIRCULAIRE', label: 'Circulaire' }
];

const SORT_OPTIONS = [
    { value: 'SIGNATURE_DATE_DESC', label: 'Date de signature (récent)' },
    { value: 'SIGNATURE_DATE_ASC', label: 'Date de signature (ancien)' },
    { value: 'PUBLICATION_DATE_DESC', label: 'Date de publication (récent)' },
    { value: 'PUBLICATION_DATE_ASC', label: 'Date de publication (ancien)' }
];

const LegifranceSearch: React.FC<LegifranceSearchProps> = ({ onSelect }) => {
    const { token } = useAuth();
    const [searchResults, setSearchResults] = useState<LegifranceResult[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [pageSize] = useState(10);

    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState({
        start: '',
        end: ''
    });
    const [sortOrder, setSortOrder] = useState('SIGNATURE_DATE_DESC');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.legifrance-sidebar') && !target.closest('.legifrance-button')) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleReferenceSelect = (referenceId: string) => {
        if (!referenceId) return;
        onSelect(referenceId);
        setIsVisible(false);
    };

    const formatTextWithMarks = (text: string) => {
        if (!text) return '';
        const parts = text.split(/(<mark>.*?<\/mark>)/g);
        return parts.map((part, index) => {
            if (part.startsWith('<mark>') && part.endsWith('</mark>')) {
                const markedText = part.replace(/<\/?mark>/g, '');
                return (
                    <span
                        key={index}
                        className="bg-mcdm-secondary-100 dark:bg-mcdm-secondary-700 px-1 rounded"
                    >
                        {markedText}
                    </span>
                );
            }
            return part;
        });
    };

    const searchLegifrance = async (userQuery?: string) => {
        const finalQuery = userQuery || window.getSelection()?.toString().trim() || searchQuery;
        if (!finalQuery) return;

        setIsLoading(true);
        setIsVisible(true);
        setSearchQuery(finalQuery);

        try {
            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/legifrance/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    query: finalQuery,
                    filters: {
                        ...(selectedTypes.length > 0 && { types: selectedTypes }),
                        dateRange,
                        sort: sortOrder,
                        page: currentPage,
                        pageSize
                    }
                }),
            });

            if (!response.ok) throw new Error('Erreur lors de la recherche Légifrance');

            const data = await response.json();
            setSearchResults(data.results.filter((result: LegifranceResult) =>
                result.titles && result.titles.length > 0
            ));
            setTotalResults(data.totalResultNumber || 0);
        } catch (error) {
            console.error('Erreur lors de la requête API Légifrance:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString: string) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <>
            <div className="flex gap-2">
                <button
                    onClick={() => searchLegifrance()}
                    className="legifrance-button px-2 py-1 hover:bg-mcdm-primary-200 rounded text-mcdm-third-500"
                >
                    Légifrance
                </button>
            </div>

            {isVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-20 z-30 transition-opacity duration-300"
                    onClick={() => setIsVisible(false)}>
                    <div className="legifrance-sidebar fixed inset-y-0 right-0 w-[320px] bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-lg shadow-inner z-30 overflow-y-auto transform transition-transform duration-300"
                        onClick={(e) => e.stopPropagation()}>
                        <div className="p-4">
                            <div className="mb-6">
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium mt-16 mb-1">Types de documents</label>
                                        <div className="flex flex-wrap gap-2">
                                            {DOCUMENT_TYPES.map(type => (
                                                <button
                                                    key={type.value}
                                                    onClick={() => {
                                                        setSelectedTypes(prev =>
                                                            prev.includes(type.value)
                                                                ? prev.filter(t => t !== type.value)
                                                                : [...prev, type.value]
                                                        )
                                                    }}
                                                    className={`px-2 py-1 rounded text-sm ${selectedTypes.includes(type.value)
                                                        ? 'bg-mcdm-secondary-500 text-white'
                                                        : 'bg-white text-mcdm-third-400'
                                                        }`}
                                                >
                                                    {type.label}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-1">Période</label>
                                        <div className="grid grid-cols-2 gap-2">
                                            <input
                                                type="date"
                                                value={dateRange.start}
                                                onChange={e => setDateRange(prev => ({
                                                    ...prev,
                                                    start: e.target.value
                                                }))}
                                                className="p-2 rounded border"
                                            />
                                            <input
                                                type="date"
                                                value={dateRange.end}
                                                onChange={e => setDateRange(prev => ({
                                                    ...prev,
                                                    end: e.target.value
                                                }))}
                                                className="p-2 rounded border"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-1">Trier par</label>
                                        <select
                                            value={sortOrder}
                                            onChange={e => setSortOrder(e.target.value)}
                                            className="w-full p-2 rounded border"
                                        >
                                            {SORT_OPTIONS.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <button
                                        onClick={() => searchLegifrance(searchQuery)}
                                        className="w-full bg-mcdm-secondary-500 text-white py-2 rounded outline-none"
                                    >
                                        Mettre à jour la recherche
                                    </button>
                                </div>
                            </div>

                            <div className="space-y-4">
                                {isLoading ? (
                                    <div className="space-y-4">
                                        {Array(5).fill(0).map((_, index) => (
                                            <div key={index} className="mb-4 animate-pulse">
                                                <div className="h-4 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full mb-2"></div>
                                                <div className="space-y-2">
                                                    {Array(8).fill(0).map((_, i) => (
                                                        <div key={i} className="h-3 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full"></div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <p className="mt-4 font-platypi text-mcdm-third-500 text-lg dark:text-mcdm-primary-100 text-left">
                                            {totalResults <= 1 ? `${totalResults} résultat trouvé` : `${totalResults} résultats trouvés`}
                                        </p>
                                        <div className="space-y-4">
                                            {searchResults.map((result, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => result.titles?.[0]?.id && handleReferenceSelect(result.titles[0].id)}
                                                    className="p-3 hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer rounded-lg border border-mcdm-primary-200 dark:border-mcdm-primary-200"
                                                >
                                                    <div className="text-sm text-mcdm-third-500 dark:text-mcdm-primary-100">
                                                        {formatTextWithMarks(result.titles?.[0]?.title || 'Titre inconnu')}
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-2 mt-2">
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">
                                                            {formatDate(result.date)}
                                                        </span>
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">
                                                            {result.nature || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {totalResults > pageSize && (
                                            <div className="flex justify-center gap-2 mt-4">
                                                {[...Array(Math.ceil(totalResults / pageSize))].map((_, i) => (
                                                    <button
                                                        key={i}
                                                        onClick={() => {
                                                            setCurrentPage(i + 1);
                                                            searchLegifrance(searchQuery);
                                                        }}
                                                        className={`w-8 h-8 rounded ${currentPage === i + 1
                                                            ? 'bg-mcdm-secondary-500 text-white'
                                                            : 'bg-white text-mcdm-third-400'
                                                            }`}
                                                    >
                                                        {i + 1}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LegifranceSearch;