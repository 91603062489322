import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useEditor } from './EditorContext';

interface MacadamiaContextType {
  currentState: 'editor' | 'reader';
  currentChapter: number;
  switchState: (newState: 'editor' | 'reader') => void;
  setChapter: (chapterIndex: number) => void;
}

interface MacadamiaProviderProps {
  children: ReactNode;
}

export const MacadamiaContext = createContext<MacadamiaContextType | undefined>(undefined);

export const MacadamiaProvider: React.FC<MacadamiaProviderProps> = ({ children }) => {
  const [currentState, setCurrentState] = useState<'editor' | 'reader'>('reader');
  const [currentChapter, setCurrentChapter] = useState(0);
  const { saveContent } = useEditor();

  const switchState = (newState: 'editor' | 'reader') => {
    setCurrentState(newState);
  };

  const setChapter = (chapterIndex: number) => {
    setCurrentChapter(chapterIndex);
  };

  useEffect(() => {
    if (currentState === 'reader') {
      saveContent();
    }
  }, [currentState, saveContent]);

  return (
    <MacadamiaContext.Provider value={{ currentState, currentChapter, switchState, setChapter }}>
      {children}
    </MacadamiaContext.Provider>
  );
};

export const useMacadamia = (): MacadamiaContextType => {
  const context = useContext(MacadamiaContext);
  if (context === undefined) {
    throw new Error('useMacadamia must be used within a MacadamiaProvider');
  }
  return context;
};
