import React from 'react';

const Maintenance: React.FC = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-mcdm-primary-100 dark:bg-mcdm-third-500 px-4">
            <div className="text-center">
                <img
                    className="w-8 h-auto mx-auto block dark:invert"
                    src="/logos/logo-macadamia-v2.png"
                    alt="Logo Macadamia"
                />
                <h1 className="text-4xl font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100 mb-4">
                    Site en maintenance
                </h1>
                <p className="text-mcdm-third-400 dark:text-mcdm-primary-200 mb-8">
                    Macadamia est actuellement en maintenance. Nous serons bientôt de retour.
                </p>
                <p className="text-sm text-mcdm-third-300 dark:text-mcdm-primary-300">
                    Merci de votre patience.
                </p>
            </div>
        </div>
    );
};

export default Maintenance;